import * as Sentry from '@sentry/vue'
import git_hash from '@/gitVersion.js'
import Vue from 'vue'
import store from '@/store/Store.js'

const appVersion = require('@/../package.json').version
let displayedSentryError = false

export default {
  init(appConfig) {
    if (process.env.VUE_APP_DISABLE_INTEGRATION_SENTRY === 'true') return

    const devEnv = appConfig.environmentName === 'development'
    const testingEnv = devEnv || /^(qa|uat)\d*$/i.test(appConfig.environmentName)

    Sentry.init({
      Vue,
      environment: appConfig.environmentName,
      // Sentry is better at handing release tracking if we strictly follow semver in reported versions
      release: `Portal@${appVersion}`,
      dsn: appConfig.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          // Use the route from vue router for tracing
          beforeNavigate: context => ({
            ...context,
            // There are no child routes in Portal so matched is always an array with 1 entry
            name: window.mpApp?.$route.matched[0].path ?? context.name,
          }),
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      debug: false,
      enabled: true,
      normalizeDepth: 10, // Allow reported data to exceed the default depth of 3
      tracesSampleRate: appConfig.sentry.tracesSampleRate ?? 0,
      replaysSessionSampleRate: appConfig.sentry.normalReplayRate ?? 0,
      replaysOnErrorSampleRate: appConfig.sentry.errorReplayRate ?? 0,
      ignoreErrors: [
        /ResizeObserver loop/,
        // Cancelled Request Errors
        /^AbortError/,
        /^canceled$/,
        / aborted\.?$/,
        // Session Timeout Error
        /^Bad session token - try logging in again\.$/,
        // Vue Router Warnings
        /^Avoided redundant navigation to current location: "[^"]+"\.$/,
        /^Navigation cancelled from "[^"]+" to "[^"]+" with a new navigation\.$/,
        /^Redirected when going from "[^"]+" to "[^"]+" via a navigation guard\.$/,
        // Google Auth Internal Errors
        /^[A-Z]$/,
        // Permissions Error
        /is not allowed by the user agent or the platform in the current context, possibly because the user denied permission\.$/,
      ],
      initialScope: {
        tags: { git_hash },
      },
      beforeSend(event, hint) {
        // If an error happens in development or qa environment, alert the user with a non-closing message.
        if (testingEnv) {
          console.log('Sentry...', event) // eslint-disable-line no-console
          if (hint.originalException) {
            console.error(hint.originalException)
            if (hint.originalException.cause) console.error(hint.originalException.cause)
          }

          // Only allow one pop-up at a time since it doesn't automatically close
          if (!displayedSentryError) {
            window.mpApp.$notify.error({
              message: 'Sentry Error Generated - please see console for details',
              duration: 0,
              position: 'bottom-left',
              onClose() { displayedSentryError = false },
            })
            displayedSentryError = true
          }
        }
        // Don't send messages in development environment
        if (devEnv) return null
        return event
      },
    })
  },

  setUserId(userId, accountType) {
    if (process.env.VUE_APP_DISABLE_INTEGRATION_SENTRY === 'true') return
    Sentry.configureScope(scope => {
      scope.setUser({
        id: userId,
        accountType,
      })
    })
  },

  captureException(e) {
    if (process.env.VUE_APP_DISABLE_INTEGRATION_SENTRY === 'true') return
    Sentry.captureException(e)
  },

  captureExceptionWithScope(exception, context, extras) {
    if (process.env.VUE_APP_DISABLE_INTEGRATION_SENTRY === 'true') return
    Sentry.withScope(scope => {
      scope.setContext(context, { ...extras, 'X-Call-Context': store.getters['ApplicationContext/companyUid'] })
      Sentry.captureException(exception)
    })
  },
}
