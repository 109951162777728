<script>
export default {
  props: {
    localeKey: {
      type: String,
      default: 'general.new',
    },
  },
}
</script>

<template>
  <span class="new-badge">
    {{ $t(localeKey) }}
  </span>
</template>

<style lang="scss" scoped>
.new-badge {
  display: inline-flex;
  line-height: 1;
  border-radius: 20px;
  padding: 0.6em;
  font-weight: bold;
  font-size: 0.8em;
  background-color: rgb(255, 0, 127);
  color: white;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}
</style>
