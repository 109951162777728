export const needsReload = (state, tf) => {
  state.needsReload = tf
}

export const setSidebar = (state, val) => {
  state.showSidebar = val
}

export const setLanguage = (state, val) => {
  state.language = val
}

export const setG2NewLogin = (state, val) => {
  state.g2NewLogin = val
}
