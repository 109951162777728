import Vue from 'vue'
import { v1 as uuid } from 'uuid'
import { mpDate } from '@/helpers.js'

const genEvent = (type, payload) => {
  return {
    type: type,
    title: payload.title,
    message: payload.message,
    ts: Date.now(),
    time: mpDate(),
    hid: uuid(),
    meta: payload.meta || undefined,
  }
}

const showMessage = (payload, type, vm) => {
  setTimeout(() => {
    vm.$message({
      message: payload.title,
      type: type,
      duration: 'duration' in payload ? payload.duration : 3000,
      showClose: true,
      offset: 65,
    })
  }, 5)
}

// Initial state
const coreState = {
  eventLog: [],
}

const getters = {
  reversedEventLog(state) {
    return state.eventLog.slice().sort((a, b) => {
      if (a.ts < b.ts) return 1
      if (a.ts > b.ts) return -1
      return 0
    })
  },
}

const actions = {
  /**
   * Define message box modal to show when ordering/editing/removing IBM connections
   */
  showIbmReminderModal() {
    const h = this._vm.$createElement
    Vue.prototype.$msgbox({
      title: window.mpApp.$t('ibm.modal-title'),
      message: h('div', null, [
        h('p', null, window.mpApp.$t('ibm.order-request-sent')),
        h('p', null, window.mpApp.$t('ibm.order-request-reminder')),
      ]),
      showCancelButton: true,
      closeOnClickModal: false,
      confirmButtonText: window.mpApp.$t('ibm.visit-portal'),
      cancelButtonText: window.mpApp.$t('general.not-now'),
    }).then(() => {
      window.open('https://cloud.ibm.com/login', '_blank').focus()
    }).catch(() => { /* empty function is intentional */ })
  },
}

const mutations = {
  notifyGood(state, payload) {
    const msgen = genEvent('success', payload)
    state.eventLog.push(msgen)
    showMessage(payload, 'success', this._vm)
  },
  notifyWarn(state, payload) {
    const msgen = genEvent('warning', payload)
    state.eventLog.push(msgen)
    showMessage(payload, 'warning', this._vm)
  },
  notifyBad(state, payload) {
    const msgen = genEvent('error', payload)
    state.eventLog.push(msgen)
    showMessage(payload, 'error', this._vm)
  },
  generateDemoEventLogData(state) {
    state.eventLog = []
    state.eventLog.push({
      type: 'success',
      title: 'Company profile updated successfully.',
      message: 'Company 14 has been updated',
      time: '9:05 am',
      hid: '5c704c30-b81b-11e6-bee5-3f741fffe13e',
    })
  },
  clearEventLog(state) {
    state.eventLog = []
  },
  logout(state) {
    state.eventLog = []
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
