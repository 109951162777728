import { cloneDeep, isObject, isString, some } from 'lodash'
import { DateTime } from 'luxon'
import { listTimeZones } from 'timezone-support'

import * as globals from '@/Globals.js'
import captureSentryError from '@/utils/CaptureSentryError.js'

import { marked } from 'marked'
import DOMPurify from 'dompurify'

const TRANSIT_MIN = 20
const DEFAULT_VXC_MIN = 1
const MEGAPORT_INTERNET_VXC_MAX = 10000
const MVE_VXC_MAX = 10000
const now = DateTime.now() // The current date and time in the local time zone.

/**
 * Determine the min VXC speed
 * @param aPort
 * @param bPort
 * @returns {number}
 */
export function minVXCSpeed(aPort, bPort) {
  if (bPort.connectType === 'TRANSIT') {
    return TRANSIT_MIN
  }
  return DEFAULT_VXC_MIN
}

/**
 * Determine the max VXC speed
 * @param aPort
 * @param bPort
 * @returns {number}
 */
export function maxVXCSpeed(aPort, bPort) {
  // If the connection is to another user's private port, then we don't know what is at the other end,
  // so just go with the speed from the end we know.
  // Note: MAX_SAFE_INTEGER is the fallback so when one end is unknown, Math.min will always pick the known speed.
  let maxSpeedA = aPort.maxVxcSpeed || aPort._aggSpeed || Number.MAX_SAFE_INTEGER
  let maxSpeedB = bPort.maxVxcSpeed || bPort._aggSpeed || Number.MAX_SAFE_INTEGER

  if (aPort.productType === globals.G_PRODUCT_TYPE_MVE) {
    maxSpeedA = MVE_VXC_MAX
  }
  if (bPort.productType === globals.G_PRODUCT_TYPE_MVE) {
    maxSpeedB = MVE_VXC_MAX
  }

  if (bPort.connectType === 'TRANSIT') {
    maxSpeedB = MEGAPORT_INTERNET_VXC_MAX
  }

  return Math.min(maxSpeedA, maxSpeedB)
}

/**
 * Determine the max speed for IX VXC connections
 * @param aPort
 * @param bPort
 * @returns {number}
 */
export function maxIXSpeed(aPort, bPort) {
  let maxSpeed = aPort._aggSpeed

  // metro/local IX
  if (aPort && bPort && aPort._location && aPort._location.metro === bPort.group_metro) {
    return maxSpeed
  }

  // remote IX
  return aPort.maxVxcSpeed || maxSpeed
}

export const convertSpeed = function(speed) {
  if (!speed) return '-'
  if (speed >= 1000) return `${speed / 1000} Gbps`
  return `${speed} Mbps`
}

// Element 'remove' polyfill
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
const prototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype]
for (const prototype of prototypes) {
  if (Object.prototype.hasOwnProperty.call(prototype, 'remove')) {
    continue
  }
  Object.defineProperty(prototype, 'remove', {
    configurable: true,
    enumerable: true,
    writable: true,
    value: function remove() {
      this.parentNode.removeChild(this)
    },
  })
}

export const deepClone = obj => {
  return cloneDeep(obj)
}

export const slug = function(value) {
  if (!value) return ''
  return value
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const mpDate = value => {
  let date = value ? DateTime.fromMillis(value) : DateTime.now()

  // Parse value if not in milliseconds
  if (date.invalid) {
    date = DateTime.fromMillis(Date.parse(date))
  }

  return date.toFormat('LLLL d y, t ZZZZZ')
}

/**
 * Convert ISO date string in UTC to provided desired format or default 'ccc dd LLL yyyy HH:mm:ss z'
 */
export const getFormattedDateFromISO = (dateString, timeZone = 'UTC', format = 'ccc dd LLL yyyy HH:mm') => {
  if (!dateString) return ''
  return DateTime.fromISO(dateString, { zone: 'UTC' }).setZone(timeZone).toFormat(format)
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
export const moneyFilter = (value, currency) => {
  const standardCurrency = (currency || 'AUD').toUpperCase()

  // When undefined is passed as locale, the currency will be displayed as per the browser location,
  // i.e. if the browser location is Germany, the amount will be displayed as '123.456,79 €',
  // whereas if the location is any English speaking country like Australia, it will be displayed as '€123,456.79'.
  let currencyValue = new Intl.NumberFormat(undefined, { style: 'currency', currency: standardCurrency }).format(value)

  if (!value) {
    currencyValue = currencyValue.replace('NaN', ' -')
  }

  return `${currencyValue} ${standardCurrency}`
}

Array.prototype.unique = function() {
  return this.reduce(function(accumulator, current) {
    if (accumulator.indexOf(current) < 0) {
      accumulator.push(current)
    }
    return accumulator
  }, [])
}

export const snakeToCapitalizedWords = input => {
  if (!input?.length) {
    return input
  }
  const arr = input.split('_')
  let result = ''
  for (const str of arr) {
    if (str.length) {
      result += `${str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()} `
    }
  }
  result = result.slice(0, result.length - 1)
  return result
}

export const convertProductType = productType => {
  switch (productType) {
    case globals.G_PRODUCT_TYPE_MEGAPORT:
      return window.mpApp.$t('productNames.port')
    case globals.G_PRODUCT_TYPE_MCR2:
      return window.mpApp.$t('productNames.mcr')
    case globals.G_PRODUCT_TYPE_MVE:
      return window.mpApp.$t('productNames.mve')
    case globals.G_PRODUCT_TYPE_VXC:
      return window.mpApp.$t('productNames.vxc')
    case globals.G_PRODUCT_TYPE_IX:
      return window.mpApp.$t('productNames.ix')
    default:
      return productType
  }
}

export const copyToClipboard = string => {
  // The normal copy method won't work when inside a popover, so create our own copy method.
  if (!string) {
    captureSentryError(new Error('No string defined for copy'))
    return
  }
  const el = document.createElement('textarea')
  el.value = string
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  const success = document.execCommand('copy')
  if (success) {
    window.mpApp.$notify({
      title: window.mpApp.$t('general.copy-to-clipboard'),
      message: window.mpApp.$tc('general.characters-copied', string.length, { count: string.length }),
      type: 'success',
    })
  } else {
    console.error('Failed to copy')
  }
  document.body.removeChild(el)
}

export const capitalizeFirstOnly = string => {
  if (!string) {
    return ''
  }
  return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`
}

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Basic converter to take a string that may have HTML, markdown style links, and html links in and turn
 * it into something that can safely be displayed as HTML. Input example would be:
 *    This is some text with [a markdown link](https://www.google.com) in the middle of it.
 *
 * The link syntax is also enhanced with maruku style definition of classes to add to the generated link.
 * Information on the style of thing is at https://golem.ph.utexas.edu/~distler/maruku/proposal.html and
 * will allow classes to be added to links like this:
 *    This is some text with [a markdown link](https://www.google.com){: .class1 .class2} in the middle of it.
 * The expectation is that there will be a space and period between each added class.
 *
 * @param {String} text - The string to be parsed
 * @param {Boolean} targetBlank - Whether to make links target _blank
 * @param {Boolean} stripHtml - Whether to strip other HTML tags from the string and turn them into text
 * @param {Boolean} parseTextLinks - Whether to parse text https links not in markdown links
 */
export const markdownLinkToHtml = (text, targetBlank = true, stripHtml = false, parseTextLinks = false) => {
  const markdownLinkRegex = /([^[]*)(\[[^\]]*\])(\([^)]*\))({:[^}]+})?([^[]*)/g
  const textLinkRegex = /(\b(https):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
  const textLinkReplace = '<a href="$1" target="_blank" style="text-decoration:underline">$1</a>'

  let input = text
  if (stripHtml) {
    input = escape(text)
  }
  let match = markdownLinkRegex.exec(input)
  if (match) {
    let output = ''
    while (match !== null) {
      // Text before the link
      if (parseTextLinks) {
        output += match[1].replace(textLinkRegex, textLinkReplace)
      } else {
        output += match[1]
      }
      output += '<a href="'
      output += match[3].slice(1, -1) // The link url
      output += '"'
      if (targetBlank) {
        output += ' target="_blank"'
      }
      // The maruku style classes to add to the link
      if (match[4]) {
        const classes = match[4].slice(2, -1).split(' .').join(' ').trim()
        output += ` class="${classes}"`
      }
      output += '>'
      output += match[2].slice(1, -1) // The link text
      output += '</a>'
      // Everything after the link and before the next one
      if (parseTextLinks) {
        output += match[5].replace(textLinkRegex, textLinkReplace)
      } else {
        output += match[5]
      }

      match = markdownLinkRegex.exec(text)
    }
    return output
  } else {
    if (parseTextLinks) {
      return input.replace(textLinkRegex, textLinkReplace)
    }
    return input
  }
}

/**
 * Converts markdown text to HTML string
 * @param {String} markdown Markdown string
 * @returns Sanitized HTML string
 */
export const markdownToHtml = markdown => {
  return DOMPurify.sanitize(marked(markdown), {
    USE_PROFILES: {
      html: true,
    },
  })
}

export const customValidationRule = msg => {
  return (rule, value, callback) => {
    return callback(new Error(msg))
  }
}

/**
 * Function for extracting the underlying language from a language-locale code
 * @param {string} locale The locale to extract the language from (e.g. en-US)
 * @returns Just the 2 letter language code from the locale (e.g. en)
 */
export const getLocaleLanguage = locale => locale.split('-')[0]

export const formatMveVendorName = vendor => {
  let vendorName = vendor.split(/[\s_]/).map(word => capitalizeFirstOnly(word)).join(' ')
  // VMware is a special case since its first two letters are capitalised,
  // so instead of manually changing the string again, just assign the expected value.
  if (vendorName === 'Vmware') vendorName = globals.VMWARE_VENDOR
  return vendorName
}

// Helper functions for location item selector
/**
 * A function that will return a string representation of the locations object for searching
 * @param {Object} loc Location
 * @returns String representation of location object
 */
export const locationToString = location => {
  return [location.name, location.address.suburb, location.address.city, location.address.state, location.address.street, location.metro].join()
}

/**
 * Returns a string for a location to be used for sorting purposes
 * @param {Object} location Location
 * @returns String to sort from
 */
export const sortLocationString = location => {
  return `${location.status} ${location.name}`
}

/**
 * Closes all tooltips in the component. Required because the parent is on a keep-alive or popover and if one of the tooltips
 * is open, it will remain open when the other page is displayed. Handling it this way means that we don't need to have a ref
 * to all the tooltips and talk to popper directly. If you call this and then hover one of the tooltips it will display as
 * expected.
 */
export const closeAllTooltips = () => {
  const tooltips = document.querySelectorAll('.el-tooltip__popper')
  for (const tooltip of tooltips) {
    tooltip.style.display = 'none'
  }
}

/**
 * Closes all tooltips and navigates to the specified route.
 *
 * @param {string} route - The route to navigate to.
 * @param {object} router - The router object.
 */
export const closeTooltipsAndNavigateToRoute = (router, route) => {
  closeAllTooltips()
  router.push(route)
}

/**
 * Filters an array of objects based on a search string, performing a deep search through nested objects.
 *
 * @param {Array<Object>} array - The array of objects to be filtered.
 * @param {string} searchString - The string to filter the array by. If an object's property (at any level of nesting) contains this string, the object is included in the result.
 * @param {Array<string>} [excludeKeys] - Optional array of keys to exclude from the search.
 * @returns {Array<Object>} - A new array containing only the objects that match the search string.
 */
export const filterNestedMatch = (array = [], searchString = '', excludeKeys = []) => {
  if (searchString === '') return array
  return array.filter(obj => {
    const matchFound = some(obj, (value, key) => {
      if (excludeKeys.includes(key)) {
        return false
      }
      if (isObject(value)) {
        return some(filterNestedMatch([value], searchString, excludeKeys))
      }
      return isString(value) && value.toLowerCase().includes(searchString.toLowerCase())
    })
    return matchFound
  })
}

/**
 * Returns a sorted array of objects
 *
 * @param arr: Array of objects to be sorted
 * @param key: the object key to sort on (string)
 * @param order: order ? 'descending' : 'ascending'
 * @param preproc for any preprocessing of sort values
 */
export const orderBy = (arr, key, order = 'ascending', preproc) => {
  /**
   * Comparator function to call
   *
   * @param {String} field The key (field) that we are sorging on
   * @param {Boolean} reverse
   * @param {Function} primer Function to call on the data before it gets to the comparison, if necessary
   * @returns
   */
  const comparator = (field, reverse, primer) => {
    const preprocessKey = primer ?
      x => {
        return primer(x[field])
      } :
      x => {
        return x[field]
      }

    const multiplier = !reverse ? 1 : -1

    // Return a function that we can use
    return (a, b) => {
      const preprocessedA = preprocessKey(a)
      const preprocessedB = preprocessKey(b)
      return multiplier * ((preprocessedA > preprocessedB) - (preprocessedB > preprocessedA))
    }
  }

  return arr.sort(comparator(key, order === 'descending', preproc))
}

/**
 * Check if a connect type is a cloud connection
 * @param {string} connectType The connect type to check against
 */
export const isCloudConnection = connectType => {
  return globals.CLOUD_ITEMS.some(cloud => cloud.connectType.includes(connectType))
}

/**
 * Filters events to include only those with services present in the provided UID list.
 * @param {Array<Object>} events - The array of events to filter.
 * @param {Array<string>} allServicesShortUidList - The list of service UIDs to match against.
 * @returns {Array<Object>} The filtered array of events with only matching services & services list.
 */
export const filterEventsByServices = (events = [], allServicesShortUidList = []) => {
  return events
    .map(event => ({
      ...event,
      services: event.services?.filter(uid => allServicesShortUidList.includes(uid)),
    }))
    .filter(event => event.services && event.services.length)
}

/**
 * An array of valid IANA time zone identifiers.
 */
export const ianaTimeZones = ['UTC', ...listTimeZones().filter(tz => now.setZone(tz).isValid)]

/**
 * An array of objects, each containing:
 * - `timeZone`: The IANA time zone identifier.
 * - `offset`: The UTC offset in `±hh:mm` format for the given time zone.
 */
export const timeZoneOffsets = ianaTimeZones.map(timeZone => ({
  label: timeZone.replace(/_/g, ' '),
  value: timeZone,
  offset: now.setZone(timeZone).toFormat('ZZ'),
}))
