import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from './RootActions.js'
import * as mutations from './RootMutations.js'

import ApplicationContext from './modules/ApplicationContext.js'
import Auth from './modules/Auth.js'
import Company from './modules/Company.js'
import Marketplace from './modules/Marketplace.js'
import Markets from './modules/Markets.js'
import RouteFiltering from './modules/RouteFiltering.js'
import Notifications from './modules/Notifications.js'
import ServiceFilters from './modules/ServiceFilters.js'
import Services from './modules/Services.js'
import UserNotifications from './modules/UserNotifications.js'
import UserProfile from './modules/UserProfile.js'
import Users from './modules/Users.js'
import IXTypes from './modules/IXTypes.js'
import IXFlows from './modules/IXFlows.js'
import Products from './modules/Products.js'
import MegaportCloudRouter from './modules/MegaportCloudRouter.js'
import MVE from './modules/MVE.js'
import ManagedCompanies from './modules/ManagedCompanies.js'
import ServiceStatus from './modules/ServiceStatus.js'

Vue.use(Vuex)

const storeConfig = {
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    Auth,
    Company,
    Marketplace,
    Markets,
    RouteFiltering,
    Notifications,
    ServiceFilters,
    Services,
    UserNotifications,
    UserProfile,
    Users,
    IXTypes,
    IXFlows,
    Products,
    MegaportCloudRouter,
    MVE,
    ManagedCompanies,
    ApplicationContext,
    ServiceStatus,
  },
  state: () => ({
    needsReload: false,
    showSidebar: true,
    language: {},
    g2NewLogin: false,
  }),
  actions,
  mutations,
}

const store = new Vuex.Store(storeConfig)

// Configure for hot module reloading
if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept([
    // './RootGetters.js',
    './RootActions.js',
    // './RootMutations.js',
    './modules/Auth.js',
    './modules/Company.js',
    './modules/Marketplace.js',
    './modules/Markets.js',
    './modules/RouteFiltering.js',
    './modules/Notifications.js',
    './modules/ServiceFilters.js',
    './modules/Services.js',
    './modules/UserNotifications.js',
    './modules/UserProfile.js',
    './modules/Users.js',
    './modules/IXTypes.js',
    './modules/IXFlows.js',
    './modules/Products.js',
    './modules/MegaportCloudRouter.js',
    './modules/MVE.js',
    './modules/ManagedCompanies.js',
    './modules/ApplicationContext.js',
    './modules/ServiceStatus.js',
  ], () => {
    // require the updated modules and swap in the new actions and mutations
    console.info('Hot updating store modules')
    store.hotUpdate({
      // getters: require('./RootGetters.js'),
      actions: require('./RootActions.js'),
      // mutations: require('./RootMutations.js'),
      modules: {
        Auth: require('./modules/Auth.js').default,
        Company: require('./modules/Company.js').default,
        Marketplace: require('./modules/Marketplace.js').default,
        Markets: require('./modules/Markets.js').default,
        RouteFiltering: require('./modules/RouteFiltering.js').default,
        Notifications: require('./modules/Notifications.js').default,
        ServiceFilters: require('./modules/ServiceFilters.js').default,
        Services: require('./modules/Services.js').default,
        UserNotifications: require('./modules/UserNotifications.js').default,
        UserProfile: require('./modules/UserProfile.js').default,
        Users: require('./modules/Users.js').default,
        IXTypes: require('./modules/IXTypes.js').default,
        IXFlows: require('./modules/IXFlows.js').default,
        Products: require('./modules/Products.js').default,
        MegaportCloudRouter: require('./modules/MegaportCloudRouter.js').default,
        MVE: require('./modules/MVE.js').default,
        ManagedCompanies: require('./modules/ManagedCompanies.js').default,
        ApplicationContext: require('./modules/ApplicationContext.js').default,
        ServiceStatus: require('./modules/ServiceStatus.js').default,
      },
    })
  })
}

export default store
export { storeConfig }
