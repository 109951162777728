<template>
  <section class="pending-approval-content">
    <h4>{{ $t('services.approve-connections') }}</h4>
    <p>{{ $t('services.approve-connections-list') }}</p>
    <el-card v-for="service in pendingApproval"
      :key="service.link"
      class="mb-1"
      data-name="pending-item"
      :data-approval-name="service.productName">
      <div v-if="service.marketplaceProfile && service.marketplaceProfile.logoImage"
        class="profile-img"
        :style="{ 'background-image': service.marketplaceProfile.logoImage }" />
      <div>
        <p>{{ service.secondaryName || service.productName }}</p>
        <div role="presentation">
          {{ service.request }}
        </div>
        <div class="text-align-right mt-1"
          role="presentation">
          <el-button v-if="canApproveOrder"
            type="primary"
            data-name="approve-connection"
            @click="$router.push(service.link, () => {})">
            {{ $t('general.approve') }}
          </el-button>
          <el-button v-else
            type="primary"
            data-name="view-connection"
            @click="$router.push(service.link, () => {})">
            {{ $t('general.view') }}
          </el-button>
        </div>
      </div>
    </el-card>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'PendingApproval',

  props: {
    pendingApproval: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('Auth', ['hasAuth']),
    canApproveOrder() {
      return this.hasAuth('approve_order')
    },
  },
})
</script>

<style lang="scss" scoped>
.pending-approval-content {
  h4 {
    text-align: center;
    background-color: var(--color-info-light-9);
    padding: 10px 0;
  }
}

.profile-img {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  background-color: white;
}
</style>
