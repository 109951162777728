// import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError'

import store from '@/store/Store.js'
import { G_PROVISIONING_LIVE } from '@/Globals'

class G2Review {
  #disabled = false

  /**
   * @param {Object} appConfiguration The environment settings loaded from environment.js
   */
  init(appConfiguration) {
    if (appConfiguration.disableIntegrationG2) {
      this.#disabled = true
    }
  }

  #isLoggedInAs() {
    return store.getters['Auth/isLoggedInAs']
  }

  isDisabled() {
    return this.#disabled || process.env.VUE_APP_DISABLE_INTEGRATION_G2 === 'true' || this.#isLoggedInAs()
  }

  async activateG2() {
    if (this.isDisabled()) {
      return
    }

    // Check whether this is a user initiated login (as opposed to a page refresh)
    if (!store.state.g2NewLogin) {
      return
    }

    // Don't display again until the user logs out and back in
    store.commit('setG2NewLogin', false)

    // Don't display if there are no live services - note that this
    // is set up so that if a service becomes live after this it still
    // won't show the survey until the user logs out and back in
    const allMyServices = store.getters['Services/allMyServices']
    if (!allMyServices.some(service => service.provisioningStatus === G_PROVISIONING_LIVE)) {
      return
    }

    // Don't show the survey if the user has already been shown the prompt to
    // do the survey
    const company = await sdk.instance.company().get()
    // If this user has already viewed G2, don't show the survey
    const authData = store.state.Auth.data
    if (company.attributeTags?.g2ViewedByUsers?.includes(authData.personUid)) {
      return
    }

    // If we can't get the token for the survey, log a sentry error and abort.
    // Next time the user logs in we'll try again.
    let surveyToken = undefined
    try {
      const passcode = await sdk.instance.g2Passcode().generateG2Passcode()
      surveyToken = passcode.state
    } catch (error) {
      captureSentryError(error)
      return
    }

    // If we got up to here, we want to record that we are showing the prompt
    // (don't wait for the survey to be shown)
    const newAttributeTags = {
      ...company.attributeTags,
      g2ViewedByUsers: [...(company.attributeTags?.g2ViewedByUsers || []), authData.personUid],
    }
    try {
      // This will log sentry errors if there are any issues.
      await store.dispatch('Company/updateCompany', { attributeTags: newAttributeTags })
    } catch (error) {
      captureSentryError(error)
      return
    }

    // Now we can ask the user if they want to do the survey
    try {
      await window.mpApp.$confirm(window.mpApp.$t('general.g2-review'), window.mpApp.$t('general.g2-review-title'), {
          confirmButtonText: window.mpApp.$t('general.review'),
          cancelButtonText: window.mpApp.$t('general.dont-review'),
          type: 'info',
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
        })
    } catch {
      return
    }

    const surveyUrl = `https://www.g2.com/partnerships/Megaport/users/login.embed?state=${surveyToken}&email=${authData.email}`
    window.open(surveyUrl, '_blank')
  }
}

export default G2Review
