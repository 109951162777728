
export default {
  /**
   * Use this to set up the tag manager
   */
  init() {
    if (process.env.VUE_APP_DISABLE_INTEGRATION_STRIPE === 'true') return false
    const stripeLoader = () => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://js.stripe.com/v3/'
      document.head.appendChild(script)
    }

    if (document.readyState === 'complete') {
      stripeLoader()
    } else {
      window.addEventListener('load', stripeLoader, false)
    }
  },
}
