import store from '@/store/Store.js'
class GoogleLogin {
  _appConfiguration = null

  /**
   * Use this to install the script at startup.
   * @param {object} appConfiguration object
   */

  init(appConfiguration) {
    if (process.env.VUE_APP_DISABLE_INTEGRATION_GOOGLE_LOGIN === 'true') return false
    this._appConfiguration = appConfiguration

    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = () => {
      store.commit('Auth/setGoogleSignInReady', true)
    }

    document.body.appendChild(script)
  }
}

export default new GoogleLogin()
