import Vue from 'vue'

import {
  Alert,
  Aside,
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  Container,
  DatePicker,
  Dialog,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Footer,
  Form,
  FormItem,
  Header,
  Input,
  Loading,
  Main,
  Menu,
  MenuItem,
  MenuItemGroup,
  Message,
  MessageBox,
  Notification,
  Option,
  OptionGroup,
  Pagination,
  Popover,
  Radio,
  RadioButton,
  RadioGroup,
  Rate,
  Row,
  Select,
  Skeleton,
  SkeletonItem,
  Step,
  Steps,
  Submenu,
  Switch,
  TabPane,
  Table,
  TableColumn,
  Tabs,
  Tag,
  Tooltip,
  Tree,
  Upload,
} from 'element-ui'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

// This will load all the appropriate variables and overrides, and export the variables for use
// in the application
import '@/styles/export-variables.scss'

// Set up all our classes
import '@/styles/classes.scss'

// Override CSS classes and add any needed for the whitelabel
if (process.env.VUE_APP_WHITELABEL_FOLDER) {
  try {
    require('WHITELABEL/classes.scss')
  } catch (error) {
    // It will fail if there is no classes.scss file defined for this whitelabel, and that's OK.
  }
}

// To find any new items that have been added but not already allowed for, use regex, e.g.:
// <el-(?!(button|menu|submenu|popover|card|container|header|aside|main|footer|loading|notification|form|form-item|radio-group|radio-button|input|dialog|checkbox|row|col|radio|alert|select|option|autocomplete|table|table-column|dropdown|dropdown-item|dropdown-menu|tabs|tab-pane|switch|checkbox-group|tag|steps|step|collapse-transition|date-picker|menu-item|tree|rate|button-group)( |>))

Vue.use(Alert)
Vue.use(Aside)
Vue.use(Autocomplete)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Card)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Col)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Container)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Divider)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Footer)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Header)
Vue.use(Input)
Vue.use(Loading)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Rate)
Vue.use(Row)
Vue.use(Select)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Submenu)
Vue.use(Switch)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tabs)
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(Tree)
Vue.use(Upload)

Vue.use(Loading.directive)

Vue.prototype.$msgbox = MessageBox
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification

Vue.component(CollapseTransition.name, CollapseTransition)
