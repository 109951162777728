export default {
  productNames: {
    port: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    ports: `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    mcr: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}`,
    mcrs: `${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    mcrLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG}`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    mveLong: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG}`,
    vxc: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    vxcs: `${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}`,
    ix: `${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    ixs: `${process.env.VUE_APP_EN_PRODUCTNAME_IXS}`,
    megaIx: `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}`,
    lag: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    lags: `${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    lagLong: `${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG}`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplaceShort: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    transitVxc: `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    mves: `${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
  },
  companyInfo: {
    name: `${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    portalUrl: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL}`,
    salesEmail: `${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}`,
    arGlobalEmail: `${process.env.VUE_APP_EN_COMPANYINFO_AR_GLOBAL_EMAIL}`,
    portal: `${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
  },
  services: {
    'loading-services': `Dienste werden geladen ...`,
    'no-matching': `Keine passenden Dienste gefunden.`,
    'diversity-zone': `Diversitätszone`,
    'create-diverse-pair': `Diversitätspaar erstellen`,
    'red-zone': `Rote Zone`,
    'blue-zone': `Blaue Zone`,
    'invoice-reference': `Service-Level-Referenz`,
    'invoice-reference-explanation': `Die Service-Level-Referenz wird auf der Ebene der Rechnungsposition für das zugehörige Produkt / den zugehörigen Dienst angezeigt.`,
    'rate-limit': `Übertragungsratenlimit`,
    'select-rate-limit': `Übertragungsratenlimit auswählen`,
    'max-rate-limit': `Maximales Übertragungsratenlimit`,
    'service-key': `Dienstschlüssel`,
    'service-deleted': `Dieser Dienst wurde aus den Daten entfernt. Umleiten zu Diensten`,
    'configuration-details': `Konfigurationsdetails`,
    'service-logs': `Dienstprotokolle`,
    reloading: `Die Dienstinformationen werden neu geladen. Warten Sie einen Moment.`,
    'initial-bgp-state': `BGP-Anfangsstatus`,
    saved: `Ihre Konfiguration wurde gespeichert.`,
    'service-id': `Dienst-ID`,
    'provisioning-status': `Bereitstellungsstatus`,
    'loading-status': `Dienststatus wird geladen ...`,
    'unknown-status': `Status dieses Dienstes kann nicht ermittelt werden`,
    media: `Medien`,
    speed: `Geschwindigkeit`,
    allocated: `Zugewiesen`,
    demarcation: `Abgrenzung`,
    up: `Dienst ist aktiv`,
    down: `Dienst ist nicht verfügbar`,
    'check-logs': `Protokolle auf weitere Informationen prüfen`,
    'product-name': `Produktname`,
    'rate-limit-fixed': `Hinweis: Das Übertragungsratenlimit kann für diesen Diensttyp nach der Bereitstellung nicht mehr geändert werden.`,
    'fixed-rate': `(feste Übertragungsrate)`,
    'service-status': `Dienststatus`,
    'service-information': `Dienstinformationen`,
    'loading-resources': `Ressourcen werden geladen. Bitte warten Sie.`,
    'loading-issue': `Beim Laden der Daten ist ein Problem aufgetreten. Wenden Sie sich an den Support.`,
    'identifier-copy': `Dienstbezeichner – Klicken zum Kopieren`,
    decommissioned: `Dieser Dienst ist bereits stillgelegt`,
    filter: `Dienste filtern`,
    'expand-collapse': `Alle Dienste erweitern oder reduzieren`,
    'some-speeds-disabled': `Hinweis: Einige Geschwindigkeiten können deaktiviert sein, da ein verbundener Dienst über dieser Geschwindigkeit liegt.`,
    'diversity-option': `Diversitätsoption`,
    'loading-state-info': `Aktualisierte Dienstinformationen werden geladen.`,
    'waiting-on-deploy': `Dienstbereitstellung in Bearbeitung`,
    'no-company': `Keine verfügbare Firma.`,
    'cancelled-when': `Stornierter Dienst endet am {when}`,
    'click-restore': `Klicken Sie zum Wiederherstellen des Dienstes`,
    'cancel-unknown': `Stornierter Dienst endet zu einem unbekannten Datum.`,
    'pending-internal-speed-approval': `System wartet auf Ihre Genehmigung einer von {where} angeforderten Geschwindigkeitsänderung`,
    'pending-internal-connection-approval': `System wartet auf Ihre Genehmigung einer Verbindung von {where}`,
    'pending-external-connection-approval': `System wartet auf externe Genehmigung einer Verbindung von {where}`,
    'pending-3rd-party-speed-approval': `System wartet auf externe Genehmigung einer Geschwindigkeitsänderung von {where}`,
    'pending-3rd-party-connection-approval': `System wartet auf externe Genehmigung einer Verbindung von {where}`,
    'gci-waiting-configure': `System wartet auf eingehende BGP-Sitzung.`,
    'waiting-for-resources': `Dieser Dienst wartet auf erstmalige Aktivierung.`,
    'in-design-not-deployed': `Noch nicht bereitgestellt. Bearbeiten Sie die Details, und schließen Sie die Bereitstellung ab.`,
    'in-design-not-ordered': `Noch nicht bestellt. Führen Sie die Bestellung über die linke Seitenleiste aus.`,
    'approve-connections': `Verbindungen genehmigen`,
    'approve-connections-list': `Folgende Verbindungen warten auf Ihre Genehmigung:`,
    'best-route': `Beste Route`,
    'next-hop-vxc': `Nächster Hop ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    bgp: `BGP`,
    'type-locked': `Dieser {type} ist gesperrt`,
    'type-being-deployed': `Dieser {type} wird bereitgestellt`,
    'type-unlock': `Klicken Sie, um {type} zu entsperren und Bearbeitung zuzulassen`,
    'type-unlock-notlive': `Dieser {type} ist noch nicht in Betrieb und kann nicht entsperrt werden`,
    'type-locked-admin': `Dieser {type} ist durch Administrator gesperrt und nicht bearbeitbar`,
    'type-lock': `Klicken Sie, um {type} zu sperren und damit Bearbeitung und Löschung zu verhindern`,
    'type-lock-notlive': `Dieser {type} ist nicht in Betrieb und kann nicht gesperrt werden`,
    'type-unlocked-admin': `Dieser {type} ist nicht gesperrt und kann nur von einem Admin gesperrt werden`,
    'type-locked-megaport': `Dieser {type} wurde von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} gesperrt und ist nicht bearbeitbar. Wenden Sie sich für weitere Informationen an den Support von ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'type-cancelled': `Dieser {type} wurde storniert`,
    'terminate-count': `k. A. | Ja, einen Dienst beenden | Ja, {count} Dienste beenden`,
    'service-locked': `Hinweis: Dieser Dienst wurde von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} gesperrt und kann weder bearbeitet noch gelöscht werden. Wenden Sie sich für weitere Informationen an den Support von ${process.env.VUE_APP_EN_COMPANYINFO_NAME}.`,
    'service-admin-locked': `Hinweis: Dieser Dienst wurde gesperrt und kann weder bearbeitet noch gelöscht werden. Nur ein Firmen-Admin kann den Dienst entsperren.`,
    'edit-permission-denied': `Hinweis: Sie haben keine Berechtigung zum Bearbeiten dieses Dienstes.`,
    'ip-address': `IP-Adresse`,
    'peer-ip-address': `Peer-IP-Adresse`,
    'creation-date': `Erstellungsdatum`,
    'replace-configured': `Konfigurierte Dienste ersetzen`,
    'replace-confirmation': `Dabei werden Ihre aktuell konfigurierten Elemente entfernt und durch diesen gespeicherten Satz ersetzt. Fortfahren?`,
    'delete-configuration': `Gespeicherte Konfiguration löschen`,
    // „name“ ist der Name des Ports/MCR/MVE, und „productType“ ist der Typ des portähnlichen Elements, das aktualisiert wurde (Port, MCR, MVE)
    'port-like-updated-title': `{productType} {name} aktualisiert`,
    'port-like-updated-message': `Updates für {productType} „{name}“ gespeichert`,
    'click-setup': `Klicken Sie zum Einrichten`,
    'new-mve-message': `Hinweis: Für die Validierung des ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}-Image bzw. der Software ist eine Internetverbindung erforderlich. Sie können ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} aktivieren oder Ihren eigenen Internetzugang anschließen.`,
    'connection-denied': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} nach {destination} wurde verweigert`,
    'connection-approved': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC} nach {destination} wurde genehmigt`,
    'amsix-contact-phone': `Für einen AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienst ist eine Telefonnummer für den {contactType} erforderlich.`,
    'gbps-ix': `Gbit/s ${process.env.VUE_APP_EN_PRODUCTNAME_IX}`,
    'gbps-vxc': `Gbit/s ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ibm-request-fail': `Die Übertragungsratenlimits für diesen Port können nicht abgerufen werden, bitte versuchen Sie es erneut.`,
    'type-state': `{type}-Status`,
    'shutdown-dialog-title': `Sind Sie sicher, dass Sie diesen Dienst abschalten wollen?`,
    'shutdown-dialog-message': `Wenn dieser Dienst abgeschaltet wird, läuft kein Verkehr mehr über ihn. Er verhält sich so, als ob er im ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Netz ausgefallen wäre.`,
    'shutdown-dialog-note': `Hinweis: Die Abrechnung für diesen Dienst bleibt aktiv, und er wird Ihnen weiterhin in Rechnung gestellt.`,
    'sub-uid': `Unter-UID`,
    'type-move-notlive': `Dieser {type} ist nicht online und kann nicht verschoben werden`,
    'port-move-notlive': `Dieser {type} ist nicht online, weshalb seine Verbindungen nicht verschoben werden können`,
    'no-movable-type': `Keine verschiebbaren Verbindungen auf diesem {type}`,
    'move-permission-denied': `Sie haben keine Berechtigung zum Verschieben von Verbindungen`,
    'cant-move-multiple-vnics': `Verbindungen von einer ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} mit mehreren vNICs können nicht verschoben werden`,
    'cant-move-no-ports': `Keine anderen ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} in der gleichen Metro`,
    'cant-move-no-mcrs': `Keine anderen ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} in der gleichen Metro`,
    'cant-move-no-mves': `Keine anderen ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} in der gleichen Metro`,
    'awaiting-approval': `Warten auf Genehmigung oder Ablehnung von ausstehenden Änderungen`,
    'shutdown-confirm-message': `Klicken Sie zum Ausschalten auf „Confirm“ (Bestätigen) und dann auf „Save“ (Speichern).`,
    'view-additional-filters': `Zusätzliche Filter anzeigen`,
    'clear-filters': `Filter löschen`,
    'service-type': `Diensttyp`,
    'contract-term': `Vertragslaufzeit`,
    'change-sort-direction': `Sortierrichtung ändern`,
    'matching-services': `0 passende Dienste (von {total}) | 1 passender Dienst (von {total}) | {count} passende Dienste (von {total})`,
    'diversity-location-tooltip': `Filtern Sie Standorte nach verfügbaren Diversitätszonen. Bei aktivierter Option „Auto“ (Automatisch) wird eine Diversitätszone für Sie ausgewählt. Weitere Informationen dazu finden Sie in unserer {link}`,
    'diversity-config-tooltip': `Diversitätszone, der dieser Dienst zugewiesen wird.`,
    'auto-zone': `Auto-Zone`,
    'download-inventory-report': `Bestandsbericht herunterladen`,
    'expand-all': `Alle Dienste erweitern`,
    'collapse-all': `Alle Dienste reduzieren`,
    'extra-actions': `Zusätzliche Aktionen`,
    'type-status': `Typ und Status`,
    'has-ongoing-maintenance': `Anhaltendes Wartungsereignis.`,
    'has-ongoing-outage': `Anhaltender Dienstausfall.`,
  },
  app: {
    'environment-unrecognized': `Umgebung nicht erkannt`,
    'unrecognized-url': `Zugriff von ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_URL} oder einer erkannten Entwicklungs-URL`,
  },
  general: {
    'delete-type': `{type} löschen`,
    'edit-type': `{type} bearbeiten`,
    'remove-type': `{type} entfernen`,
    'new-type': `{type} erstellen`,
    'add-type': `{type} hinzufügen`,
    'update-type': `{type} aktualisieren`,
    'type-details': `{type}-Details`,
    'type-configuration': `Konfiguration von {product}`,
    'edit-configured-type': `{type}-Konfiguration bearbeiten`,
    'select-type-location': `{type}-Standort auswählen`,
    'delete-permission-denied': `Sie haben keine Berechtigung zum Löschen von {type}`,
    'type-not-found': `{type} nicht gefunden`,
    'type-not-found-redirect': `{type} wurde in den Daten nicht gefunden. Umleiten zu Diensten`,
    'success-count': `0 erfolgreich | 1 erfolgreich | {count} erfolgreich`,
    'warning-count': `0 Warnungen | 1 Warnung | {count} Warnungen`,
    'error-count': `0 Fehler | 1 Fehler | {count} Fehler`,
    'count-months': `0 Monate | 1 Monat | {count} Monate`,
    'locations-count': `0 Standorte | 1 Standort | {count} Standorte`,
    'last-log': `Letzter Protokolleintrag: {last}`,
    'max-rate-limit': `MAX: {maxRateLimit} Mbit/s`,
    'speed-gbps': `{speed} Gbit/s`,
    'missing-billing-market': `Abrechnungsmarkt "{country}" fehlt.`,
    'create-thing': `{thing} erstellen`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    welcome: `Willkommen bei ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    save: `Speichern`,
    revert: `Zurücksetzen`,
    close: `Schließen`,
    cancel: `Abbrechen`,
    back: `Zurück`,
    next: `Weiter`,
    done: `Fertig`,
    loading: `Wird geladen ...`,
    services: `Dienste`,
    service: `Dienst`,
    price: `Preis`,
    summary: `Zusammenfassung`,
    copy: `Kopieren`,
    'copy-to-clipboard': `In Zwischenablage kopieren`,
    'characters-copied': `0 Zeichen kopiert | 1 Zeichen kopiert | {count} Zeichen kopiert`,
    version: `Version`,
    'first-name': `Vorname`,
    'last-name': `Nachname`,
    phone: `Telefon`,
    email: `E-Mail`,
    password: `Kennwort`,
    ok: `OK`,
    'details-help': `Weitere Informationen finden Sie unter folgendem Hilfethema:`,
    documentation: `Dokumentation`,
    saving: `Wird gespeichert ...`,
    public: `Öffentlich`,
    private: `Privat`,
    unspecified: `Nicht angegeben`,
    'x-end': `{end}-Ende`,
    hide: `ausblenden`,
    show: `anzeigen`,
    'technical-contact': `Technischer Kontakt`,
    'noc-contact': `NOC-Kontakt`,
    'contact-phone': `Telefonnummer des Kontakts`,
    'select-type': `Typ auswählen`,
    size: `Größe`,
    'step-unavailable': `Diese Option ist erst verfügbar, wenn Sie die vorherigen Schritte abgeschlossen haben.`,
    'not-available': `Nicht verfügbar`,
    'configure-thing': `{thing} konfigurieren`,
    'modification-delay': `Hinweis: Angewandte Änderungen können bis zu 2 Minuten dauern, bevor sie sichtbar werden.`,
    billing: `Abrechnung`,
    'processing-approval': `Genehmigung wird verarbeitet ...`,
    'processing-denial': `Ablehnung der Anfrage wird verarbeitet ...`,
    unknown: `Unbekannt`,
    yes: `Ja`,
    no: `Nein`,
    untitled: `Ohne Namen`,
    'no-location': `Kein Standort ausgewählt`,
    'select-location': `Standort auswählen`,
    'unknown-location': `Unbekannter Standort`,
    configure: `Konfigurieren`,
    'no-matching-countries': `Keine übereinstimmenden Länder`,
    'no-matching-locations': `Keine übereinstimmenden Standorte`,
    'country-filter': `Länder-Filter`,
    search: `Suche`,
    'pre-order': `Vorbestellung`,
    details: `Details`,
    name: `Name`,
    success: `Erfolgreich`,
    disabled: `Deaktiviert`,
    enabled: `Aktiviert`,
    'shut-down': `Heruntergefahren`,
    term: `Laufzeit`,
    red: `Rot`,
    blue: `Blau`,
    none: `Keine`,
    new: `Neu`,
    general: `Allgemein`,
    status: `Status`,
    'status-unknown': `Status ist unbekannt`,
    'created-by': `Erstellt von`,
    'privacy-policy': `Datenschutzrichtlinie`,
    'site-terms': `Nutzungsbedingungen der Website`,
    gsa: `Globale Servicevereinbarung`,
    'acceptable-use': `Zu akzeptierende Nutzungsrichtlinien`,
    'please-select': `Auswählen`,
    'important-information': `Wichtige Informationen`,
    'agree-terms': `Ich stimme den Vertragsbedingungen zu`,
    'view-details': `Details anzeigen`,
    'action-confirmation': `Möchten Sie dies wirklich tun?`,
    'unknown-error': `Unbekannter Fehler`,
    edit: `Bearbeiten`,
    remove: `Entfernen`,
    'request-failed': `Anfrage fehlgeschlagen`,
    'company-name': `Firmenname`,
    active: `Aktiv`,
    inactive: `Inaktiv`,
    na: `k. A.`,
    disable: `Deaktivieren`,
    pricing: `Preise`,
    note: `Hinweis`,
    website: `Website`,
    'click-to-visit': `Klicken Sie zum Anzeigen`,
    all: `Alle`,
    ipv4: `IPv4`,
    ipv6: `IPv6`,
    you: `Sie`,
    'your-company-admin': `Ihr Firmen-Admin`,
    date: `Datum`,
    mbps: `Mbit/s`,
    start: `Start`,
    end: `Ende`,
    duration: `Dauer`,
    'loading-details': `Details werden geladen ...`,
    'details-load-failed': `Laden der Details fehlgeschlagen`,
    prefix: `Präfix`,
    'next-hop': `Nächster Hop`,
    'load-failed': `Laden fehlgeschlagen`,
    to: `An`,
    from: `Von`,
    message: `Meldung`,
    send: `Senden`,
    sort: `Sortieren`,
    'hide-details': `Klicken Sie, um Details auszublenden`,
    'show-details': `Klicken Sie, um Details anzuzeigen`,
    'logs-label': `Protokolle`,
    'usage-label': `Nutzungsdetails`,
    'configuration-label': `Konfiguration`,
    actions: `Aktionen`,
    confirm: `Bestätigen`,
    delete: `Löschen`,
    'reload-data': `Daten neu laden`,
    network: `Netzwerk`,
    location: `Standort`,
    selection: `Auswahl`,
    'choose-file': `Datei auswählen`,
    label: `Beschriftung`,
    'single-use': `Einmalgebrauch`,
    'multi-use': `Mehrfachgebrauch`,
    add: `Hinzufügen`,
    'select-date': `Datum auswählen`,
    'loading-data': `Laden von Daten`,
    description: `Beschreibung`,
    'click-copy': `Klicken zum Kopieren`,
    token: `Token`,
    notice: `Hinweis`,
    'end-date': `Enddatum`,
    existing: `Vorhanden`,
    'no-info': `Keine weiteren Informationen verfügbar`,
    'error-updating': `Fehler beim Aktualisieren von {thing}.`,
    'click-enable': `Klicken Sie zum Aktivieren`,
    'updating-details': `Details werden aktualisiert ...`,
    approve: `Genehmigen`,
    view: `Anzeigen`,
    'save-as': `Speichern unter`,
    order: `Bestellen`,
    'show-actions': `Aktionen anzeigen`,
    'hide-actions': `Aktionen ausblenden`,
    'items-per-page': `{count} Elemente pro Seite`,
    'show-all': `Alle Elemente anzeigen`,
    origin: `Ursprung`,
    communities: `Communitys`,
    updated: `Aktualisiert`,
    'all-types': `Alle Typen`,
    static: `Statisch`,
    connected: `Verbunden`,
    local: `Lokal`,
    file: `Datei`,
    clear: `Löschen`,
    'no-match-found': `Kein Treffer gefunden`,
    'list-not-available': `Keine Liste verfügbar`,
    'list-select': `Liste auswählen`,
    'password-toggle': `Umschalten zur Anzeige des Kennworts`,
    'exact-label': `Genau`,
    'position-label': `Position`,
    'min-label': `Min`,
    'max-label': `Max`,
    'operations-label': `Operationen`,
    'drag-position-label': `Klicken und zur Position ziehen`,
    'example-abbr': `Beispiel:`,
    star: `Stern`,
    'standard-label': `Standard`,
    'advanced-label': `Erweitert`,
    'filters-label': `Filter`,
    'on-label': `Ein`,
    'off-label': `Aus`,
    'default-label': `Standard`,
    'deny-label': `Ablehnen`,
    'permit-label': `Zulassen`,
    'required-field-label': `Erforderliches Feld`,
    'warning-label': `Warnung`,
    'type-name': `{type}-Name`,
    'save-error': `Änderungen konnten nicht gespeichert werden: `,
    'copy-thing-to-clipboard': `{thing} in Zwischenablage kopieren`,
    'error-creating': `Fehler beim Erstellen von {thing}.`,
    'error-deleting': `Fehler beim Löschen von {thing}.`,
    'success-creating': `{thing} erfolgreich erstellt.`,
    'success-updating': `{thing} erfolgreich aktualisiert.`,
    'success-deleting': `{thing} erfolgreich gelöscht.`,
    'created-thing': `{thing} erfolgreich erstellt`,
    'updated-thing': `{thing} erfolgreich aktualisiert`,
    'word-counter': `{count} Wörter von {maxWords}`,
    user: `Benutzer`,
    load: `Laden`,
    update: `Aktualisieren`,
    'server-error': `Fehler bei Kommunikation mit API-Server`,
    'context-switching-title': `Möchten Sie die Konten wirklich wechseln?`,
    'context-switching-message': `Sie sind im Begriff, von Ihrem {accountTypeFrom} <strong>{fromCompany}</strong> zu {accountTypeTo} <strong>{toCompany}</strong> zu wechseln.`,
    'feature-flag-access-error': `Zugriff auf {pageName} ist von einem verwalteten Konto aus nicht möglich. Wählen Sie Ihr Partnerkonto aus, um auf {pageName} zuzugreifen.`,
    'confirm-password-mismatch': `Das Passwort stimmt nicht mit dem oben eingegebenen Passwort überein.`,
    'open-sidebar': `Seitenleiste öffnen`,
    'learn-megaport': `Erhalten Sie weitere Informationen zu den Diensten von ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, oder besuchen Sie das ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
    'visit-documentation': `Das ${process.env.VUE_APP_EN_COMPANYINFO_DOCUMENTATION_PORTAL} öffnen`,
    'visit-portal': `Besuchen Sie das ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'login-trouble': `Haben Sie Probleme beim Anmelden? {link}`,
    'site-disclaimer': `Durch den Zugriff auf das ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}/die Landingpage sind Sie an die Datenschutzrichtlinie (Privacy Policy), die Nutzungsbedingungen der Website (Site Terms) und die zu akzeptierende Nutzungsrichtlinie (Acceptable Use Policy) von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} gebunden.`,
    'partner-deals': `Partnerangebote`,
    'cancel-reterm': `Klicken Sie auf „Cancel“ (Abbrechen), wenn Sie keine Maßnahmen ergreifen und am Ende der Vertragslaufzeit zum Listenpreis in einen Vertrag ohne Laufzeit übergehen möchten.`,
    'deleted-thing': `{thing} erfolgreich gelöscht`,
    'unexpected-error': `Es ist ein unerwarteter Fehler aufgetreten. Versuchen Sie es später erneut. Wenn dieser Fehler weiterhin besteht, wenden Sie sich an ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'unsaved-changes-confirm-message': `Alle nicht gespeicherten Änderungen gehen verloren.`,
    'unsaved-changes-confirm-title': `Seite verlassen, ohne zu speichern?`,
    'unsaved-changes-confirm-cancel': `Auf der Seite bleiben`,
    'unsaved-changes-confirm-ok': `Seite verlassen`,
    'not-now': `Nicht jetzt`,
    up: `Nach oben`,
    down: `Nach unten`,
    beta: `Beta`,
    languages: `Sprachen`,
    expand: `Einblenden`,
    collapse: `Ausblenden`,
    here: `hier`,
    true: `wahr`,
    false: `falsch`,
    'get-help': `Unterstützung erhalten`,
    'type-shutdown-state': `Hinweis: Diese {type} befindet sich im ausgeschalteten Zustand`,
    continue: `Weiter`,
    accept: `Akzeptieren`,
    decline: `Ablehnen`,
    'terms-and-conditions': `Geschäftsbedingungen`,
    'lag-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAGS}`,
    'mcr-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}`,
    'mve-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}`,
    connection: `Verbindung`,
    connections: `Verbindungen`,
    'move-to': `Verschieben nach`,
    'not-submitted': `Nicht übermittelt`,
    processing: `Verarbeitung`,
    failure: `Fehler`,
    move: `Verschieben`,
    'select-all': `Alle auswählen`,
    'deselect-all': `Alle abwählen`,
    'non-prod': `Dies ist eine Nicht-Produktionsumgebung`,
    auto: `Auto`,
    'preparing-download': `Download wird vorbereitet ...`,
    'download-failed-title': `Download fehlgeschlagen`,
    'download-failed': `Datei konnte nicht heruntergeladen werden. Versuchen Sie es später noch einmal.`,
    mobile: `Mobil`,
    activate: `Aktivieren`,
    deactivate: `Deaktivieren`,
    valid: `Gültig`,
    invalid: `Ungültig`,
    type: `Typ`,
    expired: `Abgelaufen`,
    comments: `Kommentare`,
    refresh: `Aktualisieren`,
    'pre-release': `Vorabveröffentlichung`,
    'logs-count': `1 Protokolleintrag in den letzten 24 Stunden erhalten | {count} Protokolleinträge in den letzten 24 Stunden erhalten`,
    'most-recent-n-events': `Letzten {count} Ereignisse angezeigt`,
    'no-logs': `Keine Protokolle für diesen Dienst`,
  },
  tour: {
    'start-tour': `Tour starten`,
    'find-location': `Standort suchen`,
    'location-selected': `Standort ausgewählt`,
    'add-a-service': `Dienst hinzufügen`,
    'actions-panel': `Aktionsübersicht`,
    'live-chat': `24/7-Live-Chat`,
    'next-steps': `Nächste Schritte`,
    start: `Hier können Sie Dienste wie ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS}, ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} und ${process.env.VUE_APP_EN_PRODUCTNAME_IXS} erstellen, bereitstellen und verwalten.<br/><br/>Klicken Sie auf "Weiter", um fortzufahren.`,
    'intro-port': `Ein ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ist Ihre Verbindung zur ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Infrastruktur und das Gateway zu einer Vielzahl von Cloud-, Internetknoten- und anderen Endpunkten.<br/><br/>Klicken Sie auf "Weiter", um einen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} zu erstellen.`,
    'select-port': `Wählen Sie als Erstes den Standort des ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} aus.<br/><br/>Klicken Sie auf "Weiter", um einen Standort auszuwählen.`,
    'location-selected-content': `Konfigurieren Sie dann mit dem ausgewählten Standort den ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}.<br/><br/>Klicken Sie auf "Weiter", um den ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} zu konfigurieren.`,
    'port-configuration': `Konfiguration von ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'configure-port': `Konfigurieren Sie Ihren ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} durch Eingabe der erforderlichen Informationen.<br/><br/>Klicken Sie auf "Weiter", um Informationen für den ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} hinzuzufügen.`,
    price: `Anhand der bereitgestellten Informationen zum ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wird der Preis angezeigt.<br/><br/>Klicken Sie auf "Weiter", um zum nächsten Bildschirm zu gelangen.`,
    summary: `Dies ist der letzte Schritt zum Konfigurieren Ihres ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}. Die Abrechnung erfolgt erst, wenn Sie die Bestellung abgeschlossen haben.<br/><br/>Klicken Sie auf "Weiter", um den ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} hinzuzufügen.`,
    'port-configured': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} konfiguriert`,
    'configured-port': `Ihr konfigurierter ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wird auf der Seite „Services“ (Dienste) angezeigt, wo Sie die Konfiguration durch Klicken auf ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} oder das Zahnradsymbol rechts bearbeiten können.<br/><br/>Klicken Sie auf „Next“ (Weiter), um zum nächsten Bildschirm zu gelangen.`,
    'add-service': `Im nächsten Schritt wird ein Dienst zu Ihrem neu konfigurierten ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} hinzugefügt. Klicken Sie rechts neben dem ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auf das Symbol "Neue Verbindung", oder wählen Sie unten aus.`,
    'actions-panel-content': `Während dieser Demo wird Ihr konfigurierter ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} hier zusammen mit allen anderen Aktionen angezeigt, die erforderlich sind, um den Betrieb starten.<br/><br/>Wenn Sie noch ausstehende Aktionen zu erledigen haben, werden diese in der Seitenleiste angezeigt.`,
    chat: `Benötigen Sie Unterstützung? Klicken Sie hier, um eine Echtzeit-Konversation mit einem Spezialisten von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} zu starten.`,
    'next-steps-no-kb': `Sie sind am Ende der Tour angelangt.<br/><br/>Nun sind Sie bereit, um auf die Seite "Dienste" zu wechseln und Ihren ersten ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} zu erstellen.`,
    'next-steps-kb-html': `Sie sind am Ende der Tour angelangt.<br/><br/>Weitere Unterstützung finden Sie in unserer <a href="https://docs.megaport.com/" target="_blank">Dokumentation</a> oder auf unserem <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">YouTube-Kanal</a>.<br/><br/>Nun sind Sie bereit, um auf die Seite "Dienste" zu wechseln und Ihren ersten ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} zu erstellen.`,
    'next-steps-partial-html': `Sie sind am Ende der Tour angelangt.<br/><br/>Weitere Unterstützung finden Sie in unserer <a href="https://docs.megaport.com/" target="_blank">Dokumentation</a> oder auf unserem <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">YouTube-Kanal</a>.<br/><br/>Nun sind Sie bereit, um Ihren ersten ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} zu erstellen.`,
    exit: `Tour beenden`,
  },
  about: {
    why: `Warum ${process.env.VUE_APP_EN_COMPANYINFO_NAME}?`,
    provisioning: `Bereitstellen von Verbindungen in das ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Netzwerk in 59 Sekunden.`,
    scalable: `Skalierbare Konnektivität`,
    'scalable-content': `Erhöhen und Verringern der Bandbreite jederzeit bei veränderten geschäftlichen Anforderungen.`,
    'real-time': `Bereitstellung in Echtzeit`,
    api: `Leistungsstarke API-Integration`,
    'api-content': `Eigenständiges Verwalten der Konnektivität und Optimieren des Workflows über die leistungsfähige API.`,
    'about-megaport': `Info zu ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'about-megaport-video': `Info zu ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, Video`,
    'megaport-features': `Merkmale von ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
  },
  authentication: {
    'about-company': `Info zu ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'password-reset': `Ihr Konto erfordert das Rücksetzen des Kennworts. Legen Sie hier ein neues Kennwort für die Anmeldung fest.`,
    'setting-up': `Ihr Konto wird eingerichtet ...`,
    'password-strength': `Kennwortsicherheit`,
    'very-weak': `Sehr schwach`,
    weak: `Schwach`,
    moderate: `Moderat`,
    good: `Gut`,
    excellent: `Hervorragend`,
    'good-password': `Dies ist ein gutes Kennwort`,
    'excellent-password': `Dies ist ein hervorragendes Kennwort`,
    agreement: `Vereinbarung`,
    'agree-to': `Ich stimme zu `,
    'create-account': `Konto erstellen`,
    google: `Mit Google anmelden`,
    'have-account': `Haben Sie bereits ein Konto?`,
    'log-in': `Anmelden`,
    'one-time-token': `Einmal-Token`,
    'new-password': `Ihr neues Passwort`,
    'target-username': `Ziel-Benutzername`,
    forgot: `Kennwort vergessen`,
    register: `Jetzt registrieren`,
    'processing-reset': `Anforderung zum Zurücksetzen wird verarbeitet ...`,
    'send-reset': `Zurücksetzung senden`,
    resetting: `Kennwort wird zurückgesetzt ...`,
    reset: `Kennwort zurücksetzen`,
    'trying-login': `Anmeldung wird ausgeführt ...`,
    'please-login': `Anmelden`,
    'failed-login': `Anmeldung mit den vorhandenen Anmeldeinformationen ist fehlgeschlagen. Melden Sie sich erneut an.`,
    'password-reset-title': `Zurücksetzen des Kennworts`,
    'missing-reset-token': `Token zum Zurücksetzen des Kennworts fehlt. Wechseln Sie auf die Anmeldeseite, um eine "Kennwort vergessen"-Anforderung zu senden.`,
    'logging-in': `Anmeldung wird ausgeführt ...`,
    'need-to-reset': `Sie müssen Ihr Kennwort zurücksetzen. Das Formular wurde mit dem erforderlichen Feld aktualisiert.`,
    failed: `Anmeldung fehlgeschlagen`,
    'error-registering': `Fehler bei der Registrierung`,
    'unknown-error-registering': `Unbekannter Fehler bei der Registrierung`,
    'reset-email-sent': `Eine E-Mail zum Zurücksetzen des Kennworts wurde an Sie gesendet.`,
    'reset-request-failed': `Zurücksetzen des Kennworts – Anforderung fehlgeschlagen`,
    'unknown-error-reset': `Unbekannter Fehler beim Zurücksetzen des Kennworts`,
    'password-change-successful': `Kennwort wurde erfolgreich geändert. Sie können sich nun mit Ihrem neuen Kennwort anmelden.`,
    'password-reset-failed': `Das Zurücksetzen des Kennworts ist fehlgeschlagen. Wechseln Sie für einen erneuten Versuch auf die Anmeldeseite, und senden Sie eine neue "Kennwort vergessen"-Anforderung.`,
    'auth-failed': `Authentifizierung fehlgeschlagen`,
    'auth-failed-message': `Authentifizierung Ihres Kontos fehlgeschlagen`,
    'confirm-email-label': `Bestätigung der E-Mail-Adresse wird ausgeführt`,
    confirming: `Bestätigung wird ausgeführt. Bitte warten`,
    success: `Ihre E-Mail wurde bestätigt`,
    'failed-confirm': `Die Bestätigung Ihrer E-Mail-Adresse ist fehlgeschlagen. Versuchen Sie es später erneut.`,
    'return-portal': `Zurück zum Portal`,
    'registered-company': `Registrierte Firma: {name}`,
    'updated-company': `Aktualisierte Firma {name}`,
    'login-success-title': `Anmeldung war erfolgreich!`,
    'login-success-message': `{name} hat sich angemeldet`,
    'login-failed-token': `Anmeldung mit vorhandenem Token fehlgeschlagen. Melden Sie sich erneut an.`,
    'use-security-code': `Sicherheitscode verwenden`,
    'temp-password': `Ihr temporäres Passwort`,
    'confirm-password': `Passwort bestätigen`,
    'login-error': `Fehler beim Anmelden`,
    'login-error-msg': `Wir können Sie mit Ihren Anmeldedaten nicht anmelden. Haben Sie Probleme beim Anmelden? <a href="https://www.megaport.com/contact/support/" target="_blank">Unterstützung erhalten</a>`,
    submit: `Senden`,
    'vendor-onboarding': {
      cisco: {
        'intro-header': `Willkommen bei Cisco SD-WAN Cloud Interconnect mit ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
        'intro-content': `Wir freuen uns, dass Sie da sind, und hoffen, dass Sie gute Erfahrungen mit dem Produkt machen werden. Sie sind nur wenige Schritte davon entfernt, über vManage dedizierte Verbindungen zu Ihren Cloudumgebungen einzurichten und die Vorteile von Cisco SD-WAN auszuschöpfen, welches das hochverfügbare Underlay-Netzwerk von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} überlagert.`,
        'temp-password-info': `<b>Geben Sie Ihre E-Mail-Adresse und Ihr temporäres Passwort ein.</b> Nachdem Sie Ihr Passwort geändert haben, können Sie Ihre Anmeldedaten in Cisco vManage, Version 20.5 oder höher eingeben, um Bestellungen für MVEs aufzugeben, VXCs von MVEs zu anderen Zielen im Megaport-Netzwerk zu erstellen und diese zu löschen, anstatt das ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL} zu verwenden.`,
        'new-password-info': `<b>Geben Sie Ihr neues Passwort ein.</b> Nachdem Sie Ihr Passwort geändert haben, können Sie Ihre Anmeldedaten in Cisco vManage, Version 20.5 oder höher eingeben, um Bestellungen für MVEs aufzugeben, VXCs von MVEs zu anderen Zielen im Megaport-Netzwerk zu erstellen und diese zu löschen, anstatt das ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL} zu verwenden.`,
        'password-changed-info': `Wie geht es weiter? Geben Sie diese Anmeldedaten in Cisco vManage Version 20.5 oder höher ein, um ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} bereitzustellen oder ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} zwischen ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} und Cloud-Service-Anbietern zu erstellen.`,
        'temp-password-intro': `Geben Sie Ihre E-Mail-Adresse und Ihr temporäres Passwort ein. `,
        'new-password-intro': `Geben Sie Ihr neues Passwort ein. `,
        'password-changed-intro': `Ihr Passwort wurde geändert! `,
        'password-info': `Nachdem Sie Ihr Passwort geändert haben, können Sie Ihre Anmeldedaten in Cisco vManage Version 20.5 oder höher eingeben, um Bestellungen für ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} aufzugeben, ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} von ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} zu anderen Zielen im ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Netzwerk zu erstellen und diese zu löschen, anstatt das ${process.env.VUE_APP_EN_COMPANYINFO_CUSTOMER_PORTAL} zu verwenden.`,
      },
    },
    'verify-email-heading': `Bitte überprüfen Sie Ihre E-Mail`,
    'verify-email-subheading': `Wir haben Ihnen einen Link zur Bestätigung Ihres Kontos gesendet`,
    'verify-email-description': `Sie haben noch keine E-Mail erhalten? Bitte überprüfen Sie Ihren Spam-Ordner, um sicherzugehen, dass die Nachricht nicht darin enthalten ist.`,
    'verify-email-button': `E-Mail erneut senden`,
    'verify-email-resent-successful': `Die Bestätigungs-E-Mail wurde erfolgreich versendet.`,
    'verify-email-resent-unsuccessful': `Beim erneuten Senden Ihrer Bestätigungs-E-Mail ist etwas schief gelaufen.`,
    'password-must-include': `Ihr Passwort muss`,
    'at-least-8-char': `mindestens 8 Zeichen lang sein`,
    'at-least-one-number': `mindestens eine Ziffer enthalten`,
    'at-least-one-symbol': `mindestens ein Symbol enthalten`,
    'at-least-one-lower-case-letter': `mindestens einen Kleinbuchstaben enthalten`,
    'at-least-one-upper-case-letter': `mindestens einen Großbuchstaben enthalten`,
    'create-an-account': `Konto erstellen`,
    or: `Oder`,
    'signup-email': `Mit E-Mail-Adresse anmelden`,
    'signup-google': `Mit Google anmelden`,
    'login-to-your-account': `Anmeldung bei Ihrem Konto`,
    'signup-success': `Die Anmeldung war erfolgreich!`,
    'signup-failed': `Anmeldung fehlgeschlagen, bitte versuchen Sie es erneut.`,
    login: `Anmelden`,
    'login-google': `Anmeldung mit Google`,
    'setting-company': `Hinweis: Nach dem Speichern des neuen Firmenhandelsnamens werden Sie von der Sitzung abgemeldet. Sie müssen sich erneut anmelden, um dem neuen Unternehmen hinzugefügt zu werden.`,
    'reset-message': `Wenn dieses Konto existiert, wurde an Sie eine E-Mail zum Zurücksetzen Ihres Kennworts gesendet.`,
    'signup-google-instructions': `Bitte geben Sie einen Firmennamen ein und lesen Sie die nachstehenden Bedingungen, um fortzufahren.`,
    signup: `Registrieren`,
    'create-account-email': `Konto mit E-Mail erstellen`,
    'confirm-new-password': `Neues Kennwort bestätigen`,
    'new-password-no-your': `Neues Kennwort`,
    'reset-expired-dialog-title': `Der verwendete Rücksetzungs-Link ist nicht mehr gültig.`,
    'reset-expired-dialog-desc': `Der in der E-Mail verwendete Link ist abgelaufen oder ungültig. Klicken Sie auf „Resend Link“ (Link erneut senden), oder wenden Sie sich an ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'resend-link': `Link erneut senden`,
    'unexpected-error-dialog-title': `Es ist ein unerwarteter Fehler aufgetreten. Versuchen Sie es später erneut.`,
    'unexpected-error-dialog-desc': `Wenn dieser Fehler weiterhin besteht, wenden Sie sich an ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'login-megaport': `Melden Sie sich bei ${process.env.VUE_APP_EN_COMPANYINFO_NAME} an`,
    'email-verified': `Ihre E-Mail-Adresse wurde verifiziert`,
    'invalid-link': `Dieser Link ist nicht mehr gültig`,
    'request-link': `Klicken Sie auf die Schaltfläche, um einen neuen Link an Ihre registrierte E-Mail-Adresse zu senden.`,
    'verified-account': `Dieses Konto ist bereits verifiziert`,
    'verified-email': `Ihre E-Mail-Adresse wurde bereits verifiziert`,
    'continue-to-megaport': `Weiter zu ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}`,
    'company-trading-name-instructions': `Geben Sie den Handelsnamen Ihrer Firma ein, und lesen Sie die nachstehenden Bedingungen, um fortzufahren.`,
    'change-email-desc': `Geben Sie Ihre neue E-Mail-Adresse ein, und klicken Sie auf „Verifizierungscode anfordern“. Geben Sie den an Ihre neue E-Mail-Adresse gesendeten Verifizierungscode unten ein. Schließen Sie dieses Fenster nicht.`,
    'change-email-warning': `Beachten Sie, dass Sie sich nach dem Ändern Ihrer E-Mail-Adresse erneut anmelden müssen.`,
    'verification-code': `Verifizierungscode`,
    'get-verification-code': `Verifizierungscode anfordern`,
    'verify-email-taken': `Diese E-Mail-Adresse wird bereits von einem anderen Benutzer verwendet.`,
    'verification-code-expired': `Der Verifizierungscode ist abgelaufen. Fordern Sie eine neue Verifizierungs-E-Mail an`,
    'verification-code-sent': `Wir haben Ihnen einen einmaligen Verifizierungscode gesendet`,
    'verification-code-failed': `Beim Abrufen des Verifizierungscodes ist ein Fehler aufgetreten`,
    'verification-code-wrong': `Der Verifizierungscode ist falsch`,
    'setup-mfa-cancel-confirm': `Wenn Sie den Vorgang abbrechen, kehren Sie zum Anmeldebildschirm zurück. Sie können sich erst anmelden, wenn die MFA eingerichtet ist.`,
    'setup-mfa-reset': `Ihr Firmen-Admin hat Ihren Multi-Faktor-Authentifizierungscode (MFA) zurückgesetzt und deaktiviert. Bitte richten Sie die MFA erneut ein, um sich anzumelden.`,
    'setup-mfa-enforced': `Ihr Firmen-Admin hat die Multi-Faktor-Authentifizierung (MFA) erzwungen. Solange dies nicht geschehen ist, können Sie sich nicht anmelden.`,
    'enabling-mfa': `Aktivieren von MFA`,
    'mfa-token-invalid': `Einmaliger Code {code} ist ungültig. Nach 5 aufeinanderfolgenden erfolglosen Versuchen wird Ihr Konto gesperrt.`,
    'mfa-long': `Multi-Faktor-Authentifizierung`,
    'mfa-enter-token': `MFA ist für Ihr Konto aktiviert. Bitte geben Sie ein Token ein, um sich anzumelden.`,
    verify: `Überprüfen`,
    'mfa-verify-token': `Überprüfen des Tokens`,
    'mfa-instructions': `Scannen Sie mit der Smartphone-App zur Multi-Faktor-Authentifizierung das obige Bild. Wenn Sie keinen Barcode verwenden können, geben Sie stattdessen | diesen Textcode | in die App ein.`,
    'enable-mfa-failed': `Aktivierung der Multi-Faktor-Authentifizierung fehlgeschlagen`,
    'mfa-token-required': `Einmaliges Multi-Faktor-Authentifizierungstoken erforderlich. Das Formular wurde mit dem erforderlichen Feld aktualisiert.`,
    'login-cognito-unexpected-failure': `Bei der Authentifizierung ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es noch einmal, oder wenden Sie sich an Ihre Organisation.`,
    'login-sso-assistance': `Wenn Sie Hilfe benötigen, {docs} Sie unser Support-Team.`,
    contact: `kontaktieren`,
    'docs-portal': `Docs-Portal`,
    'create-one': `einen erstellen`,
    'login-sso-help-reset-password': `Wenn Sie Probleme haben, sich mit Ihrer E-Mail und Ihrem Passwort anzumelden, versuchen Sie, Ihr Passwort über den Link „Forgot Password“ (Passwort vergessen) unten zurückzusetzen.`,
    'login-sso-help-idp-config': `Wenn Sie Single Sign-On (SSO) aktiviert haben, sich aber nicht anmelden können, liegt möglicherweise ein IdP-Konfigurationsfehler vor, oder Ihr IdP antwortet nicht. Sie können sich möglicherweise direkt mit Ihrem ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Benutzernamen und -Passwort anmelden, wenn Sie ein Firmen-Admin sind oder SSO auf Optional eingestellt ist.`,
    'login-help': `Hilfe bei der Anmeldung erhalten`,
    'login-temp-password-expired': `Ihr temporäres Passwort ist abgelaufen. Bitte kontaktieren Sie unser Support-Team unter ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}, um ein neues temporäres Passwort anzufordern.`,
    continue: `Weiter`,
    'create-new-account': `Ein neues Konto erstellen`,
    'join-company-account': `Ihrem Firmenkonto beitreten`,
    'error-resetting-password': `Fehler beim Zurücksetzen des Passworts`,
    'reset-password-sent-if-confirmed': `Wenn diese E-Mail-Adresse in unserem System vorhanden ist und das Konto zuvor bestätigt wurde, senden wir Ihnen eine E-Mail, um Ihr Passwort zurückzusetzen.`,
    'sso-first-login': `Ihr Firmen-Admin hat SSO für Ihr Konto aktiviert. Sie werden nun zum Identitätsanbieter Ihres Unternehmens weitergeleitet, um sich zu authentifizieren.`,
    'sso-enforced-no-access': `Ein Firmen-Admin hat Single Sign-On erzwungen, und Sie dürfen sich nur über SSO anmelden. Bitte wenden Sie sich an Ihren Firmen-Admin, wenn Sie Hilfe beim SSO-Zugang benötigen.`,
    'sso-email-mapping-error': `Es gibt ein Problem mit der Zuordnung der E-Mail-Attribute in Ihrer SSO-Konfiguration. Um dieses Problem zu lösen, empfehlen wir Ihnen, sich an Ihren Firmen-Admin zu wenden und ihn zu bitten, die Zuordnung der E-Mail-Attribute zu überprüfen. Hilfe zur Behebung dieses Problems finden Sie auf unserer SSO-FAQ-Seite im <a href="https://docs.megaport.com/troubleshooting/sso-faq/" target="_blank">Dokumentenportal</a>`,
    'email-query-missing': `E-Mail-Abfrageparameter fehlt`,
    'email-not-sso': `E-Mail ist nicht für SSO konfiguriert`,
  },
  validations: {
    required: `{thing} ist erforderlich`,
    failed: `Validierung fehlgeschlagen`,
    'correct-issues': `Korrigieren Sie den angegebenen Fehler.`,
    'ix-selection-required': `${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Auswahl ist erforderlich`,
    'asn-validation': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ASN muss zwischen 2 und {maxAsn} liegen (Standardwert ist {defaultAsn})`,
    'asn-validation-plain': `ASN muss ein Wert zwischen {minAsn} und {maxAsn} sein`,
    'asn-validation-range': `ASN-Bereich {minAsn}-{maxAsn} oder {minAsn2}-{maxAsn2}`,
    'mbps-value-range': `Geben Sie einen Wert zwischen {min} und {max} Mbit/s ein`,
    'value-too-high': `Größer als das zugelassene Maximum. Geben Sie einen Wert kleiner oder gleich {max} ein`,
    asn: `ASNs beginnen mit "AS" oder "ASN" oder sind numerisch`,
    'min-length': `Minimale Länge 0 Zeichen | Minimale Länge 1 Zeichen | Minimale Länge {min} Zeichen`,
    'max-length': `Maximale Länge 0 Zeichen | Maximale Länge 1 Zeichen | Maximale Länge {max} Zeichen`,
    'min-max-length': `Muss {min} bis {max} Zeichen lang sein`,
    'weak-password': `Ihr Kennwort ist nicht sicher genug`,
    'invalid-mac': `Geben Sie eine gültige MAC-Adresse ein`,
    'terms-conditions': `Sie müssen den Vertragsbedingungen zustimmen`,
    'new-password': `Geben Sie Ihr neues Kennwort ein`,
    'password-different': `Das neue Kennwort muss sich vom alten Kennwort unterscheiden`,
    'email-invalid': `Geben Sie eine gültige E-Mail-Adresse ein`,
    'key-invalid': `Validierung des Schlüssels fehlgeschlagen`,
    'service-key-invalid': `Geben Sie einen gültigen Dienstschlüssel ein`,
    'use-service-key': `Zum Erstellen einer Verbindung ist ein Dienstschlüssel erforderlich`,
    'nutanix-key-format': `Format: optional drei Zeichen und Bindestrich, dann UUID`,
    'azure-key-format': `Azure-Dienstschlüssel bestehen aus 32-36 Zeichen`,
    'invalid-service-key': `Ungültiger Dienstschlüssel`,
    'destination-type': `Wählen Sie den Zieltyp aus`,
    'aws-connection-type': `Sie müssen den AWS-Verbindungstyp auswählen`,
    'destination-provider': `Wählen Sie den Zielanbieter aus`,
    'connection-name': `Geben Sie einen Namen für die Verbindung ein`,
    'connection-name-min': `Der Name muss ein oder mehrere Wörter umfassen, die mit einem Buchstaben beginnen`,
    'select-peering': `Wählen Sie aus, ob Peering mit Routeservern erfolgen soll`,
    'please-select': `Wählen Sie den/die/das {thing} aus`,
    'please-enter': `Geben Sie den/die/das {thing} ein`,
    'phone-format': `Internationales Format z. B. +49 30 1234567`,
    'aws-account': `AWS-Konten sind 12-stellige Nummern`,
    'ip-cidr-format': `Ungültiges IP-Adressformat, zum Beispiel 192.0.1.0/30`,
    'ip-list': `IP-Liste z. B. 191.0.2.0/24,191.0.2.0/30`,
    'select-peer-type': `Wählen Sie mindestens einen Peer-Typ aus`,
    'pairing-key-format-invalid': `Kopplungsschlüssel müssen der Google-Spezifikation entsprechen.`,
    'virtual-circuit-format-invalid': `Geben Sie einen gültige ID für die virtuelle Verbindung ein`,
    'pairing-key-invalid': `Ungültiger Kopplungsschlüssel`,
    'virtual-circuit-invalid': `Ungültige ID für virtuelle Verbindung`,
    'google-pairing-key': `Geben Sie zum Abschließen der Bestellung der Google-Verbindung einen gültigen Kopplungsschlüssel ein.`,
    'invalid-key': `Ungültiger Schlüssel`,
    'enter-valid-key': `Geben Sie einen gültigen Schlüssel ein`,
    'vlan-required': `VLAN-Wert ist erforderlich`,
    'rate-limit': `Das Übertragungsratenlimit kann nicht über dem angegebenen Maximum liegen.`,
    'select-location': `Sie müssen einen Standort auswählen`,
    'enter-name': `Geben Sie einen Namen ein`,
    'select-rate-limit': `Wählen Sie das Übertragungsratenlimit aus`,
    ge: `Geben Sie einen Wert größer oder gleich {value} ein`,
    'mve-final-check': `Letzte Prüfung auf ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}-VLAN erfolgt während der Einrichtung`,
    'vlan-unavailable': `VLAN nicht verfügbar`,
    'vlan-available': `VLAN ist verfügbar`,
    'vlan-auto-assign': `Wenn Sie kein VLAN angeben, wird ein zufälliges VLAN zugewiesen`,
    'vlan-no-untagged': `Dieses VLAN kann nicht auf "Nicht getaggt" eingestellt werden`,
    'vlan-untagged-warning': `Durch das Untagging eines VLAN wird der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auf eine einzige Verbindung beschränkt`,
    'vlan-range': `VLAN-Werte liegen im Bereich von {minVlan} bis {maxVlan}`,
    'vlan-in-use': `Es gibt bereits eine geplante Verbindung auf diesem ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, die dieses VLAN verwendet`,
    'agree-terms': `Sie müssen den Vertragsbedingungen zustimmen`,
    'insufficient-privileges': `Unzureichende Berechtigungen`,
    'bio-too-long': `Firmen-Biographie ist zu lang`,
    'url-format': `Geben Sie ein gültige {site}-URL ein`,
    'valid-company': `Gültiger Firmenname ist erforderlich`,
    'description-too-long': `Beschreibung ist zu lang`,
    'ip-in-list': `Diese IP ist in der Liste bereits vorhanden`,
    'invalid-network': `Die Adresse ist keine gültige IPv4-, IPv6- oder Netzwerkadresse`,
    'not-valid-network': `Die Adresse ist keine gültige Netzwerkadresse`,
    'no-subnet': `IP-Adresse muss eine Subnetzmaske enthalten`,
    'no-file-selected': `Keine Datei ausgewählt`,
    'wrong-file-type': `Falscher Dateityp`,
    'select-text-file': `Wählen Sie die hochzuladende Textdatei aus`,
    'file-too-long': `Datei zu lang`,
    'file-max-length': `Länge der kodierten Datei muss kleiner als {max} KB sein.`,
    'file-read-error': `Fehler beim Lesen der Datei`,
    'new-password-retype': `Geben Sie das neue Kennwort erneut ein`,
    'new-password-different': `Das neue Kennwort und das erneut eingegebene Kennwort müssen übereinstimmen`,
    'fill-details': `Vervollständigen Sie diese Angaben`,
    'characters-not-allowed': `Die folgenden Zeichen sind nicht zulässig: {chars}`,
    'minimum-payment': `Der Mindestzahlungsbetrag ist {value}`,
    'payment-description-none': `Die Zahlungsbeschreibung muss mindestens ein alphabetisches Zeichen enthalten`,
    'payment-enter-amount': `Geben Sie einen gültigen Betrag ein`,
    'type-used': `{thing} wird bereits verwendet`,
    'cannot-validate-type': `Wert vom Typ {thing} kann nicht validiert werden`,
    'ip-family-required': `Sie müssen den Familienwert der IP-Adresse angeben`,
    'ip-address-used': `Diese Adresse wird bereits verwendet`,
    'ip-address-subnet-required': `IP-Adresse muss eine Subnetzmaske enthalten`,
    'ip-address-subnet-not-required': `Subnetzmaske ist nicht erforderlich`,
    'ip-address-overlap': `Überlappende IPs sind auf der Schnittstelle nicht zulässig`,
    'network-broadcast-exception': `Adresse ist eine Broadcast-Adresse; eine Host-Adresse wird erwartet`,
    'network-address-exception': `Adresse ist eine Netzwerk-Adresse; eine Host-Adresse wird erwartet`,
    'local-peer-ip-same-network': `Lokale IP-Adresse und Peer-IP-Adresse sind nicht in demselben IP-Netzwerk`,
    'peer-network-address': `Peer-IP-Adresse kann nicht die Netzwerk-Adresse sein`,
    'peer-broadcast-address': `Peer-IP-Adresse kann nicht die Broadcast-Adresse sein`,
    'peer-same-local-address': `Peer-IP-Adresse kann nicht dieselbe Adresse wie die lokale IP-Adresse sein`,
    'ipv4-mismatch': `IPv6-Adressen können nicht zur IPv4-Filterliste hinzugefügt werden`,
    'ipv6-mismatch': `IPv4-Adressen können nicht zur IPv6-Filterliste hinzugefügt werden`,
    'number-required': `Wert muss eine Zahl sein`,
    'range-min': `Bereich muss bei {min} beginnen`,
    'range-max': `Bereich muss bei {max} enden`,
    'range-min-exceed-max': `Minimalwert kann nicht größer als Maximalwert sein`,
    'range-max-exceed-min': `Maximalwert kann nicht kleiner als Minimalwert sein`,
    'range-min-exceeds-cidr': `Minimalwert kann nicht kleiner als CIDR-Wert sein`,
    'thing-between-mix-max': `{thing} muss zwischen {min} und {max} liegen`,
    'value-min': `Minimaler Wert ist {min}`,
    'value-max': `Maximaler Wert ist {max}`,
    'value-required': `Sie müssen einen Wert angeben`,
    'value-integer': `Wert muss eine Ganzzahl sein`,
    'value-positive': `Ein positiver Wert ist erforderlich`,
    'value-decimal-places': `Ein Wert kann {places} Dezimalstellen haben`,
    'broadcast-address': `Nächster Hop darf keine Broadcast-Adresse sein`,
    'next-hop-same-ip-family': `Nächster Hop muss in der gleichen IP-Familie sein wie das Präfix`,
    'next-hop-same-network': `Nächster Hop muss im gleichen Netzwerk sein wie eine der Schnittstellen-IPs`,
    'next-hop-not-interface-address': `Nächster Hop darf nicht als Schnittstellen-IP-Adresse aufgeführt sein`,
    'next-hop-network-address': `Nächster Hop ist eine Netzwerkadresse. Eine Host-Adresse wird erwartet`,
    'bgp-password': `Kennwort kann nur alphanumerische und Sonderzeichen (!@#$%^&*+-=_) enthalten`,
    'bpg-password-length': `Kennwort muss ein Wert zwischen 0 und 25 Zeichen sein`,
    'min-max-range': `Werte müssen zwischen {mix} und {max} liegen`,
    'bpg-no-connections': `Es wurden keine BGP-Verbindungen gefunden`,
    'bfd-transmit': `Sendeintervall muss zwischen 300 und 9000 liegen`,
    'bfd-receive': `Empfangsintervall muss zwischen 300 und 9000 liegen`,
    'bfd-multiplier': `Minimaler Multiplikator von 3 ist erforderlich`,
    'name-already-used': `Dieser Name wird verwendet`,
    'address-family-required': `Adressfamilie ist erforderlich`,
    'vlan-unique': `Die VLAN-ID muss eindeutig und darf nicht größer als {max} sein.`,
    'invalid-address-domain': `Geben Sie eine gültige IP-Adresse oder Domäne an`,
    'vendor-image': `Wählen Sie das Anbieterbild aus`,
    'email-invalid-seperators': `Geben Sie entweder eine einzelne E-Mail-Adresse oder eine durch Kommata getrennte Liste von E-Mail-Adressen an.`,
    'no-approval-price-title': `Sie haben keine Berechtigung zum Genehmigen von Preisänderungen.`,
    'no-approval-price-message': `Nur Benutzer mit Bestellberechtigung können Änderungen vornehmen, die sich auf den Preis eines Dienstes auswirken.`,
    'enter-valid-auth-key': `Geben Sie einen gültigen Wert für {thing} ein`,
    '128-chars': `Darf maximal 128 Zeichen lang sein.`,
    'min-number': `Muss mindestens 1 Ziffer enthalten | Muss mindestens {min} Ziffern enthalten`,
    'min-upper-case': `Muss mindestens 1 Großbuchstaben enthalten | Muss mindestens {min} Großbuchstaben enthalten`,
    'min-lower-case': `Muss mindestens 1 Kleinbuchstaben enthalten | Muss mindestens {min} Kleinbuchstaben enthalten`,
    'min-symbol': `Muss mindestens 1 Symbolzeichen enthalten | Muss mindestens {min} Symbolzeichen enthalten`,
    'as-path-prepend': `Geben Sie eine Zahl von 0 bis 10 für die AS-Pfadvoranstellung ein`,
    'api-keys-expiry': `Geben Sie eine Zahl zwischen 5 und 1.440 ein`,
    'api-keys-expiry-decimal': `Gültigkeitsdauer muss eine Ganzzahl sein`,
    'min-max-alphanumeric': `Muss {min} bis {max} alphanumerische Zeichen enthalten`,
    'mfa-token': `Geben Sie Ihr MFA-Token ein`,
    vnic: `Auswahl der vNIC-Ebene ist erforderlich`,
    'bgp-password-valid-characters': `Gültige Zeichen sind: a-z A-Z 0-9 ! @ # ? . $ % ^ & * = -`,
    'ix-peer-invalid-characters': `Nur Buchstaben, Zahlen, Doppelpunkte, Unterstriche und Bindestriche`,
    'ix-peer-invalid-format': `Bitte geben Sie einen durch Doppelpunkte getrennten ASN-Satz mit AS-Präfix ein`,
    'asn-validation-range-triple': `ASN-Bereich {minAsn}-{maxAsn}, {minAsn2}-{maxAsn2} oder {minAsn3}-{maxAsn3} (Standard ist {defaultAsn})`,
    'required-pattern': `Wert muss im Muster {thing} sein`,
    'rfc-number-example': `Muss {total} Zeichen enthalten ({start} alphanumerische Zeichen, dann 6 Ziffern, dann 3 alphanumerische Zeichen)`,
    'idp-name-invalid-characters': `Der Anbietername darf nur Kleinbuchstaben, Zahlen und Bindestriche enthalten`,
    alphanumeric: `{thing} darf nur aus alphanumerischen Zeichen bestehen`,
    'reverse-dns-invalid-characters': `Gültige Zeichen sind: a-z A-Z 0-9 . -`,
    'invalid-field': `Geben Sie eine gültige {thing} ein`,
  },
  connections: {
    'choose-type': `Zieltyp auswählen`,
    cloud: `Cloud`,
    'internet-exchange': `Internetknoten`,
    csp: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Verbindung zu einem Cloud Service Provider`,
    private: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Verbindung zu Ihrem eigenen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-, ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}- oder ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}-Produkt`,
    'private-vxc': `Privater ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    ix: `Mit einem ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ${process.env.VUE_APP_EN_PRODUCTNAME_IX} verbinden`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Verbindung zu einem Partner-${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'enter-service-key': `Dienstschlüssel eingeben`,
    'service-key': `Wählen Sie diese Option aus, wenn Sie einen ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Dienstschlüssel erhalten haben.`,
    'select-destination-port': `Wählen Sie das Ziel ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} aus`,
    'awaiting-deployment': `Die Verbindung wartet auf Bereitstellung. Wenn die Bereitstellung ungewöhnlich lange dauert, wenden Sie sich an den ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Support.`,
    'approval-error': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Genehmigungsfehler.`,
    'deny-error': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Verbindungsablehnungsfehler`,
    'untag-vlan-tooltip': `Konfigurieren Sie diesen Dienst ohne VLAN-ID. Dadurch wird der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auf einen einzigen virtuellen Dienst begrenzt.`,
    'used-vlan': `Auf diesem ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wurde bereits eine Verbindung konfiguriert, die dieses VLAN verwendet. Wenn Sie fortfahren, wird Ihnen ein zufällig ausgewähltes VLAN zugewiesen.`,
    'asn-from-mcr': `ASN verwendet automatisch ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ASN`,
    'azure-ports': `Wählen Sie aus den verfügbaren Azure ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'select-destination-port-title': `Ziel-${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auswählen`,
    'no-available-target-ports': `Keine verfügbaren Ziel-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'please-select-port': `Bitte wählen Sie ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} aus`,
    'no-available-ports-key': `Auf diesem Dienstschlüssel sind keine ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} verfügbar. Bitte versuchen Sie einen anderen.`,
    'all-ports-used-key': `Alle mit diesem Dienstschlüssel verbundenen ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} wurden von anderen Diensten verbraucht. Bitte versuchen Sie einen anderen.`,
    'port-in-use-title': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wird bereits verwendet`,
    'port-in-use-message': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ist nicht zur Auswahl verfügbar, da er bereits verwendet wird.`,
    'google-ports': `Wählen Sie aus verfügbaren Google-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'no-google-services': `Es sind keine Google Cloud Services in der Region des ausgewählten ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} verfügbar.`,
    'no-google-ports': `Auf diesem Kopplungsschlüssel sind keine ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} verfügbar. Bitte versuchen Sie einen anderen.`,
    'nutanix-ports': `Wählen Sie aus verfügbaren Nutanix-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'oracle-ports': `Wählen Sie aus verfügbaren Oracle-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'no-oracle-ports': `Es sind keine ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} auf dieser virtuellen Verbindung verfügbar. Bitte versuchen Sie einen anderen.`,
    'all-oracle-ports-taken': `Alle ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} bei dieser virtuellen Verbindung wurden von anderen Diensten belegt. Versuchen Sie einen anderen.`,
    'list-all-oracle-ports': `Eine Liste aller verfügbaren Oracle-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} finden Sie unter dem zugehörigen | ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Profil`,
    'ix-details': `Verbindungsdetails für ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienst`,
    'ix-asn-message': `Hinweis: Die ASN kann für ${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}-Dienste nach der Bereitstellung nicht mehr geändert werden.`,
    'mcr-config': `Details zu ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} {end}-Endverbindung`,
    'mcr-override-asn': `Überschreiben der ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-ASN`,
    'mcr-override-asn-tooltip': `Die lokale ASN kann die ASN des ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} für diesen BGP-Peer überschreiben. In den meisten Fällen sollte der Standard beibehalten werden.`,
    'mcr-setup-tooltip': `Für jeden über einen ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} hergestellten ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} können Sie eine oder mehrere Schnittstellen, IP-Adressen, BGP-Verbindungen oder statische Routen konfigurieren. Die meisten ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} verwenden eine einzige Schnittstelle. Sie können aber auch mehrere Schnittstellen mit "Q-in-Q" konfigurieren, indem Sie für jede Schnittstelle ein inneres VLAN-Tag angeben. Die Tag-Protokoll-ID (TPID) wird auf 0x8100 gesetzt.`,
    'mcr-ip-address-tooltip': `IP-Adresse und zugehörige Subnetzmaske, die auf dieser Schnittstelle konfiguriert werden soll.<br /><br />Mehrere Adressen sind zulässig. Sowohl IPv4 als auch IPv6 werden unterstützt.<br /><br />Sie können dieses Feld leer lassen, um die Konfiguration der Schnittstelle bis nach der Bereitstellung des ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} zu verschieben.`,
    'aws-diversity-tooltip': `Bestellen Sie ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} für unterschiedliche Farbzonen, wenn Sie Gerätediversität möchten.`,
    'no-matching-ports': `Keine übereinstimmenden Ziel-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'service-key-id': `${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Dienstschlüssel-ID`,
    'service-key-unavailable': `Der Dienstschlüssel ist für den aktuellen Quell-{product} und kann nicht verwendet werden.`,
    'port-asn': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-ASN`,
    'port-ip': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-IP-Adresse`,
    'aws-default-asn': `Standard-ASN des AWS-Peer. Die ASN wird überschrieben, falls dieser ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} einem Direct Connect Gateway zugeordnet ist.`,
    'vxc-is-down': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Dienst ist nicht verfügbar`,
    'vxc-is-up': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} Dienst ist verfügbar`,
    'vxc-status-unknown': `Der Status dieses ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Dienstes ist unbekannt`,
    'ix-is-down': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienst ist nicht verfügbar`,
    'ix-is-up': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_IX} Dienst ist verfügbar`,
    'ix-status-unknown': `Der Status dieses ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienstes ist unbekannt`,
    'speed-req-external-approval': `Hinweis: Eigentümer dieses ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} ist eine andere Firma, daher müssen alle Geschwindigkeitsänderungen von dieser Firma genehmigt werden.`,
    'ix-service': `${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienst`,
    'new-vxc': `Neue ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Verbindung`,
    'preferred-end-vlan': `Bevorzugtes {title}-VLAN`,
    'amsix-contact-phone': `Eine Telefonnummer ist für {contactType} eines AMS-IX-Dienstes erforderlich.`,
    'cancel-a-end': `Beachten Sie, dass ${process.env.VUE_APP_EN_COMPANYINFO_NAME} nur das A-Ende dieses Dienstes storniert. Um laufende Gebühren von <b>{companyName}</b> zu verhindern, müssen Sie sicherstellen, dass Sie Ihren Dienst direkt bei <b>{companyName}</b> stornieren.`,
    'no-matching-source-port': `Keine übereinstimmenden Quell-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'no-selected-source-port': `Wählen Sie den Quell-${process.env.VUE_APP_EN_PRODUCTNAME_PORT} aus`,
    'non-edit-reason': `Hinweis: Die Verbindungsdetails können nicht geändert werden, da | {reason}`,
    'pending-approval': `Die Verbindung wartet auf die externe Genehmigung von {approvalFrom}.`,
    'pending-speed-approval': `Die Verbindung wartet auf die externe Genehmigung von {approvalFrom} für eine Geschwindigkeitsänderung.`,
    'pending-speed-approval-to': `Ausstehende Änderung des Übertragungsratenlimits auf {newSpeed} Mbit/s wartet auf Genehmigung von {approvalFrom}.`,
    'connection-denied': `VXC zu {destination} wurde abgelehnt`,
    'connection-approved': `VXC zu {destination} wurde genehmigt`,
    'peer-config': `{type}-Peer-Konfiguration`,
    'min-bfg-tx-interval': `Minimales Sendeintervall beträgt {min} ms`,
    'min-bfg-rx-interval': `Minimales Empfangsintervall beträgt {min} ms`,
    'min-bfg-multiplier': `Minimaler Multiplikator ist {min}`,
    'key-expired': `Dieser Dienstschlüssel ist nicht mehr gültig. Wenden Sie sich an {company}, um dieses Problem zu lösen`,
    'key-used': `Dieser Dienstschlüssel für den Einmalgebrauch wurde bereits verwendet. Wenden Sie sich an {company}, um dieses Problem zu lösen`,
    'key-invalid': `Dieser Dienstschlüssel ist für die Verwendung nicht gültig. Wenden Sie sich an {company}, um dieses Problem zu lösen`,
    'ix-rate-limit-help': `Das ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Übertragungsratenlimit ist bei Metro-Verbindungen auf die aggregierte ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Geschwindigkeit und bei Nicht-Metro-Verbindungen auf 10 Gbit/s begrenzt.`,
    'vxc-rate-limit-help': `Das maximale Übertragungsratenlimit für eine ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} wird durch die niedrigste Geschwindigkeit an jedem Ende und die Netzkapazität auf der Strecke bestimmt.`,
    'max-ip-addresses': `Maximal {max} IP-Adressen`,
    'max-static-routes': `Maximal {max} statische Routen`,
    'max-bgp-connections': `Maximal {max} BGP-Verbindungen`,
    'mcr-end-visibility': `Klicken Sie zum {operation} der Details zum ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-{end}-Ende`,
    'mcr-end-details': `Details zum ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-{end}-Ende`,
    'mcr-end': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-{end}-Ende`,
    'missing-azure-service-key': `Der Dienstschlüssel für den Azure ExpressRouter-Dienst fehlt. Für die Bestellung dieses Dienstes müssen Sie auf dem Bildschirm "${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auswählen" den Dienstschlüssel bereitstellen.`,
    'missing-google-pairing-key': `Diesem Google-Dienst fehlt der Kopplungsschlüssel. Für die Bestellung dieses Dienstes müssen Sie auf dem Bildschirm "${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auswählen" den Dienstschlüssel bereitstellen.`,
    'new-bgp-state': `Neu erstellte BGP-Verbindungen befinden sich im Status {state}.`,
    'google-key-format': `Google Keys haben folgendes Format:`,
    'bgp-peer-asn': `Peer-ASN`,
    'connection-details': `Verbindungsdetails`,
    'connection-name': `Verbindungsname`,
    'vlan-fixed': `Hinweis: Das VLAN kann für diesen Diensttyp nach der Bereitstellung nicht mehr geändert werden.`,
    'configure-azure-vlan': `Einzelnes Azure-Peering-VLAN konfigurieren`,
    'azure-vlan': `Azure-Peering-VLAN`,
    'enter-vlan': `VLAN eingeben`,
    'preferred-vlan': `Bevorzugtes VLAN`,
    asn: `ASN`,
    'asn-full': `Autonome Systemnummer`,
    'mac-address': `MAC-Adresse`,
    'mac-address-format': `Geben Sie eine MAC-Adresse in einem der folgenden Formate ein: 3c22.fb80.749c oder 3c:22:fb:80:74:9c`,
    'bgp-password': `BGP-Kennwort`,
    'peer-macro': `Peer-Makro`,
    'graph-visibility': `Diagrammsichtbarkeit`,
    untagged: `Nicht getaggt`,
    shutdown: `Herunterfahren`,
    enabled: `aktiviert`,
    'end-not-configured': `Das {end}-Ende wurde noch nicht konfiguriert.`,
    'interface-vlan': `Schnittstellen-VLAN`,
    'ip-addresses': `IP-Adressen`,
    'static-routes': `Statische Routen`,
    'bgp-connections': `BGP-Verbindungen`,
    'nat-ip-addresses': `NAT-IP-Adressen`,
    'b-vlan-service-key': `B-Ende-VLAN (aus Dienstschlüssel)`,
    'hide-cloud-details': `Klicken Sie, um Details zur Cloud auszublenden`,
    'show-cloud-details': `Klicken Sie, um Details zur Cloud anzuzeigen`,
    'cloud-details': `Details zur Cloud`,
    'peering-policy': `Peering-Richtlinie`,
    'policy-url': `Richtlinien-URL`,
    'peering-email': `Peering-E-Mail`,
    'company-url': `Firmen-URL`,
    'company-email': `Firmen-E-Mail`,
    'street-address': `Straße`,
    city: `Ort`,
    'state-province': `Bundesland/Kanton`,
    'postcode-zip': `PLZ`,
    country: `Land`,
    type: `Typ`,
    'aws-connection-name': `AWS-Verbindungsname`,
    'aws-owner-acct-id': `AWS-Eigentümer-Konto-ID`,
    'customer-ip': `Kunden-IP-Adresse`,
    'amazon-ip': `Amazon-IP-Adresse`,
    'alibaba-account': `Alibaba-Konto-ID`,
    'pairing-key': `Kopplungsschlüssel`,
    'virtual-circuit-id': `ID der virtuellen Verbindung`,
    deleted: `Verbindung gelöscht`,
    'select-destination': `Ziel auswählen`,
    'configure-a-end': `A-Ende konfigurieren`,
    'configure-b-end': `B-Ende konfigurieren`,
    'vlan-value': `VLAN-Wert`,
    save: `Verbindung speichern`,
    'approve-prompt': `Möchten Sie diese Verbindung genehmigen?`,
    'approve-connection': `Verbindung genehmigen`,
    'deny-connection': `Verbindungsanfrage ablehnen`,
    configuration: `Verbindungskonfiguration`,
    'awaiting-approval': `Diese Verbindung wartet auf Ihre Genehmigung.`,
    'approve-question': `Möchten Sie diese Anfrage wirklich genehmigen?`,
    'approve-title': `Anfrage genehmigen`,
    'deny-question': `Möchten Sie diese Anfrage wirklich ablehnen?`,
    'deny-title': `Anfrage ablehnen`,
    'cancel-question': `Möchten Sie diese Anfrage wirklich abbrechen?`,
    'cancel-title': `Anfrage abbrechen`,
    'change-approved-title': `Geschwindigkeitsänderung genehmigt`,
    'change-approved-message': `Das neue Übertragungsratenlimit wurde genehmigt.`,
    'change-denied-title': `Geschwindigkeitsänderung erfolgreich abgelehnt`,
    'change-denied-message': `Die Anfrage zur Änderung des Übertragungsratenlimits wurde abgelehnt.`,
    'change-cancelled-title': `Geschwindigkeitsänderung erfolgreich storniert`,
    'change-cancelled-message': `Die Anfrage zur Änderung des Übertragungsratenlimits wurde storniert.`,
    'approved-title': `Verbindung genehmigt`,
    'denied-title': `Verbindung erfolgreich abgelehnt`,
    untag: `Tag entfernen`,
    'auto-assigned': `Automatisch zugewiesen`,
    'auto-assign': `VLAN automatisch zuweisen`,
    'vlan-checking': `VLAN-Verfügbarkeit wird geprüft ...`,
    'add-connection': `Verbindung hinzufügen`,
    open: `Geöffnet`,
    closed: `Geschlossen`,
    vlan: `VLAN`,
    'valid-service-key': `Gültiger Dienstschlüssel`,
    'verifying-key': `Überprüfen des Schlüssels`,
    'select-provider': `Anbieter auswählen`,
    'no-matching-providers': `Keine übereinstimmenden Anbieter`,
    'select-connection-type': `Verbindungstyp auswählen`,
    'bgp-statuses': `BGP-Status`,
    'end-vlan': `{end}-Ende-VLAN`,
    'route-servers': `Routeserver`,
    'bgp-down': `BGP ist nicht verfügbar`,
    'bgp-up': `BGP ist verfügbar`,
    'bgp-unknown': `BGP-Status ist unbekannt`,
    interface: `Schnittstelle`,
    'old-speed': `Alte Geschwindigkeit`,
    'new-speed': `Neue Geschwindigkeit`,
    'approve-speed-question': `Möchten Sie diese Geschwindigkeitsänderung genehmigen?`,
    'deny-speed-change': `Anfrage zur Geschwindigkeitsänderung abgelehnt`,
    'approve-speed-change': `Geschwindigkeitsänderung genehmigen`,
    'cancel-speed-request': `Anfrage zur Geschwindigkeitsänderung abbrechen`,
    'connections-html': `Einzelheiten finden Sie unter {link}`,
    'speed-change-pending-your-approval': `Ausstehende Genehmigung durch Sie: Eine Geschwindigkeitsänderung für diese Verbindung wurde angefragt.`,
    'speed-change-pending-company-approval': `Ausstehende Genehmigung durch Ihre Firma: Eine Geschwindigkeitsänderung für diese Verbindung wurde angefragt (erfordert Genehmigung durch Ihre Firma).`,
    connection: `Verbindung`,
    'lag-full': `Diese ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} kann keine neuen Verbindungen akzeptieren`,
    'port-full': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} kann keine neuen Verbindungen akzeptieren`,
    'show-connections': `Klicken Sie, um Verbindungen anzuzeigen`,
    'hide-connections': `Klicken Sie, um Verbindungen auszublenden`,
    'service-key-vlan-range': `VLAN 2-4094 (für Mehrfachgebrauch-Dienstschlüssel deaktiviert)`,
    'third-party-owner': `Dritteigentümer`,
    'b-end': `Sie befinden sich am B-Ende dieser Verbindung, das einer anderen Firma gehört`,
    'shut-down-by-user': `Heruntergefahren durch Benutzer`,
    'b-end-no-delete': ` Verbindungsende kann die Verbindung nicht löschen`,
    'speed-change-from': `Geschwindigkeitsänderung von`,
    'new-connection-from': `Neue Verbindung von`,
    'best-route-explanation': `Ein Häkchen zeigt an, dass der ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} bei der Auswahl zwischen zwei Routen zum selben Ziel diese Route bevorzugt.`,
    'as-path': `AS-Pfad`,
    'local-preference': `Lokale Präferenz`,
    med: `Multi-Exit Discriminator`,
    metric: `Metrik`,
    protocol: `Protokoll`,
    distance: `Entfernung`,
    'public-ipv4': `Öffentliche IP-Adresse (IPv4)`,
    'public-ipv6': `Öffentliche IP-Adresse (IPv6)`,
    'gateway-ipv4': `Gateway-IP-Adresse (IPv4)`,
    'gateway-ipv6': `Gateway-IP-Adresse (IPv6)`,
    'match-label': `Übereinstimmung`,
    'dont-match-label': `Keine Übereinstimmung`,
    'delete-route-label': `Route löschen`,
    'connected-port-removed': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} oder ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}, mit dem dieser Dienst verbunden war, wurde aus den Daten entfernt. Umleiten zu Diensten`,
    approving: `Genehmigen`,
    denying: `Ablehnen`,
    'approval-issues': `Probleme beim {action} von ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}: {errorStr}`,
    'confirm-bgp-state-change': `Dadurch wird der BGP-Status zu {newState} geändert, und alle an diesem Dienst vorgenommenen Änderungen werden bestätigt. Es kann bis zu zwei Minuten dauern, bis Änderungen sichbar werden.`,
    'bgp-state-change-prompt': `Änderung von BGP-Status bestätigen?`,
    'awaiting-approval-or-denial': `Es wird darauf gewartet, dass Sie diese Verbindung genehmigen/ablehnen.`,
    'azure-key-unused-key': `An diesem Standort verfügbar`,
    'azure-key-used-key': `Kann mehrmals verwendet werden, wenn ein eindeutiges Azure-Peering-VLAN angegeben wird`,
    'azure-key-unused': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wurde noch nicht mit diesem Dienstschlüssel verwendet`,
    'azure-key-used': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} kann mehrmals verwendet werden, wenn ein eindeutiges Azure-Peering-VLAN angegeben wird`,
    'outscale-connection-name': `Name der Outscale-Verbindung`,
    'outscale-account': `Outscale-Eigentümer-Konto-ID`,
    'outscale-ip': `Outscale-IP-Adresse`,
    transit: `Transit Gateway-Zugang über eine von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} bereitgestellte Verbindung`,
    'vxc-name-default': `{aEnd} zu {bEnd}`,
    'customer-asn': `Kunden-ASN`,
    vnic: `vNIC`,
    'reverse-dns': `Reverse DNS`,
    'virtual-interfaces-vnics': `Virtuelle Schnittstellen (vNICs)`,
    'vnic-plane': `vNIC-{index} {plane} Ebene`,
    'end-vnic': `{end}-Ende vNIC`,
    'create-ip-transit-vxc': `${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} erstellen`,
    'mcr-full': `Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} kann keine neuen Verbindungen annehmen`,
    'mve-full': `Diese ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} kann keine neuen Verbindungen annehmen`,
    'provider-ip': `IP-Adresse des Anbieters`,
    'ibm-account': `IBM Cloud-Konto-ID`,
    'mve-untag-vlan-tooltip': `Konfigurieren Sie diesen Dienst ohne VLAN-ID.`,
    'no-transit-port-title': `Suche nach ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} für ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} fehlgeschlagen`,
    'no-transit-port-message': `Ein ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} konnte nicht gefunden werden. Bitte wenden Sie sich an ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'a-end-vnic-help': `Virtuelle Schnittstelle des Initiierungsdienstes`,
    'b-end-vnic-help': `Virtuelle Schnittstelle des Beendigungsdienstes`,
    'no-description': `Keine Beschreibung`,
    'select-connections': `Wählen Sie eine oder mehrere Verbindungen`,
    'move-connection': `Verbindung verschieben`,
    'select-new-end': `Wählen Sie ein neues Ende`,
    'move-connection-confirm': `Bitte bestätigen Sie, dass Sie fortfahren und die ausgewählte(n) Verbindung(en) von {currentEnd} nach {newEnd} verschieben möchten.`,
    'move-connection-diversity': `Hinweis: Die Diversitätszone wechselt von {currentDiv} zu {newDiv}. Dies kann Ihre neue Diversität beeinträchtigen.`,
    'move-connection-disclaimer': `Während des Verschiebens ist/sind die Verbindung(en) nicht verfügbar. Das Verschieben kann bis zu 2 Minuten in Anspruch nehmen.`,
    'no-valid-connections': `Keine gültigen Verbindungen gefunden`,
    'no-valid-destinations': `Keine gültigen Ziele gefunden`,
    'invalid-move-existing-end': `Ziel {type} ist bereits mit der ausgewählten Verbindung verbunden`,
    'invalid-move-multiple-vnics': `Ziel {type} hat mehrere vNICs`,
    'invalid-move-too-small': `Unzureichende Kapazität am Ziel {type}`,
    'invalid-move-new-untagged': `Ziel {type} kann keine ungetaggte Verbindung annehmen`,
    'invalid-move-untagged': `Ziel {type} hat eine ungetaggte Verbindung`,
    'invalid-move-vlan-taken': `VLAN auf Ziel {type} nicht verfügbar`,
    'search-for-providers': `Mehr Anbieter anzeigen`,
    'termed-delete-etf': `Gemäß den Bedingungen Ihres Vertrags mit Megaport fallen bei Löschung einer befristeten Verbindung Gebühren für vorzeitige Kündigung an.`,
    'avoid-etf': `Wählen Sie zur Vermeidung dieser Gebühren die Option „Keine Mindestlaufzeit“ aus, bis die Konfigurationsdetails korrekt sind. Sie können die Verbindung jederzeit aktualisieren, wenn Sie sich für eine Laufzeit entschieden haben.`,
    'rate-limit-change-disallowed': `Dies ist eine befristete Verbindung, bei der Änderungen des Übertragungsratenlimits nicht zulässig sind, es sei denn, die Verbindung wird neu befristet oder das Übertragungsratenlimit wird erhöht.`,
    'rate-limit-change-term': `Dies ist eine befristete Verbindung, bei der das Übertragungsratenlimit nicht unter die gewählte Geschwindigkeit von {termSpeed} Mbit/s gesenkt werden kann.`,
    'private-vxc-no-valid-target': `Sie haben keine gültigen Dienste, mit denen Sie eine private Verbindung herstellen können`,
    'vnic-details': `Details zu vNIC`,
    'new-vnic-details': `Details zu neuem vNIC`,
    'new-end-vnic': `Neuer {end}-Ende-vNIC`,
    'new-preferred-end-vlan': `Neues bevorzugtes {title}-VLAN`,
    'move-connection-vnic-warning': `Anmerkung: Der vNIC und das VLAN auf der Ziel-${process.env.VUE_APP_EN_PRODUCTNAME_MVE} werden ebenfalls auf die angegebenen Werte geändert.`,
    'confirm-move': `Verschieben bestätigen`,
    'speed-req-external-approval-a-end': `Anmerkung: Eigentümer dieses ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} ist eine andere Firma, daher ist es Ihnen nicht gestattet, Geschwindigkeitsänderungen an deren ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} vorzunehmen.`,
    'speed-req-external-approval-b-end': ` Anmerkung: Dieser ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} ist mit einer anderen Firma verbunden, daher müssen alle Geschwindigkeitsänderungen von dieser Firma genehmigt werden.`,
  },
  ports: {
    'port-deleted': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} gelöscht`,
    'select-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auswählen`,
    'port-speed': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Geschwindigkeit`,
    'select-port-speed': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Geschwindigkeit auswählen`,
    'mcr-explanation': `Ein ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) ist eine Layer 3-fähige, virtuelle Routing-Instanz, die von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} an Cloud-Hotspot-Standorten weltweit bereitgestellt und gehostet wird. Er benötigt keinen physischen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, aber Sie können bei Bedarf eine Verbindung zu einem Port herstellen.`,
    'port-main-tooltip': `Ein physischer ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ist erforderlich, wenn Sie Ihre Ausrüstung mit unserer verbinden. Wir unterstützen 1G-, 10G- oder 100G-Glasfaserschnittstellen (allgemein Duplex SMOF – prüfen Sie Ihren LOA, der während der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Bereitstellung für standortspezifische Anforderungen abgerufen wurde) an mit ${process.env.VUE_APP_EN_COMPANYINFO_NAME} verbundenen Standorten.`,
    'diverse-from-existing': `Unterscheidet sich von bestehendem ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'ports-count': `0 ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | 1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'ports-type-count': `0 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}| 1 {type} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | {count}{type}${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'pluralize-ports': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} | ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'services-attached-count': `0 Dienste verbunden | 1 Dienst verbunden | {count} Dienste verbunden`,
    'percent-allocated': `{percent} % zugewiesen`,
    'awaiting-resources': `Dieser {type} wartet auf Ressourcenzuweisung.`,
    'add-connection-to': `Neue Verbindung zu dieser/m/n {type} hinzufügen`,
    'no-mcr-speed-change': `Das Übertragungsratenlimit des ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ist für die Laufdauer des Dienstes festgelegt.`,
    'marketplace-visibility': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Sichtbarkeit`,
    'lacp-ports': `LACP & ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'lacp-on-port': `LACP für ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}`,
    'add-to-lag': `Zu ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} hinzuzufügende ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}`,
    'ports-in-lag': `Anzahl der ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} in ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-asn': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-ASN`,
    'summary-adding-to-lag': `Hinzufügen von ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} zu ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'summary-ports-in-lag': `${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} in ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'no-slow-ports-in-lag': `Keine ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Geschwindigkeiten <10 Gbit/s in ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'mcr-rate-limit': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Übertragungsratenlimit`,
    'marketplace-short-visibility': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}-Sichtbarkeit`,
    'public-port-info': `Wenn Sie einen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auf „Public“ (Öffentlich) setzen, wird der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} auf ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} öffentlich angezeigt.`,
    'public-port-tooltip': `Kunden können ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Verbindungen für Ihren ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} anfordern`,
    'private-port-tooltip': `Ihr ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wird privat sein und nicht verfügbar für Verbindungsziele auf dem ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    'allocated-tooltip': `Die ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Geschwindigkeit und das kumulierte Übertragungsratenlimit seiner Dienste`,
    'port-removed': `Der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wurde aus den Daten entfernt. Umleiten zu Diensten`,
    'contract-terms': `Die Vertragsbedingungen werden angezeigt, wenn der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} in Betrieb ist`,
    statuses: `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Status`,
    'configure-details': `Details für die hinzugefügten ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} konfigurieren`,
    'add-ports-to-lag': `Neue ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} zu dieser ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} hinzufügen`,
    'lag-full': `Diese ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} kann keine weiteren ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} akzeptieren`,
    'max-speed-tooltip': `Übertragungsratenlimit in Mbit/s. Muss gleich oder kleiner als {speed} sein. Wenn Sie dieses Feld leer lassen, wird die maximal verfügbare Geschwindigkeit für den {productType} verwendet.`,
    'vxc-speed-positive-integer': `${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Geschwindigkeit muss eine positive Ganzzahl sein`,
    'vxc-speed-max': `Die maximale ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Geschwindigkeit beträgt {speed} Mbit/s`,
    'lag-primary-deletion-locked': `Primärer ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} der ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} kann nicht gelöscht werden, da es gesperrte ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} in der ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} gibt`,
    'deletion-insufficient-capacity': `Durch Löschen dieses ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}s führt zu unzureichender Kapazität für die aktuellen Verbindungen der ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'link-aggregation': `Linkaggregation für {portName}`,
    'attached-vxc-locked': `Verbundener ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} ist gesperrt`,
    'attached-ix-locked': `Verbundener ${process.env.VUE_APP_EN_PRODUCTNAME_IX} ist gesperrt`,
    'terminate-date': `Dieser Dienst endet am {date}`,
    'autoconfig-mcr': `Die ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Verbindungskonfiguration wird automatisch generiert. Sobald der Dienst in Betrieb ist, können Sie die Konfiguration ändern.`,
    'count-port-lag': `- | (1 ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}) | ({count} ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS})`,
    'live-lag-disclaimer': `Hinweis: Beim Hinzufügen von ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} zu einer aktiven ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} werden dem neuen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} bzw. den neuen ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} automatisch derselbe Standort, dieselbe Geschwindigkeit und dieselbe ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Sichtbarkeit wie dem Rest der ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} zugewiesen.`,
    'public-port-warning': `Durch Aktivierung dieser Option, verstehen und akzeptieren Sie, dass Informationen über den ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} (wie Geschwindigkeit und Standort) öffentlich zugänglich sein werden. Sie können die Einstellung für diesen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} jederzeit zurück auf "Privat" ändern.`,
    'lag-explanation': `Erstellt diesen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} mit aktiviertem LACP-Protokoll, sodass er in einer ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) verwendet werden kann und Ihnen ermöglicht, die Anzahl der ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} anzugeben, die in die ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} aufgenommen werden sollen.`,
    'add-lag-ports-disclaimer': `Dadurch werden mehrere ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} zu dieser ${process.env.VUE_APP_EN_PRODUCTNAME_LAG_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_LAG}) hinzugefügt. Der angezeigte Preis gilt für alle ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, die der ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} hinzugefügt wurden.`,
    'port-diversity': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Diversität`,
    'diversity-unavailable-location': `Diversität ist aktuell nicht für diese Konfiguration verfügbar. Kontaktieren Sie <a href="mailto:${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}">${process.env.VUE_APP_EN_COMPANYINFO_SALES_EMAIL}</a>, um Ihre Diversitätsanforderungen zu erörtern.`,
    'diversity-explanation-html': `Mit ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Diversität können sich ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} am selben Standort in unterschiedlichen Diversitätszonen befinden. Das Erstellen redundanter ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} in unterschiedlichen Zonen verringert das Risiko einer Dienstunterbrechung aufgrund eines einschneidenden Ereignisses auf einem einzelnen Gerät. Weitere Informationen finden Sie unter {link}.`,
    'select-lag-speed': `Minimale ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Geschwindigkeit zum Aktivieren von LACP ist 10 Gbit/s`,
    'select-speed-diversity': `Wählen Sie eine ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Geschwindigkeit aus, um Diversitätsoptionen anzuzeigen.`,
    'diversity-lag-add': `| Der neue ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} wird zur Diversitätszone der ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} hinzugefügt. | Die neuen ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} werden zur Diversitätszone der ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} hinzugefügt.`,
    'diverse-pair': `Zwei {things} werden konfiguriert und zu unterschiedlichen Diversitätszonen hinzugefügt.`,
    'diverse-from': `Diversifiziert von: {port}`,
    'port-diverse-from': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} soll diversifiziert sein von`,
    'first-port-name': `Erster {thing}-Name`,
    'second-port-name': `Zweiter {thing}-Name`,
    'delete-diverse-from': `- | Der folgende ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} ist für Diversität von dem ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} konfiguriert, den Sie beenden: - | Die folgenden ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} sind für Diversität von dem ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} konfiguriert, den Sie beenden:`,
    'reset-diversity': `- | Wenn Sie den ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} beenden, verliert der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} seine Diversitätseinstellung und hat keine spezifizierte Diversitätszone mehr. - | Wenn Sie den ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} beenden, verlieren die aufgelisteten ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} ihre Diversitätseinstellung und haben keine spezifizierte Diversitätszone mehr.`,
    'mcr-prefix-filter': `Das ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Präfixfilter-Feature unterstützt eingehende und ausgehende präfixbasierte BGP-Route-Filter. <br/><br/> <strong>Sie können Präfixlisten hinzufügen und konfigurieren, wenn Ihr Dienst konfiguriert wird.</strong>`,
    'bgp-default-state-tooltip': `Legen Sie fest, ob neu erstellte BGP-Verbindungen standardmäßig aktiviert oder heruntergefahren sind. Wählen Sie "Aktiviert" (Standardeinstellung) aus, wenn Sie möchten, dass neue BGP-Sitzungen, die Sie konfigurieren, aktiv werden, sobald Sie die Konfiguration speichern. Wählen Sie "Heruntergefahren" aus, wenn Sie möchten, dass alle neuen BGP-Sitzungen, die Sie konfigurieren, in einem deaktivierten Zustand belassen werden, wenn Sie die Konfiguration speichern.`,
    'mve-software': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}-Software`,
    'new-prefix-filter-list': `Neu`,
    'open-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} öffnen`,
    'prefix-filter-list': `Präfixfilterlisten`,
    'prefix-list-learn-more': `Weitere Informationen zu Präfixfilterlisten`,
    'prefix-list-loaded-success': `Ausgewählte Präfixliste erfolgreich geladen`,
    'prefix-list-loaded-fail': `Laden der ausgewählten Präfixliste fehlgeschlagen`,
    'lacp-enabled': `LACP aktiviert`,
    'diverse-pair-label': `Diversitätspaar`,
    'diverse-from-label': `Diversifiziert von`,
    'download-loa': `LOA herunterladen`,
    failed: `Aktualisierung des ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} fehlgeschlagen`,
    vendor: `Anbieter`,
    'mve-file-upload': `Datei hochladen`,
    'cloud-init-vmanage': `Generieren Sie eine Cloud-Init-Datei und laden Sie diese aus Ihrem vManage-System hoch`,
    'mve-init': `Initialisierungsdatei`,
    waiting: `Warten auf Querverbindung.`,
    'key-valid-until': `Gültig bis`,
    'service-keys': `Dienstschlüssel`,
    'lag-up': `Diese ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ist aktiv`,
    'lag-down': `Diese ${process.env.VUE_APP_EN_PRODUCTNAME_LAG} ist nicht verfügbar`,
    'key-saved': `Dienstschlüssel erfolgreich gespeichert.`,
    'key-added': `Dienstschlüssel erfolgreich hinzugefügt.`,
    'key-saved-message': `Dienstschlüssel [{description}] wurde gespeichert.`,
    'key-add-error': `Fehler beim Erstellen des Dienstschlüssels.`,
    'key-update-error': `Fehler beim Aktualisieren des Dienstschlüssels.`,
    'minimum-term': `Mindestlaufzeit`,
    // Der Abschnitt cannotBeEdited ist fett gedruckt und verweist auf die Zeichenfolge cannot-edit
    'uneditable-asn-html': `Sie können die Autonome Systemnummer (ASN) dieses ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} konfigurieren, oder Sie verwenden die von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} bereitgestellte öffentliche ASN {defaultAsn}. Die ASN wird für BGP-Peering-Verbindungen auf allen mit diesem ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} verbundenen ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} verwendet. Sie {cannotBeEdited}, sobald der ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} bestellt wurde. Beachten Sie, dass einige öffentliche Clouddienste die Verwendung einer öffentlichen ASN fordern. Lesen Sie die Dokumentation zu Ihrem Cloudanbieter, bevor Sie diesen Standardwert überschreiben.`,
    'bgp-default-state': `BGP-Standardstatus`,
    'site-deployment': `Dieser Standort wird bald in Betrieb gehen. Führen Sie eine Vorbestellung aus, um Ihren LOA zu erhalten, sobald dieser verfügbar ist. Weitere Informationen dazu erhalten Sie bei Ihrem Kundenbetreuer.`,
    terms: `Vertragsbedingungen`,
    'end-date-display': `Das Datum des Vertragsendes wird angezeigt, sobald der {productName} in Betrieb ist`,
    'change-term': `Vertragslaufzeit ändern`,
    'term-info': `Weitere Informationen zu Vertragsbedingungen für {productName}`,
    monthly: `Monat zu Monat`,
    'existing-lag': `bestehende ${process.env.VUE_APP_EN_PRODUCTNAME_LAG}`,
    'thing-enabled': `{thing} aktiviert`,
    'thing-disabled': `{thing} deaktiviert`,
    'address-family-label': `Adressfamilie`,
    'local-ip-label': `Lokale IP`,
    'peer-ip-label': `Peer-IP`,
    'local-asn-label': `Lokale ASN`,
    'bgp-state-label': `BGP-Status`,
    'bgp-peer-filter-label': `BGP-Peer-Filter`,
    'bgp-prefix-filter-import-label': `Präfixfilter importieren`,
    'bgp-prefix-filter-export-label': `Präfixfilter exportieren`,
    'bgp-confirm-delete': `Diese IP-Adresse wird bereits in BGP-Verbindungen oder NAT-IP-Adressen verwendet. Durch Löschen werden diese Konfigurationen entfernt. Fortfahren?`,
    'permit-list-label': `Zulassungsliste`,
    'deny-list-label': `Ablehnungsliste`,
    'new-list-label': `Neue Liste`,
    'duplicate-list-label': `Liste duplizieren`,
    'delete-list-label': `Liste löschen`,
    'delete-list-confirm': `Möchten Sie diese Liste wirklich löschen?`,
    'filter-advertise-default-label': `Bekanntgeben nach Standard`,
    'filter-advertise-exception-label': `Bekanntgeben nach Ausnahme`,
    'bfd-switch-label': `BFD verwenden`,
    'bfd-label': `BFD – Bidirectional Forwarding Detection`,
    'inbound-label': `Eingehend`,
    'outbound-label': `Ausgehend`,
    'med-label': `MED (Metrik) – Multi-Exit Discriminator`,
    'bgp-prefix-filter-label': `BGP-Präfixfilter`,
    'bgp-connection-filter-label': `BGP-Verbindungsfilter`,
    'no-connection-found': `Keine {thing}-Verbindungen gefunden`,
    'address-placeholder': `Geben Sie eine IP-Adresse (IPv4/IPv6) ein`,
    'network-address-placeholder': `Geben Sie eine {thing}-Adresse und CIDR ein`,
    'subnet-mask-range': `Subnetzmaskenbereich`,
    'select-address': `Adresse auswählen`,
    'prefix-list-label': `Präfixliste`,
    'mve-explanation': `Ein ${process.env.VUE_APP_EN_PRODUCTNAME_MVE_LONG} (${process.env.VUE_APP_EN_PRODUCTNAME_MVE}) ist eine BYOL-Instanz eines Partner-SD-WAN-Gateways, das von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} in der Nähe von Cloud-Hotspot-Standorten weltweit bereitgestellt und gehostet wird. Es ermöglicht den kontrollierten Zugang zu ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Netzwerkressourcen über das Internet. Es benötigt keinen physischen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, kann aber auf Wunsch mit einem solchen verbunden werden. Die gesamte Lizenzierung und Verwaltung erfolgt über die Partnerlösung.`,
    'ssh-key': `SSH-Schlüssel`,
    'ssh-key-rsa-help': `Öffentlicher 2048-Bit RSA SSH-Schlüssel`,
    'mve-appliance-licence': `Gerätelizenz`,
    'mve-activation-code': `Aktivierungscode`,
    'mve-address': `die Orchestrator Adresse`,
    // FQDN – Fully Qualified Domain Name (Vollqualifizierter Domänenname)
    'mve-address-placeholder': `IPv4/IPv6 oder FQDN`,
    'mve-select-vendor': `Anbieter/Produkt auswählen`,
    'mve-cloud-init-file-upload': `Cloud-Initialisierungsdatei`,
    'mve-cloud-init-file-upload-help': `Informationen zum Generieren einer Cloud-Initialisierungsdatei finden Sie in der {title}-Produktdokumentation.`,
    'mve-appliance-licence-help': `Gerätelizenz`,
    'key-valid': `Gültig`,
    'prefix-list-count': `{count} Liste | {count} Listen`,
    'mve-director-address': `Director-Adresse`,
    'mve-controller-address': `Controller-Adresse`,
    'mve-local-auth': `Lokale Auth`,
    'mve-local-auth-help': `Lokal-ID-Zeichenfolge oder E-Mail`,
    'mve-remote-auth': `Remote-Auth`,
    'mve-remote-auth-help': `Remote-ID-Zeichenfolge oder E-Mail`,
    'serial-number': `Seriennummer`,
    'prefix-list-save-confirm': `Möchten Sie die an der Präfixliste vorgenommenen Änderungen speichern?`,
    'how-to-create-port-text': `wie man einen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} erstellt`,
    'how-to-create-port-paragraph': `Anweisungen, {0}, finden Sie in der ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Dokumentation.`,
    'aruba-orchestrator-name': `Orchestrator-Kontoname`,
    'aruba-orchestrator-name-tt': `Der mit dieser Aruba Orchestrator-Instanz verbundene Kontoname`,
    'aruba-account-key': `Kontoschlüssel`,
    'aruba-account-key-tt': `Der mit dem obigen Kontonamen verknüpfte Kontoschlüssel`,
    'aruba-system-tag': `System-Tag`,
    'aruba-system-tag-tt': `Wird bei der Registrierung für das Aruba Cloud Portal und den Aruba Orchestrator verwendet`,
    'as-path-prepend-label': `AS-Pfadvoranstellung`,
    'unbundled-mve-size-info': `Diese Größe umfasst {cpuCount} vCPUs, {ram} GB RAM und {storage} GB Speicher.`,
    'mve-appliance-mode': `Appliance-Modus`,
    'mve-appliance-mode-tooltip': `<p>Der Appliance-Modus bestimmt, wie Sie Ihr Deployment konfigurieren und verwalten.</p>
      <ul>
      <li><strong>Autonomer Modus</strong>: über die Befehlszeilenschnittstelle (CLI)</li>
      <li><strong>SD-WAN-Modus</strong>: über vManage</li>
      </ul>
      <p>${process.env.VUE_APP_EN_COMPANYINFO_NAME} unterstützt keine Änderung der Appliance-Modi nach der Bereitstellung – dies kann zu einem Verlust der Konfiguration führen.</p>
      `,
    'mve-license-data': `Lizenz-Daten`,
    'mve-admin-password': `Administrator-Passwort`,
    'mve-admin-password-tooltip': `<p>Für den Zugriff auf das virtuelle Gerät müssen Sie ein Administrator-Passwort erstellen. Das Admin-Passwort muss enthalten:</p>
      <ul>
      <li>mindestens acht Zeichen</li>
      <li>mindestens einen Großbuchstaben</li>
      <li>mindestens einen Kleinbuchstaben</li>
      <li>mindestens eine Zahl</li>
      <li>mindestens ein Symbol</li>
      </ul>
      <p>Bitte bewahren Sie das Administrator-Passwort sicher auf, und geben Sie es nicht an Dritte weiter. Bei Bedarf kann das Administrator-Passwort bei der ersten Anmeldung am Gerät zurückgesetzt werden.</p>
      <p><strong>HINWEIS</strong>: ${process.env.VUE_APP_EN_COMPANYINFO_NAME} speichert keine Kundenpasswörter.</p>
      `,
    'mve-vnic-prompt-info': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} wird Sie auffordern, eine ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} für das erste Booten & zur Konfiguration zu bestellen, sobald die Basiskonfiguration Ihres ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} fertiggestellt ist.`,
    'mve-vendor-vnic-count': `{vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} haben eine vNIC. | {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} haben zwei vNICs (vNIC 0 & vNIC 1). | {vendor} ${process.env.VUE_APP_EN_PRODUCTNAME_MVES} haben mehrere vNICs.`,
    'mve-ip-transit-vxc-prompt-success': `Sie haben die Rechenkomponente Ihres {vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} erfolgreich konfiguriert, die CPU, RAM & Speicher umfasst.`,
    'mve-ip-transit-vxc-prompt-recommend': `Um die Konfiguration Ihres {vendorName} ${process.env.VUE_APP_EN_PRODUCTNAME_MVE} abzuschließen, empfehlen wir, eine ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}-Verbindung zu erstellen.`,
    'mve-ip-transit-vxc-prompt-docs': `Weitere Informationen finden Sie in unserem {link}.`,
    'mcr-prefix-filter-support': `Das ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Präfixfilter-Feature unterstützt eingehende und ausgehende präfixbasierte BGP-Route-Filter.`,
    'mcr-prefix-filter-configure': `Sie können Präfixlisten hinzufügen und konfigurieren, wenn Ihr Dienst konfiguriert wird.`,
    'cannot-edit': `kann nicht bearbeitet werden`,
    'move-connections': `Verbindungen verschieben`,
    'megaport-internet-restricted-location-reason': `Sie haben einen oder mehrere ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS}, die mit einem Produkt vom Typ {productType} verbunden sind. Produkte vom Typ {productType} und die zugehörigen ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} müssen in derselben Region konfiguriert sein.`,
    'megaport-internet-restricted-location-workaround': `Wenn Sie Ihr Produkt vom Typ {productType} in einer anderen Region konfigurieren möchten, müssen Sie zuerst die zugehörigen ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} löschen.`,
    'mve-manage-locally': `Lokal verwalten`,
    'mve-manage-locally-tooltip': `Gibt an, ob diese ${process.env.VUE_APP_EN_PRODUCTNAME_MVE}-Instanz das Firewall Management Center anruft oder nicht.`,
    'mve-fmc-ip-address': `FMC-IP-Adresse`,
    'mve-fmc-ip-address-tooltip': `Cisco Secure FMC-Netzwerkadresse. Dies kann einer der folgenden Werte sein: Hostname, IPv4- oder IPv6-Adresse.`,
    'mve-fmc-registration-key': `FMC-Registrierungsschlüssel`,
    'mve-nat-id': `NAT-ID`,
    'mve-nat-id-tooltip': `Geben Sie die NAT-ID an, wenn der Firewall Management Controller nicht direkt adressierbar ist.`,
    'mve-ion-key': `ION-Schlüssel`,
    'mve-secret-key': `Geheimer Schlüssel`,
    'unbundled-mve-size-info-no-storage': `Diese Größe umfasst {cpuCount} vCPUs und {ram} GB RAM.`,
    'service-key-name': `{name}-Dienstschlüssel`,
    'mve-cisco-vmanage': `vManage`,
    'mve-aviatrix-secure-edge': `Aviatrix Secure Edge`,
    'mve-select-version': `Version auswählen`,
    'mve-version': `Version {version}`,
    'mve-service-details': `Details zum {vendor}-Dienst`,
  },
  'port-term': {
    'funky-1': `Die Laufzeit für diesen Dienst konnte nicht automatisch aktualisiert werden.`,
    'funky-2': `Wenden Sie sich an Ihren ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Vertriebsmitarbeiter.`,
    title: `Vertragslaufzeit des {productType} einstellen`,
    choose: `Vertragslaufzeit des {productType} wählen`,
    'update-failure': `Aktualisieren der ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Laufzeit fehlgeschlagen`,
    'update-error': `Beim Versuch, die ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Laufzeit zu aktualisieren, ist ein Fehler aufgetreten`,
    'update-unavailable': `Die ausgewählte ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Laufzeit ist nicht verfügbar.`,
    'update-success': `{productType}-Laufzeiten aktualisiert`,
    'reterm-success': `- | Laufzeit für ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} '{portName}' auf 1 Monat festlegen | Laufzeit für ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} '{portName}' auf {count} Monate festlegen`,
    reterm: `Die neue Vertragslaufzeit für diesen Dienst (die Mindestlaufzeit) tritt sofort in Kraft, und der Rabatt für die neue Laufzeit wird auf die Rechnung des nächsten Kalendermonats angerechnet. Bei vorzeitiger Kündigung während der Mindestlaufzeit werden entsprechende Gebühren gemäß der zwischen uns getroffenen Vereinbarung fällig.`,
    'confirm-new-term': `Neue Vertragslaufzeit beginnen`,
    'no-change': `Keine Änderung`,
    'funky-3': `Wenden Sie sich an Ihren ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Partner.`,
    'no-minimum-term': `Keine Mindestlaufzeit`,
    'minimum-term-tooltip-ports': `Die Mindestlaufzeit beginnt nach Inbetriebnahme des Dienstes oder 14 Tage nach der Dienstkonfiguration`,
    'minimum-term-tooltip': `Die Mindestlaufzeit beginnt mit der Bestellung`,
    'reterm-immediate': `Die neue Vertragslaufzeit für diesen Dienst (die Mindestlaufzeit) gilt mit sofortiger Wirkung. Die Mindestlaufzeit beginnt ab {start} und endet am {end}. Bei vorzeitiger Kündigung während der Mindestlaufzeit werden entsprechende Gebühren gemäß der zwischen uns getroffenen Vereinbarung fällig.`,
    'option-price': `- | Monatlich: {cost} pro Monat | {count} Monate: {cost} pro Monat`,
  },
  dashboard: {
    navigation: `Lernen Sie die Grundlagen der Navigation auf dem ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL} kennen, und erfahren Sie, wie Sie Ihren ersten ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} erstellen können.`,
    'visit-marketplace': `Besuchen Sie ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'deep-dive': `Nutzen Sie technische Dokumente und Artikel, um mehr über unsere Dienste zu erfahren und darüber, wie Sie Netzwerke, Cloudkonnektivität und ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Lösungen einrichten können.`,
    'ql-overview': `Einführung in ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'ql-vxc': `Einrichten einer Verbindung mit einem ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}`,
    'ql-cloud': `Verbinden mit Cloud Service Providern`,
    'ql-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} – Virtuelles Routing für private Layer 3-Konnektivität`,
    'marketplace-marketing': `Der Onlinehub, über den unsere Service-Provider-Partner und Unternehmenskunden zusammenkommen und Dienste gemeinsam nutzen. Auf ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} können Sie neue Ressourcen entdecken und von neuen Kunden entdeckt werden.`,
    'marketplace-image': `Wählen Sie durch Klicken ein Bild für das ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}-Profil Ihrer Firma aus.`,
    'get-started': `Erste Schritte`,
    'quick-links': `Schnellzugriff:`,
    documentation: `${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Dokumentation`,
    video: `Video abspielen`,
    'video-error': `Video kann nicht abgespielt werden`,
    'video-error-message': `Die Wiedergabe von Videos scheint in Ihrem Browser blockiert zu sein. Dies wurde an das Entwicklungsteam zur Untersuchung weitergegeben. Wir bedauern die Unannehmlichkeiten.`,
  },
  menu: {
    dashboard: `Start`,
    company: `Firma`,
    'ix-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MEGA_IX}-Looking Glass`,
    'ix-telemetry': `${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Telemetrie`,
    'marketplace-profile': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Profil`,
    'mcr-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Looking Glass`,
    'more-events': `Weitere Ereignisse sind verfügbar:`,
    'company-profile': `Firmenprofil`,
    'billing-markets': `Abrechnungsmärkte`,
    'manage-users': `Benutzer verwalten`,
    tools: `Tools`,
    'api-documentation': `API-Dokumentation`,
    'api-key-generator': `API-Schlüssel-Generator`,
    'event-log': `Ereignisprotokoll`,
    'view-event-log': `Ereignisprotokoll anzeigen`,
    events: `Ereignisse`,
    'bug-report': `Fehlerbericht senden`,
    'user-profile': `Benutzerprofil`,
    'notification-settings': `Benachrichtigungseinstellungen`,
    logout: `Abmelden`,
    'partner-admin': `Partner-Admin`,
    'company-security-settings': `Sicherheitseinstellungen`,
    'technical-support-details': `Details zum technischen Support`,
    'account-contacts': `Kontokontakte`,
    entitlements: `Berechtigungen der Firma`,
    feedback: `Feedback senden`,
  },
  marketplace: {
    'pluralize-provider-type': `Anbietertypen | Anbietertyp | Anbietertypen`,
    'pluralize-locations': `Standorte | Standort | Standorte`,
    profile: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}-Profil`,
    'currently-private': `Ihr ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Profil ist derzeit privat. Das bedeutet, dass unser globales Netzwerk von Kunden Sie nicht finden und sich nicht mit Ihren Diensten verbinden kann.`,
    'no-profile': `Sie haben derzeit kein ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}-Profil eingerichtet.`,
    visible: `Ihr Profil wird auf ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} sichtbar sein.`,
    hidden: `Ihr Profil wird vor anderen auf ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} verborgen sein.`,
    services: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Dienste`,
    'create-vxc': `Neuen ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} zu diesem ${process.env.VUE_APP_EN_PRODUCTNAME_PORT} hinzufügen`,
    'display-name': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Anzeigename`,
    'contact-company': `Wenden Sie sich an {company}`,
    'email-sent-to': `Ihre E-Mail wurde an {recipient} gesendet.`,
    'not-ready-publishing': `Es sieht so aus, als wäre Ihr ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Profil nicht bereit für die Veröffentlichung. Klicken Sie auf "Profil erstellen", um Ihre Seite abzuschließen, sodass unser globales Netzwerk von Kunden Sie finden und sich mit Ihren Diensten verbinden kann.`,
    'easy-get-connected': `Verbindungen lassen sich ganz einfach herstellen. Klicken Sie auf "Profil bearbeiten", und geben Sie es für die Veröffentlichung auf ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} frei.`,
    'marketplace-intro': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} verbindet Ihre Unternehmen mit unserem globalen Netzwerk von Kunden. Diese Plattform ermöglicht Ihnen, neue Märkte zu erschließen, um bisher unerreichbare Einnahmequellen zu erkennen. Wenn Sie Ihr Profil vervollständigen und auf dem neuesten Stand halten, verbessern Sie Ihr Verkaufspotenzial und Ihre Markenpräsenz bei kaufwilligen Kunden erheblich.`,
    'duplicate-name-warning': `Das kann zu Irritationen führen, wenn Benutzer sich mit Ihren ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} verbinden möchten.<br/><br/>Möchten Sie wirklich speichern?`,
    sending: `Nachricht wird gesendet ...`,
    'general-info': `Allgemeine Informationen zum Dienst`,
    'send-copy': `Senden Sie mir eine Kopie der Nachricht`,
    message: `Geben Sie die Nachricht ein`,
    sent: `Nachricht gesendet`,
    'send-error': `Fehler beim Senden der Nachricht`,
    target: `Verbindungsziel`,
    'connection-from': `Verbindung von`,
    filter: `Nach Anbietertyp filtern`,
    'select-types': `Wählen Sie die erforderlichen Typen und Untertypen aus`,
    'clear-filter': `Filter löschen`,
    'hide-message': `Diese Nachricht ausblenden`,
    'hide-today': `Nur heute`,
    'hide-week': `Für eine Woche`,
    'hide-forever': `Für immer`,
    'edit-profile': `Profil bearbeiten`,
    'save-profile': `Profil speichern`,
    'create-profile': `Profil erstellen`,
    'sort-alpha': `A–Z`,
    'sort-location-count': `Standortzähler`,
    'grid-display': `Gridanzeige`,
    'list-display': `Listenanzeige`,
    'company-name-filter': `Firmennamenfilter`,
    'select-speeds': `Geschwindigkeit auswählen`,
    'select-provider-types': `Anbietertypen auswählen`,
    'select-countries': `Länder auswählen`,
    'loading-companies': `Laden von Firmen`,
    'all-services': `alle`,
    'selected-services': `ausgewählte Dienste`,
    'profile-message-hidden': `Profilmeldung ausgeblendet`,
    'hidden-day': `Diese Meldung wurde für einen Tag ausgeblendet.`,
    'hidden-week': `Diese Meldung wurde für eine Woche ausgeblendet.`,
    'hidden-message': `Diese Meldung wird nicht mehr auf diesem Bildschirm angezeigt, aber Sie können Ihr ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE_SHORT}-Profil unter der Menüoption „Company > ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} Profile“ (Firma > Marketplace-Profil) erstellen oder bearbeiten.`,
    'hide-profile': `Profilmeldung ausblenden`,
    'hidden-permanently': `Diese Meldung wurde dauerhaft ausgeblendet.`,
    'upload-logo': `Logo hochladen`,
    'change-logo': `Logo ändern`,
    social: `Social Media`,
    headquarters: `Hauptsitz`,
    'address-1': `Adresse 1`,
    'address-2': `Adresse 2`,
    city: `Ort`,
    postcode: `PLZ`,
    'point-click': `Zeigen, Klicken, Verbinden.`,
    'no-edit-permission': `Sie haben keine Berechtigung zum Bearbeiten dieses Profils. Wenden Sie sich an einen Firmen-Admin, um die Bearbeitung vorzunehmen.`,
    'loading-editor': `Profil-Editor wird geladen ...`,
    'no-create-permission': `Sie haben keine Berechtigung zum Erstellen eines Profils. Wenden Sie sich an einen Firmen-Admin, um das Profil zu erstellen.`,
    'profile-url': `Profil verfügbar unter URL:`,
    contact: `Kontakt`,
    visibility: `Profilsichtbarkeit`,
    'company-bio': `Firmen-Biographie`,
    'bio-length': `Firmen-Biographie (200 Wörter)`,
    'contact-email': `Kontakt-E-Mail`,
    'recently-viewed': `Zuletzt angezeigte Anbieter`,
    'similar-providers': `Ähnliche Dienstanbieter`,
    'company-list': `Firmenliste`,
    'duplicated-names': `Die folgenden Dienstanzeigenamen wurden dupliziert:`,
    'duplicate-name': `Anzeigenamen duplizieren`,
    description: `Beschreibung des Dienstes (200 Wörter)`,
    'service-contact-email': `Kontakt-E-Mail des Dienstes`,
    'service-types': `Diensttyp`,
    'company-profile': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Firmenprofil für Firma {company}`,
    'live-time': `Aktiv seit {time}`,
    'company-at-location': `{company} am Standort {location}`,
    state: `Bundesland/Kanton`,
    country: `Land`,
  },
  'mcr-looking-glass': {
    'no-services-message': `Sie haben keine ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG}-Dienste (${process.env.VUE_APP_EN_PRODUCTNAME_MCR}) konfiguriert.`,
    'no-bgp-sessions': `Es sind keine BGP-Sitzungen für diesen ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} vorhanden`,
    'no-services': `Keine Dienste gefunden`,
    selection: `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Auswahl`,
    'routes-table': `Routing-Tabelle`,
    'bgp-table': `BGP-Tabelle`,
    'all-reachable': `Alle erreichbaren Netze in der ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Routing-Tabelle.`,
    'all-routes': `Alle Routen`,
    'routes-by-ip': `Routen nach IP`,
    find: `Routen suchen`,
    loading: `Laden von Routen`,
    'enter-ip': `Geben Sie eine IP- oder Netzwerk-Adresse für die Routensuche ein`,
    'no-routes': `Es gibt keine Routen für diese IP-Adresse`,
    'no-bgp': `Es gibt keine BGP-Verbindungen zu dieser Schnittstelle`,
    neighbour: `Nachbarrouten`,
    'bgp-sessions': `BGP-Sitzungen`,
    'bgp-sessions-tooltip': `Alle BGP-Sitzungen, die auf den ${process.env.VUE_APP_EN_PRODUCTNAME_VXCS} des ausgewählten ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} konfiguriert sind.`,
    'filter-sessions': `Geben Sie eine Zeichenfolge zum Filtern der Sitzungen ein`,
    'toggle-details': `Zusätzliche Details anzeigen`,
    'total-routes': `Routen gesamt`,
    'filter-routes': `Text zum Filtern der Routen eingeben`,
    advertised: `Bekannt gegeben`,
    received: `Empfangen`,
    'sort-by-thing': `Nach {thing} sortieren`,
    'clear-filter-routes': `Filter-Routen löschen`,
    'contact-support': `Wenden Sie sich an unser Support-Team, um zu erörtern, wie Sie von ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}s profitieren können.`,
    'service-could-not-be-loaded': `Dienst konnte nicht geladen werden ({errorStatus})`,
    'service-loading-timeout': `Es scheint, dass es ein Problem beim Laden dieser Informationen gab. Wir entschuldigen uns für die Unannehmlichkeiten. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an unser Support-Team unter ${process.env.VUE_APP_EN_COMPANYINFO_SUPPORT_EMAIL}`,
  },
  'ix-flows': {
    'no-ix-found': `${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienst nicht gefunden`,
    'no-ix-message': `Sie haben keine ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienste. Wenden Sie sich an Ihren Vertriebsmitarbeiter, wenn Sie an ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Diensten interessiert sind.`,
    'date-select': `Datumsauswahl`,
    'select-peer': `Peer auswählen`,
    'no-data': `Zu diesem Peer sind keine Daten verfügbar`,
    'flow-type': `Übermittlungstyp`,
    'loading-peers': `Laden von Peers`,
    'sort-by': `Sortiert nach`,
    'filter-by-name': `Nach Name filtern`,
    'total-peers': `Peers gesamt: {count}`,
    'top-5': `Top 5 Peers ({direction})`,
    'volume-in': `Volumen (eing.)`,
    'volume-out': `Volumen (ausg.)`,
    'load-error': `Es gibt ein Problem beim Laden dieser Daten. Versuchen Sie es später noch einmal.`,
  },
  checkout: {
    'minimum-term-months': `Keine Mindestlaufzeit | Monat zu Monat | Mindestlaufzeit: {count} Monate`,
    'unknown-validation-error': `Unbekannter Validierungsfehler: '{message}'`,
    'hide-promo': `Ausblenden`,
    'add-promo': `Code hinzufügen`,
    order: `Dienste bestellen`,
    'set-up-company': `Richten Sie Ihre Firma ein`,
    'register-markets': `Märkte registrieren`,
    'order-now': `Jetzt bestellen`,
    'need-setup': `Bevor Sie Dienste bestellen können, müssen Sie die Kontoeinrichtung vervollständigen.`,
    'need-company': `Sie müssen Ihre Firmeninformationen einrichten.`,
    'need-company-permissions': `Ihre Firmeninformationen sind nicht eingerichtet, aber Sie haben keine ausreichenden Berechtigungen. Wenden Sie sich an den Administrator Ihrer Firma.`,
    'need-markets': `Sie müssen die erforderlichen Firmenmärkte aktivieren.`,
    'need-markets-permissions': `Ihre Firmenmärkte sind nicht aktiviert, aber Sie haben keine ausreichenden Berechtigungen. Wenden Sie sich an den Administrator Ihrer Firma.`,
    validating: `Ihre Bestellung wird validiert`,
    deploying: `Bereitstellung wird ausgeführt ...`,
    'no-term': `Keine Laufzeit`,
    promo: `Promo`,
    'add-promo-code': `Promo-Code hinzufügen`,
    'promo-code': `Promo-Code`,
    'checking-promo': `Promo-Code wird geprüft`,
    'promo-validated': `Code validiert`,
    'no-deploy': `Hinweis: Sie können zwar Warenkörbe erstellen und speichern, aber Ihre Kontoberechtigungen schränken Sie beim Bestellen von Diensten ein.`,
    'invalid-code': `Ungültiger Code`,
    'code-not-confirmed': `Promo-Code wurde nicht bestätigt`,
    'save-failed': `Speichern der konfigurierten Dienste fehlgeschlagen`,
    'error-deploying': `Fehler beim Bereitstellen des Dienstes.`,
    'unknown-error': `Bei der Bereitstellung ist ein unbekannter Fehler aufgetreten`,
    deployed: `Der Dienst wurde erfolgreich bereitgestellt.`,
    success: `Dienste unter Bestellnummer {orderId} erfolgreich bereitgestellt:`,
    'null-code': `Geben Sie einen Promo-Code ein`,
    'capture-tech-support-details': `Details zum technischen Support erfassen`,
    'set-up-tech-support-details': `Details zum technischen Support`,
    'need-tech-support-details': `Bevor eine Bestellung aufgegeben werden kann, werden die Kontaktdaten des technischen Supports benötigt.`,
    'need-tech-support-details-by-admin': `Die Kontaktdaten für den technischen Support müssen von einem Administrator des Unternehmens ausgefüllt werden.`,
    'missing-tech-support-details': `Fehlende Kontaktdaten für den technischen Support`,
    'deleting-configuration': `Gespeicherte Konfigurationen werden gelöscht ...`,
    'refreshing-services': `Dienste werden aktualisiert ...`,
  },
  sidebar: {
    'enable-market': `Markt {country} freigeben`,
    'configured-services': `Konfigurierte Dienste`,
    'save-configured-services': `Konfigurierte Dienste speichern`,
    'save-configured-services-later': `Speichern Sie diese konfigurierten Dienste zur späteren Verwendung.`,
    'saved-configurations-alert': `Gespeicherte Konfigurationen gelten für die gesamte Firma und sind für jeden in der Organisation sichtbar.`,
    'existing-configuration-warning': `Eine Konfiguration mit diesem Namen ist bereits vorhanden. Durch Speichern unter demselben Namen wird die vorher gespeicherte Konfiguration überschrieben.`,
    'clear-configured-services': `Alle konfigurierten Dienste löschen`,
    'save-configured-name': `Geben Sie einen Namen für die Konfiguration ein`,
    'saved-configurations': `Gespeicherte Konfigurationen`,
    'config-saved-title': `Konfiguration von {name} wurde gespeichert`,
    'config-saved-message': `Konfiguration von {name} ({serviceOrder}) erfolgreich auf Server gespeichert.`,
    'config-save-error-title': `Fehler beim Speichern der Konfiguration von {name}`,
    'config-deleted-title': `Konfiguration gelöscht`,
    'config-delete-error-title': `Konfiguration von {name} konnte nicht gelöscht werden.`,
  },
  'billing-markets': {
    'vat-characters': `Geben Sie eine {length} Zeichen lange USt-IdNr. ein`,
    'purchase-order-market': `Die Rechnungsbestellnummer wird im Rechnungskopf für den Markt {country} angezeigt, wenn die Rechnung erzeugt wird.`,
    'due-date-date': `Fälligkeitsdatum: {date}`,
    'remove-card-ending': `Die mit {numbers} endende Karte entfernen`,
    'edit-card-ending': `Optionen für die mit {numbers} endende Karte bearbeiten`,
    'pay-card-ending': `Nehmen Sie mit der mit {numbers} endenden, registrierten Karte eine Zahlung vor.`,
    'remove-card-confirmation': `Möchten Sie die mit {numbers} endende Kreditkarte aus dem ${process.env.VUE_APP_EN_COMPANYINFO_NAME} {marketName}-Abrechnungsmarkt entfernen?`,
    'dont-accept-currency': `Wir akzeptieren in diesem Markt derzeit keine {currency}-Zahlungen via Kreditkarte. Weitere Zahlungsmöglichkeiten können Sie der Rechnung entnehmen.`,
    title: `Firmenabrechnungsmärkte`,
    enabled: `Aktivierte Märkte`,
    none: `Keine Märkte aktiviert`,
    options: `Optionen`,
    'view-invoices': `Rechnungen anzeigen`,
    payment: `Zahlung vornehmen`,
    edit: `Markt bearbeiten`,
    view: `Markt anzeigen`,
    'download-invoices': `Alle Rechnungen herunterladen`,
    'download-json': `Als JSON herunterladen`,
    'download-csv': `CSV herunterladen`,
    'enable-bm': `Aktivieren Sie einen Abrechnungsmarkt`,
    'none-available': `Keine Märkte verfügbar`,
    enable: `Aktivieren`,
    market: `Markt`,
    invoices: `Rechnungen`,
    'next-invoice': `Nächste Rechnung`,
    disclaimer: `(keine Geschwindigkeitsänderungen vorausgesetzt, ohne MwSt.)`,
    balance: `Kontostand`,
    'inc-tax': `(inkl. Steuern)`,
    'download-statement': `Auszug herunterladen`,
    'no-bills': `Für diesen Markt wurden noch keine Dienste abgerechnet.`,
    'invoice-no': `Rechnungsnr.`,
    'issue-date': `Ausstellungsdatum`,
    total: `Gesamt`,
    payments: `Zahlungen`,
    'no-stripe': `Stripe-Zahlungen sind derzeit nicht möglich`,
    'cc-history': `Zahlungshistorie der Kreditkarte`,
    'no-payments': `Keine Zahlungen erfasst`,
    description: `Beschreibung`,
    'payment-date': `Zahlungsdatum`,
    'unavailable-market': `Zahlungen mit Kreditkarte werden für diesen Markt nicht akzeptiert. Weitere Zahlungsmöglichkeiten können Sie der Rechnung entnehmen`,
    'billing-currency': `Abrechnungswährung`,
    'currency-fixed': `Die Abrechnungswährung kann nicht geändert werden`,
    'no-currency-change': `Die Abrechnungswährung kann nach der Einrichtung des Marktes nicht mehr geändert werden`,
    language: `Rechnungssprache`,
    'language-future-invoices': `Hinweis: Änderungen an der Rechnungssprache werden erst für künftige Rechnungen wirksam.`,
    'billing-name': `Name des Abrechnungskontakts`,
    'billing-phone': `Telefon des Abrechnungskontakts`,
    'phone-format': `Internationale Telefonnummer. Beispiel: +49 30 1234567`,
    'billing-email': `E-Mail des Abrechnungskontakts`,
    'email-format': `Entweder eine einzelne E-Mail-Adresse oder eine durch Kommata getrennte Liste von E-Mail-Adressen`,
    'billing-address': `Abrechnungsadresse`,
    'address-2': `Adresszeile 2`,
    city: `Ort`,
    province: `Bundesland/`,
    state: `Kanton`,
    postcode: `PLZ`,
    'select-or-type': `Filteroptionen auswählen oder eingeben`,
    'company-registration': `Firmenregistrierungsnr.`,
    'company-tooltip': `Registrierungsnummer der Firma, die mit der einkaufenden Einheit verbunden ist.`,
    'vat-registration': `Sind Sie für die Umsatzsteuer registriert?`,
    'vat-id': `USt-IdNr.`,
    'vat-number': `Registrierte USt-IdNr.`,
    po: `Rechnungsbestellnummer`,
    currency: `Marktwährung`,
    'company-identifier': `Firmen-ID`,
    'contact-name': `Kontaktname`,
    'phone-number': `Telefonnummer`,
    address: `Adresse`,
    'due-unavailable': `Nicht verfügbar`,
    vat: `MwSt.`,
    'register-card': `Karte registrieren`,
    'register-card-status': `Karteneinrichtungsstatus: {status}`,
    'register-card-edit': `Registrierte Karte bearbeiten`,
    'register-card-fail': `Kartenregistrierung fehlgeschlagen`,
    'register-card-success': `Karte wurde erfolgreich registriert.`,
    'register-card-fail-message': `Das Registrieren Ihrer Karte ist fehlgeschlagen.`,
    'register-card-message': `Registrieren Sie eine Karte für einen ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Abrechnungsmarkt, um automatische Zahlungen (nach Rechnungsstellung) oder manuelle Einmalzahlungen auszuführen.`,
    'registering-card': `Karte wird registriert ...`,
    'remove-card': `Karte entfernen`,
    'removing-card': `Karte wird entfernt ...`,
    'remove-card-fail': `Fehler beim Entfernen der Karte`,
    'remove-card-fail-message': `Entfernen der Karte fehlgeschlagen: {error}`,
    'update-card': `Karte aktualisieren`,
    'update-card-success': `Karte wurde erfolgreich aktualisiert.`,
    'update-card-fail': `Fehler beim Aktualisieren der Karte.`,
    'update-card-fail-message': `Aktualisieren der Karte fehlgeschlagen: {error}`,
    'remove-card-success': `Karte wurde erfolgreich entfernt.`,
    'save-card-fail': `Kartenspeicherung fehlgeschlagen`,
    'save-card-fail-message': `Karteninformationen konnten nicht gespeichert werden: {error}`,
    'payment-once': `Einmalzahlung ausführen`,
    'payment-once-make': `Führen Sie eine Einmalzahlung für Ihr Konto aus.`,
    'payment-processing': `Zahlung wird ausgeführt`,
    'payment-confirm-fail': `Bestätigen der Zahlungseinrichtung fehlgeschlagen`,
    'payment-error': `Unerwarteter Zahlungsfehler`,
    'payment-success': `Zahlung erfolgreich.`,
    'payment-success-message': `{amount} {currency} wurden im Markt [{market}] gezahlt`,
    'card-number': `Kartennummer`,
    'card-expiry': `Gültigkeitsdauer`,
    'card-cvc': `Kreditkartencode (CVC)`,
    'auto-payments-on': `Automatische Zahlungen ein`,
    'auto-payments-off': `Automatische Zahlungen aus`,
    'payment-amount': `Zahlungsbetrag`,
    'payment-description': `Zahlungsbeschreibung`,
    'payment-failed': `Zahlung fehlgeschlagen`,
    'payment-create-failed': `Erstellen der Zahlungsmethode fehlgeschlagen`,
    'payment-create-failed-message': `Stripe-Zahlungsmethode konnte nicht erstellt werden: {error}`,
    'payment-attempt-failed': `Zahlungsversuch fehlgeschlagen: {error}`,
    'payment-market-fail': `In Markt [{market}] konnte keine Zahlung ausgeführt werden. {error}`,
    'processing-fees': `Bearbeitungsgebühren für Kreditkarten werden auf den Zahlungsbetrag aufgeschlagen und in Ihrer nächsten Rechnung ausgewiesen. ${process.env.VUE_APP_EN_COMPANYINFO_NAME} leitet diese Gebühren direkt von unserem Dienstanbieter weiter.`,
    'account-balance': `Ihre aktueller Kontostand ist | (inkl. Mwst.).`,
    'enable-markets': `Märkte aktivieren`,
    'services-no-market': `Sie haben Dienste für Märkte konfiguriert, die noch nicht aktiviert wurden.`,
    'enable-markets-admin': `Die folgenden Abrechnungsmärkte müssen von einem Firmen-Admin aktiviert werden:`,
    'no-payment-method': `Keine Daten zu Zahlungsmethoden erhalten.`,
    'stripe-error': `Stripe-Fehler`,
    'update-success': `Unterstützung für Markt „{country}“ wurde erfolgreich aktualisiert.`,
    supply: `Marktangebot`,
    'create-success': `Unterstützung für Markt „{country}“ wurde erfolgreich erstellt.`,
    'payment-docs-html': `Weitere Informationen zu den Gebühren für Kreditkartentransaktionen erhalten Sie von unserem Dienstanbieter Stripe, {link}.`,
    'cc-autopay': `Automatische Zahlungen werden am 20. eines jeden Monats für den ausstehenden Saldo auf Ihrem Konto bearbeitet.`,
    'cc-support-html': `Wenn Sie Fragen zu Kreditkartenzahlungen haben, wenden Sie sich an {email}.`,
    download: `Herunterladen`,
    'due-date': `Fälligkeitsdatum`,
    'invoice-date': `Rechnungsdatum`,
    'invoice-amount': `Rechnungsbetrag`,
    'open-balance': `Offener Saldo`,
    'date-range': `Datumsbereich`,
    'download-pdf': `PDF herunterladen`,
    'download-invoices-info': `Bitte beachten Sie: Die Funktion „Download All Invoices“ (Alle Rechnungen herunterladen) wird nicht mehr unterstützt. Bitte laden Sie einzelne Rechnungen herunter, indem Sie oben auf das Symbol „View Invoices“ (Rechnungen anzeigen) klicken und einen Datumsbereich auswählen.`,
    'billing-address-entry-rules': `Bei dieser Adresse sollte es sich um den Geschäftssitz handeln, an dem die wichtigsten Entscheidungsträger im Hinblick auf die Beschaffung und Nutzung von ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Diensten ansässig sind.`,
    UK_MOBQ: `Nutzen Sie unsere Dienstleistungen im Vereinigten Königreich?`,
    UK_MOBQ_TOOLTIP: `Bitte bestätigen Sie, ob Sie Mitarbeiter im Vereinigten Königreich haben, die diese Dienste nutzen`,
    AU_MOBQ: `Sind Sie für die australische GST registriert?`,
    AU_MOBQ_TOOLTIP: `Prüfen Sie, ob die juristische Person oder die Niederlassung, die den Dienst in Anspruch nimmt (dies kann eine Tochtergesellschaft sein, die Rechnungsempfänger von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ist), für die australische Mehrwertsteuer registriert ist.`,
    SG_MOBQ: `Sind Sie für die GST in Singapur registriert?`,
    SG_MOBQ_TOOLTIP: `Prüfen Sie, ob die juristische Person oder die Niederlassung, die den Dienst in Anspruch nimmt (dies kann eine Tochtergesellschaft sein, die Rechnungsempfänger von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} ist), für die Mehrwertsteuer in Singapur registriert ist.`,
    'company-type-mexico': `Firmentyp`,
    'company-type-mexico-tooltip': `Art des Steuerpflichtigen (natürliche oder juristische Person oder ausländische Einrichtung)`,
    'tax-regime-code-mexico': `Steuersystem`,
    'tax-regime-code-mexico-tooltip': `Steuersystem – Wählen Sie den aktuellen Code des Steuersystems, der bei der mexikanischen Steuerbehörde (SAT) registriert ist.`,
    'sat-purpose-mexico': `Steuerlicher Zweck`,
    'sat-purpose-mexico-tooltip': `Steuerlicher Zweck`,
    'rfc-mexico': `RFC-Nummer`,
    'rfc-mexico-tooltip': `Zuweisung der aktuellen Nummer des Bundessteuerregisters – wie von der mexikanischen Steuerverwaltung (SAT) vergeben`,
    'fusf-interstate-traffic-certification': `Federal USF Interstate Traffic Certification *`,
    'fusf-recommended-text': `* Es wird dringend empfohlen, den Tooltip mit weiteren Informationen zu lesen`,
    'fusf-tooltip-content': `Bei Datenübertragungsprodukten* mit Dienstendpunkten innerhalb desselben Bundesstaates ist Megaport durch die Federal Communications Commission (FCC) verpflichtet, davon auszugehen, dass der Datenverkehr des Kunden letztlich die Grenzen des Bundesstaates als Teil des weiteren Netzwerks des Kunden überquert, es sei denn, der Kunde bestätigt das Gegenteil.<br/><br/>
      Durch Aktivieren des Kontrollkästchens bestätigt der Kunde, dass <strong>mehr als 10 %</strong> des Datenverkehrs in Bezug auf alle derartigen von Megaport erworbenen Produkte in andere Bundesstaaten übertragen wird. Ist dies der Fall, unterliegen die Dienste den Gebühren des Federal Universal Service Fund (FUSF).<br/><br/>
      Indem der Kunde das Kontrollkästchen nicht aktiviert, bestätigt er, dass <strong>10 % oder weniger</strong> des über diese Produkte übertragenen Datenverkehrs die Grenzen des Bundesstaates überqueren, was bedeutet, dass kein FUSF angewendet wird.<br/><br/>
      Ein elektronischer Datensatz mit Name und Titel des Kontonutzers, der die oben genannte Zertifizierung vorgelegt hat, wird für die Verwaltung in Megaport generiert und kann zu Prüfzwecken verwendet werden.<br /><br />
      *Die Datenübertragungsprodukte von Megaport umfassen VXC, Megaport Internet und Internet Exchange`,
  },
  users: {
    'configure-destination-notifications': `{destination}-Benachrichtigungen konfigurieren`,
    'editing-user-name-you': `Benutzer {firstName} {lastName} {you} wird bearbeitet`,
    'user-not-found-redirect': `{userId} wurde nicht gefunden. Leitet Sie zur Seite "Benutzer verwalten" um.`,
    'position-not-found': `Dieser Benutzer hat eine Rolle ({position}), die vom System nicht mehr unterstützt wird. Wählen Sie eine der verfügbaren Optionen aus.`,
    'invalid-email': `{email} ist keine gültige E-Mail-Adresse`,
    deactivated: `Deaktivierte Benutzer anzeigen`,
    notifications: `Benachrichtigungen`,
    destinations: `Verfügbare Ziele`,
    'no-destinations': `Keine Ziele verfügbar`,
    'detailed-notifications': `Detaillierte Benachrichtigungen anzeigen`,
    filter: `Filter`,
    'no-match': `Keine übereinstimmenden Benutzer`,
    active: `Benutzerkonto ist aktiv`,
    disabled: `Benutzerkonto ist deaktiviert`,
    you: `(Sie)`,
    new: `Neuer Benutzer`,
    'logged-in-as-user': `Sie sind derzeit als dieser Benutzer angemeldet. Sie können Ihre Benutzerdetails auf der Seite "Benutzerprofil" ändern.`,
    state: `Benutzerstatus`,
    'technical-admin': `Technischer Admin`,
    finance: `Finanzen`,
    'financial-contact': `Finanzkontakt`,
    role: `Rolle des Benutzers`,
    'role-not-found': `Rolle nicht gefunden`,
    'user-not-found': `Benutzer nicht gefunden`,
    'missing-info': `Fehlende Informationen`,
    'change-email': `E-Mail ändern`,
    'changing-email': `E-Mail wird geändert ...`,
    'new-email': `Neue E-Mail`,
    'email-preferences': `E-Mail-Einstellungen`,
    'saving-email-preferences': `E-Mail-Einstellungen werden gespeichert ...`,
    'receive-newsletter': `${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Newsletter erhalten`,
    'receive-promotional-material': `Werbematerial von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} erhalten`,
    'change-password': `Kennwort ändern`,
    'changing-password': `Kennwort wird geändert ...`,
    'old-password': `Altes Kennwort`,
    'new-password': `Neues Kennwort`,
    'retype-new-password': `Neues Kennwort erneut eingeben`,
    'saving-user-profile': `Speichern des Benutzerprofils`,
    'account-password': `Konto-Kennwort`,
    username: `Benutzername`,
    'loading-qr-code': `Laden des QR-Codes`,
    'account-activity': `Kontoaktivität`,
    'account-activity-none': `Keine Aktivitätsdaten`,
    'account-activity-loading': `Aktivitätsdaten werden geladen ...`,
    'activity-data-load-fail': `Laden der Aktivitätsdaten fehlgeschlagen`,
    'save-failed': `Speichern fehlgeschlagen`,
    'save-user-profile-failed': `Speichern des Benutzerprofils fehlgeschlagen`,
    'save-email-failed': `Ändern der E-Mail fehlgeschlagen`,
    'save-email-preference-failed': `Speichern der E-Mail-Einstellungen fehlgeschlagen`,
    'profile-saved': `Profil erfolgreich aktualisiert.`,
    'email-change-success': `E-Mail-Einstellungen wurden geändert.`,
    'save-password-failed': `Speichern des Kennworts fehlgeschlagen`,
    'save-password-failed-message': `Speichern des aktualisierten Kennworts fehlgeschlagen`,
    'saving-user-profile-error': `Fehler beim Aktualisieren des Profils`,
    profile: `Profil`,
    'user-name': `Benutzer {firstName} {lastName}`,
    'verification-email': `Eine Verifizierungs-E-Mail wurde an Ihre neue Adresse gesendet.`,
    'verification-message': `Folgen Sie den Eingabeaufforderungen in der E-Mail.`,
    'user-role': `Benutzerrolle`,
    'viewing-user-name-you': `Benutzer {firstName} {lastName} {you} wird angezeigt`,
    'cant-edit-invite-pending': `Dieses Benutzerprofil kann erst bearbeitet werden, wenn der Benutzer die Einladung angenommen hat. Löschen Sie den Benutzer, und laden Sie ihn erneut ein, wenn Änderungen erforderlich sind.`,
    'deleting-user': `Benutzer wird gelöscht ...`,
    'disable-mfa': `MFA deaktivieren`,
    'enable-mfa': `MFA aktivieren`,
    'reset-mfa': `MFA zurücksetzen`,
    'disable-mfa-dialog': `Multi-Faktor-Authentifizierung deaktivieren`,
    'enable-mfa-dialog': `Multi-Faktor-Authentifizierung aktivieren`,
    reset: `Zurücksetzen`,
    'mfa-long': `Multi-Faktor-Authentifizierung`,
    'reset-mfa-dialog': `Multi-Faktor-Authentifizierung zurücksetzen`,
    'reset-mfa-success': `Multi-Faktor-Authentifizierung erfolgreich zurückgesetzt`,
    'reset-mfa-failed': `Fehler beim Zurücksetzen der Multi-Faktor-Authentifizierung`,
    'reset-mfa-dialog-desc': `Sie sind dabei, das Geheimnis Ihrer Multi-Faktor-Authentifizierung zurückzusetzen. Sobald Sie den QR-Code gescannt und bestätigt haben, werden die neuen Geheimnisse aktiv und alle alten Geheimnisse auf Geräten werden ungültig.`,
    'mfa-enforced-disable-tooltip': `Der Administrator Ihres Unternehmens hat eine globale Richtlinie zur Durchsetzung von MFA festgelegt. Sie können die MFA nicht deaktivieren, solange diese Funktion aktiviert ist.`,
    'position-header': `Rolle`,
    'mfa-header': `MFA`,
    'mfa-tooltip-not-set': `Benutzer hat die Multi-Faktor-Authentifizierung nicht eingerichtet`,
    'mfa-tooltip-set': `Benutzer hat die Multi-Faktor-Authentifizierung eingerichtet`,
    set: `Eingerichtet`,
    'not-set': `Nicht eingerichtet`,
    enforced: `Erzwungen`,
    optional: `Optional`,
    'partner-only': `Nur Partner`,
    'partner-only-tooltip': `Konfigurieren Sie E-Mail-Benachrichtigungen, die nur mit Ihrem Partnerkonto verknüpft sind`,
    'partner-and-managed': `Partner und Verwaltet`,
    'partner-and-managed-tooltip': `Konfigurieren Sie E-Mail-Benachrichtigungen, die sowohl mit Ihren Partnerkonten als auch mit allen verwalteten Konten verbunden sind`,
    'company-admin-reset-mfa-confirm-title': `Möchten Sie die Multi-Faktor-Authentifizierung wirklich zurücksetzen?`,
    'company-admin-reset-mfa-confirm-message': `Stellen Sie sicher, dass es sich bei {username}, für den Sie die Rücksetzung beantragen, um eine tatsächliche Person handelt und nicht um ein Konto, das für Machine-to-Machine-Integrationen verwendet wird. Nachdem MFA zurückgesetzt wurde, wird der Benutzer aufgefordert, seine Anmeldedaten bei der Anmeldung zurückzusetzen.`,
    'company-admin-reset-help-text': `Helfen Sie diesem Benutzer, sein Konto zurückzusetzen. In diesem Fall muss der Benutzer ein neues Gerät anmelden.`,
    'mfa-alt-tag': `Verwenden Sie Ihre Kamera zum Scannen des QR-Codes`,
    'mfa-app-suggestion': `Wir empfehlen Google Authenticator als Authentifizierungs-App.`,
    'mfa-token': `Generiertes Einmaltoken eingeben`,
    'mfa-loading-failed': `Laden des QR-Codes fehlgeschlagen`,
    'mfa-disabled': `Multi-Faktor-Authentifizierung deaktiviert`,
    'enable-mfa-success': `Multi-Faktor-Authentifizierung erfolgreich aktiviert.`,
    'disable-mfa-fail': `Fehler beim Deaktivieren der Multi-Faktor-Authentifizierung`,
    'view-user-actions': `Alle Benutzeraktionen anzeigen`,
    'mfa-warning-1': `Stellen Sie sicher, dass das in Ihrer Multi-Faktor-Authentifizierungs-App registrierte Konto entfernt wurde.`,
    'mfa-warning-2': `Sie werden beim Deaktivieren abgemeldet.`,
    'loading-users': `Firmenbenutzer laden...`,
    'sso-compatibility': `SSO-Kompatibilität`,
    'sso-enabled-tooltip': `Die E-Mail-Domäne des Benutzers ist mit SSO verknüpft und nutzt SSO standardmäßig für Anmeldungen.`,
    'sso-enforced-no-access-tooltip': `SSO wird erzwungen, und der Benutzer ist NICHT SSO-kompatibel. Zugriff untersagt`,
    'sso-optional-can-login-tooltip': `Die E-Mail-Domäne des Benutzers ist NICHT SSO-kompatibel, allerdings kann sich der Benutzer über andere Methoden anmelden.`,
    'active-api-keys-found': `Aktive API-Schlüssel gefunden`,
    'active-api-keys-inactive-if-continue': `Es wurden aktive API-Schlüssel für diesen Benutzer gefunden; diese werden inaktiv, wenn Sie fortfahren. Alle API-Schlüssel können mit Tools - API Key Generator (Werkzeuge - API-Schlüssel-Generator) überprüft werden.`,
    'active-api-keys-input-label': `Um fortzufahren, geben Sie Inactivate, ein und klicken Sie dann auf Continue (Fortfahren), oder klicken Sie auf Cancel (Abbrechen), um den Vorgang abzubrechen.`,
    'active-api-keys-error': `Geben Sie Inactivate ein, um fortzufahren.`,
    'active-api-keys-review': `Es wird empfohlen zu überprüfen, ob die von diesem Benutzer generierten API-Schlüssel in einer systemkritischen Integration zwischen Ihrem Unternehmen und ${process.env.VUE_APP_EN_COMPANYINFO_NAME} verwendet werden.`,
  },
  'api-keys': {
    'description-intro': `Für den direkten Zugriff auf die ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-API benötigen Sie einen API-Schlüssel.`,
    'description-html': `API-Schlüssel sind nur in der Umgebung gültig, in der sie generiert wurden. Sie sind derzeit in der {environment}-Umgebung angemeldet, daher funktionieren alle hier erstellten und aufgeführten Schlüssel nur in der {environment}-Umgebung. Wenn Sie Schlüssel für den Zugriff auf eine andere Umgebung benötigen, melden Sie sich beim ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} in dieser Umgebung an, und generieren Sie dann dort einen Schlüssel.`,
    'learn-more-html': `Weitere Informationen zu Umgebungen finden Sie in der {link}.`,
    forbidden: `Sie können API-Schlüssel nur dann erstellen oder verwalten, wenn Sie Administrator in einem Firmen-Setup mit einem aktivierten Abrechnungsmarkt sind.`,
    'key-description': `Der API-Schlüssel ist ein eindeutiger Bezeichner, der Anforderungen für Nutzungs- und Abrechnungszwecke authentifiziert. Verwenden Sie den Schlüssel, um ein Token für die Authentifizierung von API-Anforderungen zu generieren.`,
    'key-generated-message': `API-Schlüssel {key} wurde generiert`,
    'key-updated-message': `API-Schlüssel {key} wurde aktualisiert`,
    'key-deleted-message': `API-Schlüssel {key} wurde gelöscht`,
    'key-regenerated-message': `Neues Secret für API-Schlüssel {key} wurde generiert`,
    'key-dialog-description': `Ihr API-Schlüssel wurde erfolgreich erstellt. Kopieren Sie den neuen API-Schlüssel und das API-Schlüssel-Secret, und verwenden Sie beide Werte zum Generieren eines API-Tokens.`,
    'role-change': `Möchten Sie die <strong>{oldRole}</strong>-Rolle wirklich in die <strong>{newRole}</strong>-Rolle ändern?<br/><br/>Der Firmen-Admin hat vollen Zugriff auf die API und kann Dienste erstellen, verwalten und beenden. "Nur Lesen" kann Nutzungsdaten und Konfigurationsdetails anzeigen, aber nicht ändern.`,
    'delete-key': `Möchten Sie diesen Schlüssel wirklich löschen? Sobald der Schlüssel gelöscht ist, können Sie ihn nicht mehr zum Generieren von neuen Tokens verwenden.`,
    'regenerate-secret': `Durch Neugenerieren Ihres Secrets werden alle Sitzungen abgebrochen, die das alte Secret verwenden. Sie werden das neuen Secret benutzen müssen, um Tokens zu generieren, mit denen dieser Schlüssel genutzt werden kann. Möchten Sie Ihr Secret wirklich neu generieren?`,
    'api-keys': `API-Schlüssel`,
    'read-only': `„Nur Lesen“ ist die Rolle mit den größten Einschränkungen. Bitte beachten Sie, dass ein Benutzer mit dieser Rolle Dienstdetails anzeigen kann, die Sie unter Umständen unter Verschluss halten möchten.`,
    admin: `Firmen-Admin`,
    'unknown-role': `Unbekannte Rolle`,
    'your-api-key': `Ihr API-Schlüssel`,
    'api-key': `API-Schlüssel`,
    secret: `API-Schlüssel-Secret`,
    'secret-message': `Dieses Secret kann nicht noch einmal generiert oder angezeigt werden. Stellen Sie sicher, dass es an einem sicheren Ort aufbewahrt wird.`,
    history: `API-Schlüsselhistorie`,
    'history-order': `Die neuesten Änderungen befinden sich oben`,
    'edited-by': `Bearbeitet von`,
    'update-date': `Letzte Aktualisierung`,
    role: `Rolle`,
    created: `Schlüssel erstellt`,
    updated: `Schlüssel aktualisiert`,
    regenerated: `Secret neu generiert`,
    'unknown-action': `Unbekannte Schlüsselaktion`,
    'unknown-user': `Unbekannter Benutzer`,
    'unable-to-read': `API-Schlüssel können nicht gelesen werden`,
    key: `Schlüssel`,
    'generate-key': `Schlüssel generieren`,
    'generate-label': `API-Schlüssel genieren`,
    generating: `Generieren von API-Schlüssel`,
    'read-only-impersonate': `Da Sie die Identität eines anderen Benutzers übernehmen, haben Sie Nur-Lese-Zugriff auf die Schlüssel des Kontos.`,
    'success-generate': `API-Schlüssel erfolgreich generiert.`,
    'failed-generate': `Generieren des API-Schlüssels fehlgeschlagen`,
    'success-update': `API-Schlüssel erfolgreich aktualisiert.`,
    'failed-update': `Aktualisieren des API-Schlüssels fehlgeschlagen`,
    'success-delete': `API-Schlüssel erfolgreich gelöscht.`,
    'failed-delete': `Löschen des API-Schlüssels fehlgeschlagen`,
    'active-keys': `Aktive API-Schlüssel`,
    'no-active-keys': `Sie haben derzeit keine aktiven Schlüssel. Generieren Sie einen Schlüssel, um beginnen zu können.`,
    'active-tooltip': `Sie benötigen den Schlüssel und das Secret, um das Zugriffstoken zu generieren. Das Secret wurde Ihnen bereits beim Generieren des Schlüssels angezeigt, oder Sie haben ein neues Secret für den Schlüssel generiert.`,
    'date-created': `Erstellungsdatum`,
    'added-by': `Hinzugefügt von`,
    'loading-user-data': `Laden von Benutzerdaten`,
    'loading-api-keys': `Laden vorhandener API-Schlüssel`,
    'deleting-api-keys': `Löschen des API-Schlüssels`,
    'regenerating-secret': `Neugenerieren des Secrets`,
    'saving-changes': `Speichern von Änderungen`,
    'regenerate-secret-label': `Secret neu generieren`,
    'view-history': `Historie anzeigen`,
    'confirm-role-change': `Rollenänderung bestätigen`,
    'delete-key-label': `Schlüssel löschen`,
    'company-admin': `Benutzer mit dem Status „Company Admin“ haben Zugriff auf sämtliche Benutzerrechte. Wir empfehlen, die Anzahl der Firmenadministratoren auf solche zu beschränken, die vollen Zugriff benötigen, aber mindestens zwei Benutzer zur Redundanz zu definieren.`,
    'technical-admin': `Diese Rolle bezeichnet Fachanwender, die Bestellungen erstellen und genehmigen.`,
    'technical-contact': `Diese Rolle bezeichnet technische Benutzer, die qualifiziert sind, Dienste zu entwerfen und zu modifizieren, aber nicht die Berechtigung haben, Bestellungen zu genehmigen.`,
    finance: `Finanzanwender sollten eine finanzielle Verantwortung innerhalb der Organisation haben und die Konsequenzen verstehen, die mit dem Löschen oder Genehmigen von Diensten einhergehen. {note}`,
    'financial-contact': `Diese Benutzerrolle ähnelt der Rolle „Finanzen“. Es besteht jedoch keine Möglichkeit, Bestellungen aufzugeben und zu genehmigen, Dienste zu löschen oder Dienstschlüssel zu verwalten.`,
    'read-only-short': `Nur Lesen`,
    'token-expiry': `Token-Gültigkeitsdauer (Minuten)`,
    'expiry-min': `Mindestens 5 Minuten`,
    'expiry-max': `Maximal 24 Stunden (1.440 Minuten)`,
    'api-resource': `API-Ressource`,
    action: `Aktion`,
    'name-min': `Mindestens 3 Zeichen`,
    'name-max': `Maximal 90 Zeichen`,
    'name-chars': `Sonderzeichen sind mit Ausnahme von Bindestrich und Unterstrich nicht zulässig`,
    'name-invalid': `Name ist ungültig`,
    'api-documentation': `${process.env.VUE_APP_EN_COMPANYINFO_NAME}-API-Dokumentation`,
    'finance-note': `Hinweis: Beim Erstellen von Diensten können Direktkunden die Kosten des Dienstes sehen, bevor sie ihn bereitstellen. Es ist auch möglich, die Kosten für bereitgestellte Dienste anzuzeigen, indem Sie einen Dienst im ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL_SHORT} auswählen und die Registerkarte „Details“ anzeigen.`,
    'api-role': `API`,
    'key-active-tooltip': `Schlüssel ist aktiv`,
    'key-inactive-tooltip': `Der Schlüssel ist aufgrund einer Benutzerdeaktivierung inaktiv. Solange der Benutzer, der diesen Schlüssel erstellt hat, nicht wieder aktiv wird, kann dieser Schlüssel nicht verwendet werden. Sie können in Erwägung ziehen, diesen Schlüssel zu löschen und einen neuen API-Schlüssel für einen aktiven Benutzer zu erstellen.`,
  },
  debug: {
    information: `Debug-Informationen`,
    'include-info': `Fügen Sie die obigen Informationen hinzu, wenn Sie das Erstellen eines Tickets planen.`,
  },
  pricebook: {
    product: `Produkt`,
    'monthly-rate': `Monatliche Rate`,
    'excludes-tax': `Preis ohne MwSt.`,
    'contact-sales': `Wenden Sie sich für angepasste Preise an den Vertrieb.`,
    failed: `Abrufen des Preises für Dienst {product} fehlgeschlagen`,
    'monthly-charges-excl-taxes': `Monatliche Gebühren ohne MwSt.`,
    'price-change-1': `Die aktuelle monatliche anteilige Pauschale für diesen Dienst ist {currentMonthlyPrice} (ohne MwSt.). Angenommen, es gibt keine weiteren Geschwindigkeitsänderungen, dann wird dieser Dienst mit {totalPrice} (ohne MwSt.) für die aktuelle Abrechnungsperiode berechnet. Dies ist {changeDirectionText} von {deltaPrice} (ohne MwSt.). Die neue geschätzte anteilige Monatspauschale für diesen Dienst ist {longTermMonthlyPrice} (ohne MwSt.).`,
    'price-change-2': `Die Änderungen dieses Dienstes führen zu einer Preisänderung. Wenn es keine weiteren Geschwindigkeitsänderungen gibt, betragen die Kosten für diesen Dienst in dieser Abrechnungsperiode {totalPrice} (ohne MwSt.). Dies ist {changeDirectionText} von {deltaPrice} (ohne MwSt.).`,
    'price-change-3': `Die monatliche Gebühr für diesen Dienst beträgt {longTermMonthlyPrice} (ohne MwSt.). Bestätigen Sie diese Änderung.`,
  },
  'price-change': {
    detected: `Tarifänderung erkannt`,
    cancel: `Nein, Änderungen ablehnen`,
    accept: `Ja, Änderungen akzeptieren`,
    'current-month': `Aktuelle monatliche Gebühr`,
    'new-monthly': `Neue monatliche Gebühr`,
    'this-period': `Diese Abrechnungsperiode`,
    change: `Ändern`,
    'pricing-model': `Änderungen an diesem Dienst führen zu einer Preisänderung gemäß unserer neuen Preismodell-Aktualisierung.`,
    'confirm-model-change': `Sobald diese Änderung akzeptiert wurde, ist es nicht möglich, zum alten Preismodell zurückzukehren und die nächste neue ausgestellte Rechnung wird dies widerspiegeln. Bestätigen Sie, dass Sie diese Änderungen übernehmen wollen.`,
    increase: `eine <strong>Erhöhung</strong>`,
    decrease: `eine <strong>Verringerung</strong>`,
    'current-monthly': `Aktuelle monatliche Gebühr`,
  },
  cancel: {
    immediate: `Sofortige Stornierung`,
    'remove-configured': `Konfigurierte Dienste entfernen`,
    terminate: `Dienste beenden`,
    'keep-configured-service': `Nein, konfigurierten Dienst behalten`,
    'keep-configured-services': `Nein, konfigurierte Dienste behalten`,
    'remove-configuration': `Ja, Konfiguration entfernen`,
    'keep-services': `Nein, Dienste behalten`,
    'clear-configured': `Möchten Sie wirklich alle ihre konfigurierten Dienste löschen?`,
    'remove-from-configured': `Möchten Sie diesen Dienst wirklich aus Ihren konfigurierten Diensten entfernen?`,
    'end-of-contract': `Am Vertragsende beenden`,
    later: `Später`,
    'terminate-immediately': `Sofort beenden`,
    now: `Jetzt`,
    'now-confirmation': `Durch Auswahl von „Dienste beenden“ wird der Beendigungsprozess bestätigt. Diese Dienste werden sofort entfernt. Für die folgenden Dienste wird bei vorzeitiger Kündigung eine Gebühr erhoben:`,
    'later-confirmation': `Durch Auswahl von „Dienste beenden“ wird der Beendigungsprozess bestätigt. Diese Dienste werden bis zu dem für den Dienst unten angegebenen Datum weiterhin wie gewohnt funktionieren. Für die folgenden Dienste wird bei vorzeitiger Kündigung eine Gebühr erhoben:`,
    unable: `Stornierung nicht möglich:`,
    better: `Hätten wir etwas besser machen können?`,
    'no-permission': `Sie haben keine Berechtigung zum Löschen von Diensten`,
    error: `Fehler beim Laden der Stornierungsdaten`,
    diversity: `Diversitätsbeziehung gefunden`,
    'issues-terminating': `0 | Probleme beim Beenden von einem Dienst. | Probleme beim Beenden von {count} Diensten.`,
    terminating: `0 | 1 Dienst wird beendet. | {count} Dienste werden beendet.`,
    terminated: `0 | 1 Dienst beendet. | {count} Dienste beendet.`,
    pending: `Ausstehende Beendigung`,
    'do-not-restore': `Nein, Dienst nicht wiederherstellen`,
    restore: `Ja, Dienst wiederherstellen`,
    restoring: `Dienst wird wiederhergestellt ...`,
    'cancel-termination-confirmation': `Dieser Dienst wurde für die Beendigung eingeplant. Möchten Sie diese Beendigungsanforderung abbrechen und den Dienst wiederherstellen?`,
    restored: `Zur Beendigung ausstehender Dienst wurde wiederhergestellt.`,
    'error-uncancelling': `Fehler bei Rücknahme der Stornierung des Dienstes.`,
  },
  canada: {
    ab: `Alberta`,
    bc: `British Columbia`,
    mb: `Manitoba`,
    nb: `New Brunswick`,
    nl: `Neufundland und Labrador`,
    nt: `Northwest Territories`,
    ns: `Nova Scotia`,
    nu: `Nunavut`,
    on: `Ontario`,
    pe: `Prince Edward Island`,
    qc: `Quebec`,
    sk: `Saskatchewan`,
    yt: `Yukon`,
  },
  company: {
    'trading-name': `Handelsname`,
    'view-markets': `Abrechnungsmärkte anzeigen`,
    'company-trading-name': `Handelsname der Firma`,
    'company-legal-name': `Juristischer Name der Firma`,
  },
  alibaba: {
    'connection-details': `Verbindungsdetails für Alibaba-Dienst`,
    'account-id': `Alibaba Cloud-Konto-ID`,
    'account-tooltip': `Beziehen Sie dies von Ihrem Alibaba-Konto`,
    connection: `Alibaba-Verbindung`,
    'connection-id': `Alibaba-Verbindungs-Id`,
  },
  amsix: {
    'connection-details': `Verbindungsdaten für AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienst`,
    peer: `Peering mit Routeservern?`,
    selective: `Gezielt`,
    'noc-contact': `NOC-Kontakt`,
    'url-tooltip': `AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienste benötigen eine Web-URL für die Werbung mit Firmeninformationen auf dem ${process.env.VUE_APP_EN_PRODUCTNAME_IX}.`,
    'email-tooltip': `AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Dienste benötigen eine E-Mail für die Werbung mit Firmeninformationen auf dem ${process.env.VUE_APP_EN_PRODUCTNAME_IX}.`,
    'select-country': `Land auswählen`,
    'admin-phone-tooltip': `Eine Telefonnummer kann von einem Firmen-Admin auf dem Bildschirm "Benutzer verwalten" oder vom Benutzer in seinem Benutzerprofil hinzugefügt werden.`,
    'phone-tooltip': `Sie können Ihre Telefonnummer in Ihrem Benutzerprofil aktualisieren, oder kontaktieren Sie Ihren Firmen-Admin.`,
  },
  aws: {
    'connection-details': `Verbindungsdetails für AWS-Dienst`,
    'edit-warning': `Das Bearbeiten der AWS-Details für diesen Dienst initiiert die Neuerstellung und erfordert die Akzeptanz als neue VIF-Verbindungsanfrage über die AWS-Konsole des empfangenden Kontos. Bestätigen Sie dies, bevor Sie Änderungen durchführen.`,
    'warning-button': `Ich verstehe`,
    'start-editing': `Klicken Sie auf die Schaltfläche oben, um mit dem Bearbeiten zu beginnen`,
    'no-edit-hosted': `Hinweis: AWS-Details zu gehosteten Verbindungen können nicht bearbeitet werden.`,
    'connection-name': `AWS-Verbindungsname`,
    'connection-name-tooltip': `Geben Sie eine Bezeichnung für den Dienst in AWS an`,
    'account-id': `AWS-Konto-ID`,
    'account-id-tooltip': `Erhalten Sie dies von Ihren AWS-Kontoeinstellungen in der AWS-Konsole`,
    'bgp-asn': `Öffentliche oder private BGP-ASN`,
    'amazon-asn': `Amazon-ASN`,
    'default-amazon-asn': `Lassen Sie das Feld leer, um den vom BGP-Peer empfangenen Wert zu verwenden.`,
    'bgp-key': `BGP-Authentifizierungsschlüssel`,
    'bgp-key-tooltip': `Geben Sie einen BGP-Authentifizierungsschlüssel oder ein Kennwort (6-24 Zeichen, kein Leerzeichen) ein. Wenn Sie diesen nicht bereitstellen, werden wir einen für Sie generieren`,
    'amazon-prefixes': `Amazon-Präfixe`,
    'amazon-prefixes-tooltip': `Präfixe zur Ankündigung bei AWS, nur RIR-zugewiesene IPv4-Adressbereiche`,
    'connection-type': `AWS-Verbindungstyp`,
    'hosted-vif': `Gehostetes VIF`,
    'hosted-vif-description': `Gehostete VIFs (Hosted Virtual Interfaces) können sich mit öffentlichen oder privaten AWS-Clouddiensten verbinden.`,
    'hosted-connection': `Gehostete Verbindung`,
    'documentation-html': `Weitere Informationen zu AWS-Verbindungsoptionen finden Sie unter: {link}`,
    'diversity-html': `Einzelheiten finden Sie unter {link}`,
    'vif-connection': `AWS-VIF-Verbindung`,
    'vif-id': `VIF-ID`,
    label: `AWS-Bezeichnung`,
    'customer-address': `Kundenadresse`,
    'amazon-address': `Amazon-Adresse`,
    'aws-hosted-connection': `AWS-gehostete Verbindung`,
    'aws-vif': `AWS-VIF`,
    'connection-id': `AWS-Verbindungs-ID`,
    'manual-intervention': `Hinweis: Öffentliche VIFs erfordern einen manuellen Eingriff von Amazon, was bis zu 48 Stunden dauern kann.`,
    'hosted-connection-description': `Jeder ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} für gehostete Verbindungen unterstützt ein einzelnes AWS VIF für ein öffentliches, privates oder Transit-Gateway.`,
    'key-features': `Wichtige Merkmale sind:`,
    'aws-vif-feature-list-1': `Die AWS-Portgebühr ist in der ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Verbindung enthalten.`,
    'aws-vif-feature-list-2': `Verwaltung und Überwachung der gemeinsam genutzten Kundenbandbreite.`,
    'aws-vif-feature-list-3': `Möglichkeit, die ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Geschwindigkeit zu ändern.`,
    'aws-vif-feature-list-4': `Konfigurierbar in ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Schritten von 1 Mbit/s zwischen 1 Mbit/s und 5 Gbit/s.`,
    'aws-vif-feature-list-5': `Automatische Konfiguration des angeschlossenen ${process.env.VUE_APP_EN_PRODUCTNAME_MCR_LONG}.`,
    'aws-hosted-feature-list-1': `Die AWS ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Gebühr wird über Amazon abgerechnet.`,
    'aws-hosted-feature-list-2': `Dedizierte Bandbreite.`,
    'aws-hosted-feature-list-3': `Wählen Sie ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Geschwindigkeitsstufen von 50 Mbit/s bis 10 Gbit/s.`,
    'aws-hosted-feature-list-4': `Keine ${process.env.VUE_APP_EN_PRODUCTNAME_VXC}-Geschwindigkeitsänderungen.`,
    'aws-hosted-feature-list-5': `Unterstützung für diverse ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} für Ausfallsicherheit. Hervorhebung diversifizierter ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} durch Farbcodierung`,
    'documentation-link': `AWS-Verbindungsübersicht – ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Dokumentation`,
    'readonly-warning': `Das Bearbeiten der AWS-Details für diesen Dienst initiiert die Neuerstellung und erfordert die Akzeptanz als neue VIF-Verbindungsanfrage über die AWS-Konsole des empfangenden Kontos.`,
    onboarding: `AWS-Onboarding`,
    'onboarding-reachout': `Ein Mitarbeiter von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} wird sich in den nächsten 1–2 Werktagen mit Ihnen in Verbindung setzen, um Sie beim Onboarding zu unterstützen.`,
    'onboarding-questions': `Sollten Sie in der Zwischenzeit Fragen haben, können Sie uns unter folgender Adresse erreichen: {email}`,
    'onboarding-info': `Geben Sie so viele Informationen wie möglich an, einschließlich der AWS-Angebots- oder Vertragsnummer.`,
    'onboarding-success-title': `AWS-Onboarding erfolgreich`,
    'onboarding-success': `Vielen Dank für die Übermittlung. Ein Mitarbeiter von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} wird sich in den nächsten 1–2 Werktagen mit Ihnen in Verbindung setzen, um Sie beim Onboarding zu unterstützen.`,
  },
  azure: {
    'primary-subnet': `Primäres Subnetz`,
    'primary-subnet-placeholder': `Primäres Subnetz, zum Beispiel 192.0.1.32/30`,
    'secondary-subnet': `Sekundäres Subnetz`,
    'secondary-subnet-placeholder': `Sekundäres Subnetz, zum Beispiel 192.0.1.32/30`,
    'public-prefixes': `Öffentliche Präfixe`,
    'public-prefixes-placeholder': `Öffentliche Präfixe, zum Beispiel 192.0.2.0/24,192.0.2.0/30`,
    'shared-key': `Gemeinsam verwendeter Schlüssel`,
    'update-peer': `Peer aktualisieren`,
    'editing-message': `Die Verbindungsdetails für diesen Dienst müssen auf der Azure-Seite bearbeitet werden.`,
    'no-peers': `Keine Peers definiert`,
    'peer-type': `Peer-Typ`,
    'disable-peering-type': `Möchten Sie diesen Peering-Typ wirklich deaktivieren?`,
    'disable-peer-type': `Peer-Typ deaktivieren`,
    configuration: `Azure-Konfiguration`,
    'express-route-html': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} liefert ExpressRoute-Dienste an primäre oder sekundäre Microsoft-${process.env.VUE_APP_EN_PRODUCTNAME_PORTS} als Q in Q (doppelt getaggt, oder 802.1ad). Stellen Sie sicher, dass Sie mit den {link} auf der Microsoft-Website vertraut sind.`,
    'peering-html': `Öffentliche, private und Microsoft-Peering-Typen sind über diese Methode unter Verwendung eines einzelnen ExpressRoute-Dienstschlüssels verfügbar. Weitere Informationen zu diesen Peering-Typen und zur Verfügbarkeit finden Sie in der {link}.`,
    'get-service-key': `Wie Sie einen Azure ExpressRoute-Dienstschlüssel erhalten.`,
    'azure-service-key': `Microsoft Azure-Dienstschlüssel`,
    'peering-type': `Peering-Typ`,
    'peering-type-explanation': `Der Peering-Typ für diesen Dienstschlüssel wurde bereits für einen anderen Dienst eingestellt.`,
    microsoft: `Microsoft`,
    'back-to-service-key': `Kehren Sie zu "Dienstschlüssel" zurück, um Ihren Schlüssel einzugeben und die Verbindung zu erstellen.`,
    interface: `Azure-Schnittstelle`,
    'q-in-q': `Q-in-Q-Schnittstellendefinitionen`,
    'ms-doc': `Microsoft-Dokumentation`,
  },
  google: {
    'cloud-configuration': `Google Cloud-Konfiguration`,
    'gci-configuration': `GCI-Konfiguration`,
    'partner-peering-key': `Google Partner-Kopplungsschlüssel`,
    'show-all-locations': `Alle Google Partner-Standorte anzeigen`,
    'back-to-enter-peering': `Zurück zur Eingabe des Kopplungsschlüssels`,
    interface: `GCI-Schnittstelle`,
    connection: `Google-Verbindung`,
  },
  ix: {
    'ecix-asn-fixed': `Hinweis: Die ASN kann für ECIX-Dienste nach der Bereitstellung nicht mehr geändert werden.`,
    'bgp-password-tooltip': `Geben Sie ein BGP-Passwort ein, wenn Sie eines einstellen möchten.`,
    'bgp-password-warning': `Hinweis: Das BGP-Kennwort kann nach der Bereitstellung nicht mehr geändert werden.`,
    'looking-glass-graphs': `Nutzungsdiagramme werden in Looking Glass angezeigt`,
    'no-looking-glass-graphs': `Nutzungsdiagramme werden in Looking Glass nicht angezeigt`,
  },
  'mcr-config': {
    'enable-qinq': `Q-in-Q aktivieren?`,
    invalid: `Ungültig`,
    'add-vlan': `VLAN hinzufügen`,
    'virtual-lan': `Virtuelles LAN`,
    'unique-id': `Stellen Sie eine eindeutige ID für das VLAN bereit`,
    'vlan-id': `VLAN-ID`,
    'delete-vlan': `VLAN löschen`,
    'interface-ip-addresses': `Schnittstellen-IP-Adressen`,
    'ip-subnet': `IP-Adresse und Subnetzmaske`,
    nat: `Netzwerkadressübersetzung (NAT)`,
    'nat-source': `NAT-Quell-IP-Adresse`,
    'nat-source-tooltip': `Wählen Sie eine IPv4-Adresse aus, um Quellen-NAT für diese Schnittstelle zu aktivieren. Bei allen Paketen, die diese Schnittstelle verlassen, wird die Quelladresse auf diese Adresse umgeschrieben.`,
    'nat-enabled': `NAT aktiviert`,
    'bgp-connections-description': `BGP-Peering-Beziehungen für diese Schnittstelle – maximal fünf. Erfordert das Erstellen einer Schnittstellen-IP-Adresse.`,
    'bgp-add': `BGP-Verbindung hinzufügen`,
    'bfd-settings': `Einstellungen für bidirektionale Weiterleitungserkennung (BFD)`,
    'no-bfd-enabled': `Bei keiner BGP-Verbindung ist BFD aktiviert`,
    'transmit-interval': `Sendeintervall`,
    'receive-interval': `Empfangsintervall`,
    multiplier: `Multiplikator`,
    'bgp-connection': `BGP-Verbindung`,
    'disable-qinq-message': `Durch Deaktivieren von Q-in-Q werden alle Einstellungen entfernt.`,
    confirm: `Möchten Sie wirklich fortfahren?`,
    'delete-vlan-message': `Dadurch werden dieses VLAN und alle seine Einstellungen gelöscht. Fortfahren?`,
    'nat-is-enabled': `NAT ist aktiviert`,
    'no-prefix-filter': `Kein Präfixfilter`,
  },
  nutanix: {
    configuration: `Nutanix-Konfiguration`,
    'service-key': `Nutanix-Dienstschlüssel`,
    'service-key-notice': `Für die Verbindung zu einem Nutanix-${process.env.VUE_APP_EN_PRODUCTNAME_PORT} benötigen Sie einen Nutanix-Dienstschlüssel. Wenn Sie noch keinen Dienstschlüssel haben, besuchen Sie {link}, um einen zu erhalten.`,
    interface: `Nutanix-Schnittstelle`,
    'accept-speed-change': `Hinweis: Die Geschwindigkeitseinstellung für Ihren Nutanix-Dienstschlüssel wurde aktualisiert. Klicken Sie auf "Speichern", um das neue Übertragungsratenlimit zu akzeptieren.`,
    nutanix: `Nutanix`,
  },
  oracle: {
    configuration: `Oracle-Konfiguration`,
    'virtual-circuit-id': `Oracle-Virtual Circuit-ID`,
  },
  salesforce: {
    configuration: `Verbindungsdetails für Salesforce-Dienst`,
    approvals: `Die Genehmigung von Salesforce-Verbindungen kann bis zu zwei US-Arbeitstage dauern.`,
    'provide-password': `Geben Sie ein BGP-Passwort ein. Wenn Sie keines bereitstellen, werden wir eins für Sie generieren`,
    prefixes: `Präfixe`,
    'prefixes-tooltip': `Präfixe zur Ankündigung bei Salesforce, nur RIR-zugewiesene IPv4-Adressbereiche.`,
    connection: `Salesforce-Verbindung`,
    'permitted-prefixes': `Zugelassene BGP-Präfixe`,
  },
  'target-select': {
    'no-bio': `Keine Firmen-Biographie verfügbar.`,
    'marketplace-html': `Weitere Informationen finden Sie auf deren {link}.`,
    'hide-info': `Informationsbereich ausblenden`,
    'show-info': `Informationen zu diesen Optionen anzeigen`,
  },
  'target-service-key': {
    'destination-found': `Ziel gefunden`,
    'key-name': `Schlüsselname`,
    'max-speed': `Max. Geschwindigkeit`,
  },
  events: {
    session: `Sitzungsereignisprotokoll`,
    clear: `Alle Ereignisse löschen`,
    cleared: `Das Sitzungsereignisprotokoll wurde gelöscht.`,
  },
  graph: {
    private: `Diese Firmendaten sind privat`,
    'load-error': `Laden der Diagrammdaten fehlgeschlagen`,
    'no-data': `Keine Diagrammdaten verfügbar`,
    'in-mbps': `Eing. Mbit/s`,
    'out-mbps': `Ausg. Mbit/s`,
    'billing-month': `Abrechnungsmonat:`,
    'a-end-not-owned': `Keine Abrechnungsinformationen, da das A-Ende dieser Verbindung einer anderen Firma gehört.`,
    'load-failed': `Laden der erforderlichen Daten fehlgeschlagen`,
    'no-billing-data': `Keine Abrechnungsdaten verfügbar`,
    estimated: `Voraussichtliche nächste Rechnung (ohne MwSt.) für diesen Dienst.`,
    calculated: `Rechnung (ohne MwSt.) für diesen Dienst.`,
    'too-many-changes': `Zu viele Geschwindigkeitsänderungen für die Grafik. Laden Sie die CSV herunter, um die Daten anzuzeigen.`,
    'configured-speed': `Konfigurierte Geschwindigkeit`,
    'billing-details': `Abrechnungsdetails`,
    'estimated-next': `Voraussichtliche nächste Rechnung (ohne MwSt.) für diesen Dienst, ohne dass weitere Änderungen erfolgen.`,
    speed: `Geschwindigkeit (Mbit/s)`,
    download: `CSV herunterladen`,
    'service-graph': `Nutzungsdiagramm`,
    'date-range': `Datumsbereich:`,
    'last-12-h': `Letzte 12 Stunden`,
    'last-24-h': `Letzte 24 Stunden`,
    'last-7-d': `Letzte 7 Tage`,
    'last-4-w': `Letzte 4 Wochen`,
    metrics: `Metriken:`,
    source: `Quelle:`,
    timezone: `Zeitzone:`,
    'nothing-selected': `Keine Daten für Grafik ausgewählt`,
    'date-time': `Datum/Uhrzeit`,
    in: `Eingehend`,
    out: `Ausgehend`,
    'last-hour': `k. A. | Letzte Stunde | Letzte {count} Stunden`,
    'last-week': `k. A. | Letzte Woche | Letzte {count} Wochen`,
    'last-month': `k. A. | Letzter Monat | Letzte {count} Monate`,
    // Koordinierte Weltzeit
    utc: `UTC`,
    'export-csv': `CSV exportieren`,
    'downloading-csv': `CSV wird heruntergeladen`,
  },
  'looking-glass': {
    'select-ix': `${process.env.VUE_APP_EN_PRODUCTNAME_IX} auswählen`,
    title: `Looking Glass`,
    select: `Internetknoten auswählen`,
    filter: `Eingabe zum Filtern nach Namen oder ASN`,
    'no-peers': `Keine verfügbaren Peers`,
    'loading-peers': `Peer-Liste wird geladen ...`,
    rs1: `RS1`,
    'route-server-1': `Routeserver 1`,
    rs2: `RS2`,
    'route-server-2': `Routeserver 2`,
    'extended-details': `Erweiterte Peer-Details`,
    routing: `Routing-Informationen`,
    routes: `Routen`,
    imported: `Importiert`,
    best: `Beste`,
    filtered: `Gefiltert`,
    loading: `Diagrammdaten werden geladen ...`,
    'loading-routes': `Routen werden geladen ...`,
    'route-load-failed': `Laden der Routendaten fehlgeschlagen`,
    'no-routes': `Keine verfügbaren Routen`,
    'local-pref': `Lokale Präferenz`,
    'origin-asn': `Original-ASN`,
    'asn-path': `ASN-Pfad`,
    since: `Seit`,
    'route-data-load-failed': `Laden der Routendaten fehlgeschlagen`,
    'detail-data-load-failed': `Laden der Detaildaten fehlgeschlagen`,
    'ip-unavailable': `IPv4 und IPv6 nicht verfügbar.`,
    'protocol-unavailable': `{protocol} ist nicht verfügbar.`,
    'ip-available': `IPv4 und IPv6 sind verfügbar.`,
    asn: `ASN:`,
  },
  'image-upload': {
    select: `Bild auswählen`,
    crop: `Bild zuschneiden`,
    'drop-image': `Bilddatei hier ablegen oder zum Hochladen anklicken`,
    clear: `Bild löschen`,
  },
  'no-services': {
    message: `Schnelles, flexibles Vernetzen mit nur wenigen Klicks.`,
    action: `Lass Dich verbinden`,
    instructions: `Um beginnen zu können, erstellen Sie über die Schaltfläche oben einen ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}, oder Sie klicken auf "Tour starten", um eine kurze Demonstration des Bestellvorgangs zu erhalten.`,
  },
  'google-login': {
    unavailable: `Google-Anmeldung nicht verfügbar`,
    loading: `Die Google-Dienste werden immer noch geladen – versuchen Sie es in Kürze noch einmal.`,
    problem: `Beim Laden der Google-Autorisierungsdienste ist ein Problem aufgetreten. Dieses Problem wurde an unsere Entwickler gemeldet. Verwenden Sie diesmal zur Authentifizierung Benutzername und Kennwort.`,
    cancelled: `Google-Anmeldung abgebrochen`,
    'cancelled-message': `Google-Anmeldeversuch wurde von Ihnen abgebrochen.`,
    blocked: `Google-Anmeldung blockiert`,
    'blocked-message': `Für die Anmeldung mit Google ist ein Popup-Fenster erforderlich, das von Ihrem Browser blockiert wird. Erlauben Sie Popups auf dieser Website.`,
    'cookies-blocked-message': `Ihr Browser ist so eingestellt, dass er Cookies von Drittanbietern blockiert. Für die Anmeldung mit Google müssen Sie die Verwendung von Drittanbieter-Cookies erlauben und dann die Seite neu laden.`,
    'browser-blocked-message': `Die Anmeldung mit Google konnte auf Ihrem Browser nicht ausgeführt werden. Die Details sind wie folgt: {details}`,
    failed: `Google-Anmeldung fehlgeschlagen`,
    'failed-message': `Anmeldung über Google fehlgeschlagen.`,
    'token-received': `Google-Token erhalten, Anmeldung wird ausgeführt ...`,
    'no-details': `Keine zusätzlichen Details`,
    'token-failed-message': `Es ist ein Fehler bei der Überprüfung Ihres Google-Login-Tokens aufgetreten. Bitte versuchen Sie, sich erneut anzumelden, oder löschen Sie Ihre Browser-Cookies, falls das Problem weiterhin besteht.`,
  },
  'whitelabel-dlr': {
    welcome: `Willkommen bei DLR`,
    'uptimes-blog': `Uptimes Blog`,
    twitter: `Folgen Sie uns auf Twitter`,
    linkedin: `Folgen Sie uns auf Linkedin`,
    conditions: `Siehe unsere Bedingungen und Datenschutzbestimmungen`,
    contact: `Kontakt und Support`,
    careers: `Karriere – Wir stellen ein`,
  },
  tooltips: {
    'route-prefix': `Eine IPv4- oder IPv6-Netzwerkadresse in CIDR-Notation.`,
    'route-next-hop': `Die IP-Adresse des Next-Hop-Routers.`,
    'required-item-start': `Elemente, die mit einem`,
    'required-item-end': `gekennzeichnet sind, sind erforderliche Felder.`,
    'local-ip-address': `Die IPv4- oder IPv6-Adresse dieser Schnittstelle, die für die Kommunikation mit dem BGP-Peer verwendet werden soll.`,
    'peer-ip-address': `Die IP-Adresse des BGP-Peers.`,
    'peer-asn': `Die ASN des externen BGP-Peers. 4-Byte AS-Nummern werden im asplain-Format unterstützt.`,
    'bgp-password': `Ein gemeinsamer Schlüssel, der zur Authentifizierung des BGP-Peers verwendet wird; bis zu 25 Zeichen lang.`,
    'bgp-description': `Eine Beschreibung für die BGP-Verbindung; bis zu 100 Zeichen lang.`,
    'bgp-state': `Standardmäßig sind BGP-Verbindungen aktiviert und versuchen aktiv, sich mit dem Peer zu verbinden. Wählen Sie "Herunterfahren" aus, um die BGP-Sitzung vorübergehend zu deaktivieren, ohne sie zu entfernen. Dies kann bei der Problembehandlung oder beim Testen von Failover-Szenarios nützlich sein.`,
    'med-inbound': `Der MED wird auf alle Routen angewendet, die auf dieser BGP-Verbindung empfangen werden. Lassen Sie das Feld leer, um den vom BGP-Peer empfangenen Wert zu verwenden. Die Route mit dem niedrigsten Wert wird bevorzugt.`,
    'med-outbound': `Der MED wird auf alle Routen angewendet, die auf dieser BGP-Verbindung gesendet werden. Das benachbarte autonome System kann nach eigenem Ermessen den niedrigsten Wert bevorzugen.`,
    bfd: `Bidirectional Forwarding Detection ermöglicht eine schnelle Fehlererkennung auf dieser BGP-Verbindung. Wenn die Verbindung unterbrochen wird, wird die BGP-Sitzung geschlossen, ohne dass der Ablauf der BGP-Haltezeit abgewartet wird. Die Verwendung von BFD kann die Failover-Zeit verbessern, wenn mehrere Routen verfügbar sind. Hinweis: Diese Einstellungen werden für alle BGP-Verbindungen auf diesem VXC verwendet, bei dem BFD aktiviert ist.`,
    'bfd-group': `Hinweis: Diese Einstellungen werden für alle BGP-Verbindungen auf dieser Schnittstelle verwendet, bei der BFD aktiviert ist.`,
    'bfd-transmit': `Die minimale Zeit zwischen dem Senden von BFD-Paketen an den Nachbarn. Der unterstützte Bereich ist 300 ms bis 9.000 ms.`,
    'bfd-receive': `Die minimale Zeit zwischen BFD-Paketen, die ein Nachbar sendet. Der unterstützte Bereich ist 300 ms bis 9.000 ms.`,
    'bfd-multiplier': `Die BGP-Sitzung wird geschlossen, wenn so viele aufeinanderfolgende BFD-Pakete vom Nachbarn nicht empfangen werden.`,
    med: `Der Multi-Exit Discriminator kann verwendet werden, um die Routenpräferenz zu beeinflussen, wenn mehrere Routen zum selben Ziel verfügbar sind. Er wird normalerweise verwendet, um bestimmte primäre oder sekundäre Pfade zu bevorzugen. Werte zwischen 0 und 4.294.967.295 werden unterstützt.`,
    'bgp-config-description': `Die von diesem BGP-Peer empfangenen Routen werden anderen BGP-Peers auf diesem ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} bekannt gegeben. <br />Sie können Routen standardmäßig allen Peers bekannt geben und zusätzlich Routen für bestimmte Peers filtern. Sie können aber auch Routen zu allen Peers standardmäßig filtern und Routen zu bestimmten Peers bekannt geben.`,
    'bgp-prefix-filter': `<p>Wenden Sie einen vordefinierten Präfixfilter auf diesen BGP-Peer an, um die Menge der Routen zu begrenzen, die von diesem Peer empfangen oder dem Peer angekündigt werden.</p>
      <ul>
      <li>Kein Präfixfilter erlaubt alle Routen. Es werden keine Routen gefiltert.</li>
      <li>Eine Zulassungsliste erlaubt nur Routen, die mit der Präfixliste übereinstimmen. Andere Routen werden gefiltert.</li>
      <li>Eine Ablehnungsliste lässt alle Routen zu, außer denen, die mit der ausgewählten Präfixliste übereinstimmen.</li>
      </ul>
      <p>Um Präfixfilterlisten zu definieren, wählen Sie auf der ${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Konfigurationsseite die Registerkarte mit den Präfixfilterlisten aus.</p>
      `,
    'bgp-prefix-editor': `
          <p>
          Die Präfixfilterliste des ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} ist eine benannte Liste von IP-Netzwerkadressen. Erstellen Sie eine Präfixfilterliste, um Routen anhand ihres Präfixes zu ermitteln und herauszufiltern und den Filter dann auf
          die Routen anzuwenden, die von BGP-Peers auf diesem ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} empfangen oder diesen BGP-Peers bekannt gegeben werden.
          Der ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} gleicht jedes Routenpräfix mit den in der Liste enthaltenen Bedingungen ab, einen Treffer nach dem anderen von oben nach unten, um die Route abzulehnen oder bekannt zu geben.
          </p>
          <ul>
          <li>Eine Präfixfilterliste kann mehrere Male auf unterschiedlichen BGP-Peers angewendet werden.</li>
          <li>Auf jedem ${process.env.VUE_APP_EN_PRODUCTNAME_MCR} können bis zu 50 Präfixfilterlisten konfiguriert werden.</li>
          <li>Jede Liste kann bis zu 200 Einträge enthalten.</li>
          </ul>
         `,
    'company-trading-name': `Der Name Ihres Kontos, Unternehmens oder Ihrer Organisation. Sie können diese Angabe jederzeit ändern.`,
    'as-path-prepend-tooltip': `Die lokale ASN wird dem AS-Pfad von ausgehenden BGP-Routenankündigungen vorangestellt. Geben Sie eine Zahl für 0 bis maximal 10 Voranstellungen ein.`,
    'shutdown-state': `Aktualisieren Sie den konfigurierten Status auf „Shut Down“ (Abgeschaltet), um zu testen, wie sich der Dienst im ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Netzwerk verhalten würde, wenn er ausgefallen wäre.`,
    'move-connection-limits': `Nur Ports desselben Produkttyps und innerhalb derselben Metro sind zur Auswahl verfügbar.`,
    'move-connection-vnic': `Die Bezeichnung des A- oder B-Endes des ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} wurde beim Erstellen des ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} festgelegt.`,
  },
  time: {
    min: `min`,
    s: `s`,
    ms: `ms`,
    µs: `µs`,
  },
  'page-titles': {
    login: `Anmelden`,
    'user-profile': `Benutzerprofil`,
    'user-notifications': `Benachrichtigungen an Benutzer`,
    services: `Dienste`,
    dashboard: `Dashboard`,
    'create-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} erstellen`,
    mve: `${process.env.VUE_APP_EN_PRODUCTNAME_MVE}`,
    'create-connection': `Verbindung herstellen`,
    'edit-port': `${process.env.VUE_APP_EN_PRODUCTNAME_PORT} bearbeiten`,
    'edit-connection': `Verbindung bearbeiten`,
    'company-profile': `Firmenprofil`,
    'company-users': `Firmenbenutzer`,
    'email-confirmation': `E-Mail-Bestätigung`,
    'marketplace-profile': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Profil`,
    marketplace: `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}`,
    debug: `Debugging-Informationen`,
    'looking-glass': `Looking Glass`,
    'mcr-looking-glass': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR}-Looking Glass`,
    'event-log': `Sitzungsereignisprotokoll`,
    'ix-flows': `${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Übermittlung`,
    'api-keys': `API-Schlüssel`,
    'partner-admin': `Partner-Admin`,
    'managed-account': `Verwaltetes Konto`,
    'billing-markets': `Abrechnungsmärkte`,
    onboard: `Einrichten`,
    signup: `Anmeldung`,
    'signup-google': `Google-Anmeldung`,
    admin: `Admin`,
    'logging-in': `Anmelden`,
    'company-security-settings': `Sicherheitseinstellungen des Unternehmens`,
    'company-support': `Kontaktinformationen zum technischen Support`,
    'setup-mfa': `Einrichtung der Multi-Faktor-Authentifizierung`,
    mfa: `Multi-Faktor-Authentifizierung`,
    'create-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} erstellen`,
    'edit-mcr': `${process.env.VUE_APP_EN_PRODUCTNAME_MCR} bearbeiten`,
    'create-mve': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE} erstellen`,
    'edit-mve': `${process.env.VUE_APP_EN_PRODUCTNAME_MVE} bearbeiten`,
    'move-connections': `Verbindungen verschieben`,
    'aws-entitlements': `AWS-Berechtigungen`,
    'aws-onboarding': `AWS-Onboarding`,
    'service-status': `Dienststatus`,
  },
  partner: {
    'learn-more': `Weitere Informationen zum Management verwalteter Konten finden Sie in unserer Partner-Dokumentation.`,
    'view-documentation': `Dokumentation anzeigen`,
    'managed-accounts': `Verwaltete Konten`,
    'create-managed-account': `Verwaltetes Konto erstellen`,
    'view-services': `Dienste anzeigen`,
    edit: `Bearbeiten`,
    filter: `Konten filtern`,
    'no-managed-accounts': `Es gibt keine verwalteten Konten. Klicken Sie auf die Schaltfläche „Create Managed Account“ (Verwaltetes Konto erstellen), um Ihr erstes Konto zu erstellen.`,
    'invalid-company-uid': `Kein verwaltetes Konto gefunden, das mit der UID übereinstimmt.`,
    'no-match': `Keine übereinstimmenden Konten.`,
    'account-name': `Kontoname`,
    'company-uid': `Firmen-UID`,
    'managed-account-ref': `UID von verwaltetem Konto`,
    'managed-account-ref-placeholder': `Eindeutige Firmenreferenz`,
    actions: `Maßnahmen`,
    'managed-account': `Verwaltetes Konto`,
    'managed-learn-more': `Weitere Informationen zur Erstellung verwalteter Konten finden Sie in unserer Partner-Dokumentation.`,
    save: `Speichern`,
    create: `Erstellen`,
    cancel: `Abbrechen`,
    'account-created': `Verwaltetes Konto wurde erstellt.`,
    'account-saved': `Verwaltetes Konto wurde gespeichert.`,
    'create-account-error': `Fehler beim Erstellen des verwalteten Kontos.`,
    'update-account-error': `Fehler beim Aktualisieren des verwalteten Kontos.`,
    error: `Fehler`,
    'error-retrieving-accounts': `Verwaltete Konten konnten nicht abgerufen werden.`,
    'managed-accounts-info': `Zeigen Sie eine Liste von Konten an, legen Sie Berechtigungen fest und verwalten Sie Dienste.`,
    'edit-users': `Benutzer bearbeiten`,
    'edit-users-text': `Zeigen Sie eine vollständige Liste von Benutzern sowie deren Berechtigungen für diese Firma an und verwalten Sie sie.`,
    'new-managed': `Neues verwaltetes Konto`,
    'view-services-text': `Sie können neue Dienste für diese Firma einrichten, anzeigen und bestellen.`,
    'manage-services': `Dienste verwalten`,
    'diversity-unavailable-location': `Diversität ist aktuell nicht für diese Konfiguration verfügbar. Kontaktieren Sie Ihren ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Partner, um Ihre Diversitätsanforderungen zu erörtern.`,
  },
  'partner-admin': {
    'partner-account': `Partnerkonto`,
    'managed-account': `Verwaltetes Konto | Verwaltete Konten`,
    'managed-accounts-uid': `UID von verwaltetem Konto`,
    'select-account': `Konto auswählen`,
    'account-filter': `Konten nach Name oder Referenz filtern`,
    partner: `Partner`,
    'viewing-as': `Anzeigen als:`,
  },
  'partner-vantage': {
    subscription: `Abonnement`,
    'subscription-tooltip-ports': `Das Abonnement beginnt nach Aktivierung des Dienstes oder 14 Tage nach Konfiguration des Dienstes`,
    'subscription-tooltip': `Das Abonnement beginnt mit der Bestellung`,
    'no-subscription': `Kein Abonnement`,
    'change-subscription': `Abonnement ändern`,
    'subscription-title': `{productType}-Abonnement festlegen`,
    'subscription-choose': `Wählen Sie das {productType}-Abonnement`,
    'confirm-new-subscription': `Neues Abonnement starten`,
    'subscription-months': `Kein Abonnement | Abonnement: 1 Monat | Abonnement: {count} Monate`,
    'subscription-reterm': `Die Laufzeit für diesen Dienst (die Mindestlaufzeit) tritt sofort in Kraft. Der Rabatt für die Laufzeit wird auf die Rechnung des nächsten Kalendermonats angerechnet. Bei vorzeitiger Kündigung während der Laufzeit werden entsprechende Gebühren gemäß der zwischen uns getroffenen Vereinbarung fällig.`,
    'cancel-subscription': `Klicken Sie auf „Abbrechen“, wenn Sie am Ende des laufenden Abonnements nichts unternehmen und kein Abonnement zum Listenpreis abschließen möchten.`,
    'subscription-term': `Laufzeit des Abonnements`,
  },
  outscale: {
    connection: `Outscale-Verbindung`,
    'connection-details': `Verbindungsdetails für Outscale-Dienst`,
    'connection-name': `Name der Outscale-Verbindung`,
    'connection-name-tooltip': `Geben Sie eine Bezeichnung für den Dienst in Outscale an`,
    'account-id': `Outscale-Konto-ID`,
    'account-id-tooltip': `Geben Sie die Outscale-Eigentümer-Konto-ID ein`,
  },
  'company-security-settings': {
    'security-settings': `Sicherheitseinstellungen`,
    'mfa-long': `Multi-Faktor-Authentifizierung`,
    'info-enforced': `Nach der Einführung werden alle Benutzer Ihres Unternehmens bei der Anmeldung aufgefordert, MFA einzurichten.`,
    'info-optional': `Wenn dies optional ist, haben alle Benutzer Ihres Unternehmens weiterhin die Möglichkeit, MFA in ihrem Benutzerprofil zu aktivieren.`,
    'mfa-now-enforced': `MFA ist jetzt festgelegt`,
    'mfa-now-optional': `MFA ist jetzt optional`,
    'enforce-mfa-all-users': `Führen Sie Multi-Faktor-Authentifizierung (MFA) für alle Benutzer in Ihrem Unternehmen ein`,
    'error-updating-mfa': `Fehler beim Aktualisieren der MFA-Einstellung`,
    'enforce-mfa-tooltip-on': `Wenn MFA festgelegt ist (ON), müssen alle Benutzer MFA verwenden, wenn sie sich bei ihrem Konto anmelden. Wenn ein Benutzer derzeit keine MFA aktiviert hat, wird er bei der nächsten Anmeldung aufgefordert, MFA einzurichten. Die Benutzer können erst dann auf Ihr Unternehmen innerhalb von ${process.env.VUE_APP_EN_COMPANYINFO_NAME} zugreifen, wenn sie MFA eingerichtet haben.`,
    'enforce-mfa-tooltip-off': `Wenn MFA optional (AUS) ist, können Benutzer wählen, ob sie MFA für ihr Konto aktivieren möchten oder nicht.`,
    'enforce-mfa-tooltip-title': `Durchsetzung der MFA`,
    mfa: `MFA`,
    on: `EIN`,
    off: `AUS`,
    sso: `SSO`,
    'sso-long': `Single Sign-On`,
    'provider-identity': `Anbieter-Identität`,
    type: `Typ`,
    'identity-provider-info': `Informationen zum Identitätsanbieter`,
    'provider-name': `Name des Anbieters`,
    domains: `Domains`,
    metadata: `Metadaten-URL`,
    'integration-details': `Details zur Integration`,
    'audience-uri': `Publikums-URI`,
    'idp-response-url': `IdP-Antwort-URL`,
    'sso-fail': `SSO-Konfigurationen konnten nicht abgerufen werden.`,
    'sso-no-idps': `Keine konfigurierten Identitätsanbieter.`,
    'sso-attribute-mapping': `Attribut-Zuordnung`,
    'sso-user-pool-attribute': `Benutzerpool-Attribut`,
    'sso-saml-attribute': `SAML-Attribut`,
    'integration-details-desc': `Um einen SAML 2.0-Anbieter mit ${process.env.VUE_APP_EN_COMPANYINFO_NAME} zu verbinden, müssen Sie die Publikums-URI und die IdP-Antwort-URL an Ihren Identitätsanbieter weitergeben`,
    'details-for': `Details für {thing}`,
    'accepts-csv-values': `Akzeptiert eine durch Kommas getrennte Liste von Werten`,
    'enforce-sso-all-users': `Erzwingen von Single Sign-On (SSO) für alle Benutzer in Ihrem Unternehmen`,
    'enforce-sso-tooltip-title': `SSO erzwingen`,
    'enforce-sso-tooltip-on': `Wenn die SSO-Erzwingung aktiviert ist, müssen sich alle Benutzer über den standardmäßig konfigurierten IdP authentifizieren. Für alle Benutzer, die nicht mit dem IdP-Benutzerpool verbunden sind, wird der Zugang gesperrt. (Ausnahme: Firmen-Admins können sich weiterhin mit anderen Methoden anmelden, auch Firmen-Admins, die keine mit SSO verbundene Domäne haben).`,
    'enforce-sso-tooltip-off': `Wenn die SSO-Erzwingung deaktiviert ist, können die Benutzer verschiedene Authentifizierungsmethoden wie Benutzername/Passwort, Google oder SSO verwenden.`,
    'enforce-sso-info-enforced': `Sobald die SSO-Erzwingung aktiviert ist, müssen sich alle Benutzer über den standardmäßig konfigurierten IdP authentifizieren. Für alle Benutzer, die nicht mit dem IdP-Benutzerpool verbunden sind, wird der Zugang gesperrt. (Ausnahme: Firmen-Admins können sich weiterhin mit anderen Methoden anmelden, auch Firmen-Admins, die keine mit SSO verbundene Domäne haben).`,
    'enforce-sso-info-optional': `Sobald die SSO-Erzwingung deaktiviert ist, können die Benutzer verschiedene Authentifizierungsmethoden wie Benutzername/Passwort, Google oder SSO verwenden. Alle aktiven Benutzer in Ihrem Unternehmen können sich nun wieder anmelden.`,
    'enforce-sso': `SSO erzwingen`,
    'sso-connections': `SSO-Verbindungen`,
    'error-fetching-sso': `Unerwarteter Fehler beim Abrufen der SSO-Konfiguration`,
    'error-updating-sso': `Unerwarteter Fehler beim Aktualisieren der SSO-Konfiguration`,
    'configure-saml-idp': `Einen SAML 2.0-Identitätsanbieter (IdP) konfigurieren`,
    'configure-saml-description': `Sie können Ihr ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Konto für die Verwendung von Single Sign-On (SSO) konfigurieren. Wenn Sie SSO aktivieren, werden die Benutzer beim Zugriff auf die ${process.env.VUE_APP_EN_COMPANYINFO_PORTAL}-Website zur Authentifizierung an einen externen Identitätsanbieter (IdP) weitergeleitet, anstatt zur Eingabe eines Passworts aufgefordert zu werden. SSO vereinfacht die Anmeldung und macht es für die Benutzer überflüssig, sich mehrere Anmeldedaten zu merken, was die Benutzerfreundlichkeit erhöht und den Administratoren die Verwaltung erleichtert.`,
    'add-saml-connection': `SAML-Verbindung hinzufügen`,
    'register-saml-provider': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} bei Ihrem SAML-Anbieter registrieren`,
    'register-saml-provider-description': `Konfigurieren Sie Ihren SAML-Identitätsanbieter mit ${process.env.VUE_APP_EN_COMPANYINFO_NAME}, indem Sie die folgenden Details angeben (einschließlich der von Ihrem Identitätsanbieter verwendeten Metadaten-URL).`,
    'sso-update-success': `SSO-Einstellungen aktualisiert für {name}`,
    'maximum-domains-error': `Die maximale Anzahl von Domains beträgt {max}`,
    'invalid-domains': `Ungültige Domains gefunden {domains}`,
    'duplicate-domains': `Doppelte Domänen gefunden {domains}`,
    'idp-delete-confirmation-title': `Anbieter löschen`,
    'idp-delete-confirmation-message': `<strong>Wenn Sie mit dieser Aktion fortfahren, bestätigen Sie Folgendes:</strong><ul><li>Alle mit dieser Verbindung verbundenen Benutzer können sich nicht mehr mit Ihrem externen IdP anmelden.</li><li>Wenn SSO-Erzwingung aktiviert ist, wird sie deaktiviert.</li><li>Alle aktiven Benutzer Ihres Unternehmens können sich jetzt mit anderen Authentifizierungsmethoden anmelden.</li></ul>`,
    'sso-now-enforced': `SSO wird jetzt erzwungen`,
    'sso-now-optional': `SSO ist jetzt optional`,
    'new-user-non-sso-domain-warning': `Bitte beachten Sie, dass Single Sign-On (SSO) derzeit für Ihr Konto erzwungen wird. Obwohl Sie jeden Benutzer zu Ihrem Konto einladen können, können eingeladene Benutzer ihre Anmeldedaten nicht verwenden, wenn sie nicht mit einer SSO-Domäne verknüpft sind. Wenn der Benutzer ein Firmen-Admin ist, kann er ausnahmsweise auf Ihr Konto zugreifen, auch wenn er nicht mit einer SSO-Domäne verbunden ist.`,
    'sso-enforced-inactive-warning': `Bitte beachten Sie, dass Single Sign-On (SSO) derzeit für Ihr Konto erzwungen wird. Wenn Sie ein Benutzerkonto wieder aktivieren, das nicht mit einer SSO-Domäne verknüpft ist, wird der Benutzer weiterhin gesperrt und kann sich nicht anmelden. Wenn der Benutzer ein Firmen-Admin ist, kann er auf sein Konto zugreifen, auch wenn er nicht mit einer SSO-Domäne verbunden ist.`,
    'sso-new-docs-message': `Bevor Sie SSO zu diesem Konto hinzufügen, besuchen Sie bitte die {docs}-Seite. Hier finden Sie Anweisungen zur Einrichtung und wichtige Informationen zu Single Sign-On.`,
    'sso-features-and-instructions-for-use': `SSO-Funktionen und Anweisungen zur Verwendung`,
    'sso-add-settings-for-account': `Sie haben SSO-Einstellungen für dieses Konto hinzugefügt.`,
    'sso-ensure-config-complete': `Um sicherzustellen, dass Ihre Konfiguration vollständig ist, müssen Sie nun Ihren IdP mit der Publikums-URI und der Antwort-URL unten integrieren. Dies sind wesentliche Komponenten, die erforderlich sind, damit die SAML-Authentifizierung korrekt funktioniert:`,
    'sso-info-accessed-again-at-any-time': `Diese Informationen können jederzeit wieder abgerufen werden, indem Sie auf die Schaltfläche „Edit“ (Bearbeiten) neben Ihrer SSO-Verbindung klicken.`,
    'can-also-be-known-as': `Möglicherweise auch bekannt als:`,
    'entity-id': `Entitäts-ID`,
    audience: `Publikum`,
    'assertion-consumer-service': `Assertion Consumer Service (ACS) URL`,
    'single-sign-on-url': `Single Sign On URL`,
    'application-callback-url': `Application Callback URL`,
    'reply-url': `Antwort-URL`,
  },
  tscd: {
    'tech-supp-phone': `Telefonnummer des technischen Supports`,
    'tech-supp-phone-num': `Telefonnummer des technischen Support-Teams`,
    'tech-supp-phone-placeholder': `Telefonnummer der internen Support-Teams der Kunden`,
    'tech-supp-email': `E-Mail-Adresse des technischen Support-Teams`,
    'tech-supp-email-placeholder': `E-Mail-Adresse der internen Support-Teams der Kunden`,
  },
  'company-support': {
    'tech-supp-phone': `Telefonnummer des technischen Support-Teams`,
    'tech-supp-phone-placeholder': `Kontakttelefonnummer Ihres internen Support-Teams`,
    'tech-supp-email': `E-Mail-Adresse des technischen Support-Teams`,
    'tech-supp-email-placeholder': `Kontakt-E-Mail Ihres internen Support-Teams`,
    'admin-warning': `Firmenadministrator-Benutzer können Details zum technischen Supportteam eingeben und bearbeiten`,
    'customer-supp-heading': `Kundenkontakte`,
    'megaport-supp-heading': `Kontakte bei ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    'account-manager': `Kundenbetreuer`,
    'customer-success-manager': `Customer Success Manager`,
    'click-chat-icon-msg': `Klicken Sie auf das Chat-Symbol, um eine Unterhaltung zu beginnen`,
  },
  images: {
    'datacentre-logo': `Rechenzentrum-Logo`,
    'megaport-logo': `${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Logo`,
    'megaport-cloud-providers-logo': `${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Cloudanbieter`,
    'loading-logo': `${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Ladelogo`,
    'role-icon': `Rollensymbol`,
    'docs-icon': `Symbol für Dokumentation`,
    'company-logo': `Firmenlogo`,
    'resize-handle-icon': `Größe des Handle-Symbols ändern`,
    'marketplace-logo': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Logo`,
    'marketplace-profile-logo': `${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE}-Profillogo`,
    'provider-logo': `Anbieterlogo`,
    'copy-icon': `Symbol für Wert kopieren`,
    'partner-vantage-logo': `Partner Vantage-Logo`,
    'profile-logo': `Profillogo`,
    'sx-logo': `Logo für Dienstaustausch`,
  },
  ibm: {
    connection: `IBM-Verbindung`,
    'connection-details': `Verbindungsdaten für IBM Cloud-Service`,
    'account-id': `IBM Konto-ID`,
    'account-id-tooltip': `Diese erhalten Sie über Ihre IBM-Kontoeinstellungen im IBM-Portal`,
    'customer-asn-tooltip': `Öffentliche oder private BGP-ASN`,
    'account-id-validation': `32 stellige Hexadezimalzahl`,
    'modal-title': `IBM Portal-Anbindung`,
    'order-request-sent': `Die Anfrage wurde an Ihr IBM-Portalkonto gesendet.`,
    'order-request-reminder': `Dies ist eine Erinnerung daran, die Verbindungsanfrage über das IBM-Portal anzunehmen oder abzulehnen.`,
    'visit-portal': `IBM-Portal besuchen`,
    'edit-modal-updated-api': `${process.env.VUE_APP_EN_COMPANYINFO_NAME} hat die API-Integrationen mit IBM aktualisiert.`,
    'edit-modal-recommendation': `Wir empfehlen Benutzern, eine IBM Cloud ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} neu zu bestellen, um auf aktualisierte Funktionen zuzugreifen und die bestehende Verbindung zu beenden.`,
    'edit-modal-docs': `Weitere Informationen finden Sie unter {link}.`,
  },
  'mexico-billing-market': {
    'company-type-mexico-01-legal-entity': `01: Rechtsträger`,
    'company-type-mexico-02-legal-person': `02: juristische Person`,
    'company-type-mexico-03-foreign-company': `03: ausländisches Unternehmen`,
    'tax-regime-code-mexico-601': `601 – Juristische Personen nach allgemeinem Recht`,
    'tax-regime-code-mexico-603': `603 – Non-Profit-Rechtsträger`,
    'tax-regime-code-mexico-605': `605 – Löhne und Gehälter und Löhnen gleichgestellte Einkommen`,
    'tax-regime-code-mexico-606': `606 – Pacht`,
    'tax-regime-code-mexico-607': `607 – Regelung zur Veräußerung oder zum Erwerb von Gütern`,
    'tax-regime-code-mexico-608': `608 – Sonstige Einnahmen`,
    'tax-regime-code-mexico-610': `610 – Einwohner im Ausland ohne ständige Niederlassung in Mexiko`,
    'tax-regime-code-mexico-611': `611 – Dividendeneinkünfte (Gesellschafter und Aktionäre)`,
    'tax-regime-code-mexico-612': `612 – Einzelpersonen mit geschäftlichen und beruflichen Aktivitäten`,
    'tax-regime-code-mexico-614': `614 – Zinserträge`,
    'tax-regime-code-mexico-615': `615 – Regelung für Einkünfte aus dem Erhalt von Preisen`,
    'tax-regime-code-mexico-616': `616 – Keine steuerlichen Verpflichtungen`,
    'tax-regime-code-mexico-620': `620 – Produktionsgenossenschaften, die sich dafür entscheiden, ihr Einkommen zu stunden`,
    'tax-regime-code-mexico-621': `621 – Steuerliche Aufnahme`,
    'tax-regime-code-mexico-622': `622 – Landwirtschaft, Viehzucht, Forstwirtschaft und Fischerei`,
    'tax-regime-code-mexico-623': `623 – Fakultativ für Unternehmensgruppen`,
    'tax-regime-code-mexico-624': `624 – Koordiniert`,
    'tax-regime-code-mexico-625': `625 – Regelung von Geschäftstätigkeiten mit Einkommen durch technologische Plattformen`,
    'tax-regime-code-mexico-626': `626 – Vereinfachte Treuhandregelung`,
    'sat-purpose-mexico-cn01': `CN01 – Gehaltsabrechnung`,
    'sat-purpose-mexico-cp01': `CP01 – Zahlungen`,
    'sat-purpose-mexico-d01': `D01 – Arzt-, Zahnarzt- und Krankenhauskosten`,
    'sat-purpose-mexico-d02': `D02 – Krankheitskosten aufgrund von Arbeitsunfähigkeit oder Behinderung`,
    'sat-purpose-mexico-d03': `D03 – Beerdigungskosten`,
    'sat-purpose-mexico-d04': `D04 – Schenkungen`,
    'sat-purpose-mexico-d05': `D05 – Effektiv gezahlte Realzinsen für Hypothekendarlehen (Wohnhaus)`,
    'sat-purpose-mexico-d06': `D06 – Freiwillige Beiträge zum SAR`,
    'sat-purpose-mexico-d07': `D07 – Versicherungsprämien für Krankheitskosten`,
    'sat-purpose-mexico-d08': `D08 – Kosten für obligatorische Schülerbeförderung`,
    'sat-purpose-mexico-d09': `D09 – Einlagen auf Sparkonten, Prämien auf der Grundlage von Pensionsplänen`,
    'sat-purpose-mexico-d10': `D10 – Zahlungen für Bildungsdienstleistungen (Schulgeld)`,
    'sat-purpose-mexico-g01': `G01 – Erwerb von Handelswaren`,
    'sat-purpose-mexico-g02': `G02 – Renditen, Rabatte oder Boni`,
    'sat-purpose-mexico-g03': `G03 – Ausgaben im Allgemeinen`,
    'sat-purpose-mexico-i01': `I01 – Gebäude`,
    'sat-purpose-mexico-i02': `I02 – Betriebs- und Geschäftsausstattung für Investitionen`,
    'sat-purpose-mexico-i03': `I03 – Transportmittel`,
    'sat-purpose-mexico-i04': `I04 – Computerausrüstung und -zubehör`,
    'sat-purpose-mexico-i05': `I05 – Matrizen, Gesenke, Formen, Gesenk- und Werkzeugbau`,
    'sat-purpose-mexico-i06': `I06 – Telefonische Kommunikation`,
    'sat-purpose-mexico-i07': `I07 – Satellitenkommunikation`,
    'sat-purpose-mexico-i08': `I08 – Sonstige Maschinen und Anlagen`,
    'sat-purpose-mexico-s01': `S01 – Keine steuerlichen Auswirkungen (keine steuerlichen Verpflichtungen)`,
  },
  'survey-banner': {
    'customer-survey-link': `Wir würden uns über Ihr Feedback freuen! Klicken Sie hier, um unsere Kundenumfrage auszufüllen.`,
  },
  'mp-one': {
    'platform-orchestrate-network-services': `EINE Plattform zur Orchestrierung, Vernetzung und Aktivierung von Clouddiensten.`,
    'try-megaport-one': `Probieren Sie Megaport ONE aus`,
    'learn-more': `Mehr erfahren`,
    'click-here-to-try-mp-one': `Klicken Sie hier, um Megaport ONE Beta zu testen. Eine Plattform zur Orchestrierung, Verbindung und Einführung von Cloudlösungen.`,
    'terms-and-conditions': `Sie betreten jetzt das MegaportONE-Portal.nnMit dem Betreten des MegaportONE-Portals erklären Sie sich mit den folgenden Bedingungen einverstanden.nnDie im MegaportONE-Portal bereitgestellten Informationen und Dienste stehen den Benutzern derzeit kostenlos zur Verfügung.nnMegaport behält sich das Recht vor, in Zukunft Gebühren oder Kosten für bestimmte Funktionen oder Dienste einzuführen.nnSolche Gebühren oder Kosten werden den Nutzern im Voraus klar mitgeteilt.nnBitte beachten Sie, dass sich das MegaportONE-Portal derzeit in der Betaphase befindet und möglicherweise nicht alle Funktionen oder Dienste jederzeit verfügbar sind.nnMegaport behält sich das Recht vor, Teile der Website oder ihre Funktionalität ohne vorherige Ankündigung zu ändern, auszusetzen oder einzustellen.nnWir garantieren keinen ununterbrochenen oder fehlerfreien Zugang zum MegaportONE-Portal.`,
  },
  'view-mode': {
    tooltip: `Zeigen Sie Ihre Dienste in der tabellarischen oder der Kartenansicht an`,
    tabular: `Tabellarisch`,
    map: `Karte`,
  },
  map: {
    'all-locations': `Alle Standorte anzeigen`,
    'occupied-locations': `Nur Orte anzeigen, an denen ich Dienste habe`,
    'filtered-locations': `Anzeige von Orten filtern, an denen ich keine Dienste habe`,
    'thing-available': `{thing} verfügbar`,
    'dc-provider': `Anbieter von Rechenzentren`,
    'locations-filtered': `Die angezeigten Orte werden gefiltert`,
    'zoom-in': `Vergrößern`,
    'zoom-in-lots': `Stark vergrößern`,
    'zoom-out': `Verkleinern`,
    'zoom-out-lots': `Stark verkleinern`,
    settings: `Einstellungen`,
    key: `Kartenlegende`,
    help: `Hilfe und Anleitung`,
    'help-action': `Aktion`,
    'help-description': `Beschreibung`,
    'help-items': {
      'zoom-in-title': `Vergrößern`,
      'zoom-in-action-1': `Doppelklick`,
      'zoom-in-action-2': `= Taste`,
      'zoom-in-action-3': `+ Taste`,
      'zoom-in-action-4': `⌘/CTRL-Taste und Mausrad gedrückt halten`,
      'zoom-in-action-5': `Alt-/Optionstaste gedrückt halten und nach unten ziehen`,
      'zoom-in-action-6': `Greifen`,
      'double-zoom-in-title': `Vergrößern mit doppelter Geschwindigkeit`,
      'double-zoom-in-action-1': `Umschalttaste und = Taste`,
      'double-zoom-in-action-2': `Umschalttaste und + Taste`,
      'zoom-out-title': `Verkleinern`,
      'zoom-out-action-1': `Doppelklick bei gedrückter Umschalttaste`,
      'zoom-out-action-2': `- Taste`,
      'zoom-out-action-3': `⌘/CTRL-Taste und Mausrad gedrückt halten`,
      'zoom-out-action-4': `Alt-/Optionstaste gedrückt halten und nach oben ziehen`,
      'zoom-out-action-5': `Greifen`,
      'double-zoom-out-title': `Verkleinern mit doppelter Geschwindigkeit`,
      'double-zoom-out-action-1': `Umschalttaste und - Taste`,
      'area-zoom-in-title': `Vergrößern eines Bereichs`,
      'area-zoom-in-action-1': `Umschalttaste und Bereich ziehen`,
      'pan-title': `Karte schwenken`,
      'pan-action-1': `Ziehen`,
      'pan-action-2': `Pfeiltasten`,
      'orientation-title': `Kartenausrichtung ändern`,
      'orientation-action-1': `Ziehen mit zwei Fingern`,
      'orientation-action-2': `Umschalttaste + ←/→ zum Drehen; Umschalttaste + ↑ zum Zurücksetzen`,
    },
    'ix-count': `0 IXs | 1 IX | {count} IXs`,
    'location-cluster': `Dieser Cluster repräsentiert {locationString}.`,
    'location-ix-cluster': `Dieser Cluster repräsentiert {locationString} und {ixString}.`,
    'click-expand': `Klicken Sie zum Einblenden.`,
    'click-empty-location': `Klicken Sie hier, um Details zu sehen und Dienste hinzuzufügen.`,
    'supported-products': `Dieser Standort unterstützt die folgenden Produkte:`,
    'products-location': `Sie haben die folgenden Produkte an diesem Standort:`,
    'click-details': `Klicken Sie hier, um Details zu sehen.`,
    'line-connections-count': `- | Diese Linie stellt eine 1 Verbindung dar: | Diese Linie stellt {count} Verbindungen dar`,
    'location-deployment': `Dieser Standort wird bald online gehen. Sie können Ihre Dienste jetzt vorbestellen.`,
    'view-in-map': `Diesen Dienst auf einer Karte anzeigen`,
    'navigate-to': `Navigieren Sie zu {serviceName}`,
    'search-tips': `Sie können suchen nach:<ul><li>Beliebiger Produkttyp, z. B. VXC</li><li>Beliebiger Dienstname</li><li>Produkt-ID</li><li>Megaport-fähige Standorte (wenn Sie dies in Ihren Karteneinstellungen nicht deaktiviert haben)</li><li>Oder jeder andere Ort auf der Welt</li></ul>`,
    'key-items': {
      'occupied-location': `Ein einziger Standort, an dem Sie Dienste haben. Sie können auf diese Stecknadeln klicken, um Details zu den Diensten zu sehen, die Sie an diesem Ort haben, und um dort zusätzliche Dienste zu bestellen.`,
      spiderified: `Wenn Sie auf einen Ort klicken, an dem Sie Dienste haben, werden alle ${process.env.VUE_APP_EN_PRODUCTNAME_PORTS}, ${process.env.VUE_APP_EN_PRODUCTNAME_MCRS} und ${process.env.VUE_APP_EN_PRODUCTNAME_MVES}, die Sie an diesem Ort haben, sowie der Ort selbst angezeigt. Die Symbole sind farblich gekennzeichnet und zeigen an, ob der Dienst aktiviert oder deaktiviert ist. Ports von Drittanbietern, mit denen Sie verbunden sind, werden mit schwarzen Symbolen dargestellt. Klicken Sie auf ein Symbol, um die Details zu sehen.`,
      'occupied-location-cluster': `Eine Ansammlung von Standorten, an denen Sie Dienste haben. Die Farbe des Rings zeigt an, welcher Anteil der Verbindungen, die in dem Cluster beginnen, sich im Aufwärts- oder Abwärtszustand befindet. Klicken Sie zum Einblenden.`,
      'unoccupied-location': `Ein einzelner Standort, an dem ${process.env.VUE_APP_EN_COMPANYINFO_NAME}-Dienste verfügbar sind, an dem Sie derzeit aber keine Dienste haben. Klicken Sie hier, um Einzelheiten über den Standort zu erfahren und dort Dienste zu bestellen. Sie können die Anzeige unbesetzter Orte in den Karteneinstellungen deaktivieren.`,
      'unoccupied-location-cluster': `Eine Ansammlung von Orten, an denen Sie keine Dienste haben. Klicken Sie zum Einblenden. Sie können die Anzeige unbesetzter Orte in den Karteneinstellungen deaktivieren.`,
      'ix-pin': `Eine virtuelle Stecknadel, die einen ${process.env.VUE_APP_EN_PRODUCTNAME_IX} in diesem Metrogebiet darstellt.`,
      'mid-connection': `Eine Markierung, die Verbindungen zwischen zwei Orten oder Ortsclustern darstellt. Eine einzige Zeile kann mehrere Verbindungen darstellen. Die Markierung ist grün, wenn alle Verbindungen aktiv sind, rot, wenn alle Verbindungen nicht aktiv sind, und halb und halb, wenn nur einige aktiv sind. Klicken Sie auf den Marker, um alle dargestellten Verbindungen zu sehen.`,
      connections: `Die Linien zwischen den Orten stellen Verbindungen dar. Die Farbe der Linie zeigt an, ob die Verbindung hergestellt oder unterbrochen ist. In diesem Fall gibt es drei Aufwärtsverbindungen und eine Abwärtsverbindung.`,
    },
    'partner-count': `– | Sie haben 1 ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Verbindung von einem Drittanbieter an diesem Standort. | Sie haben {count} ${process.env.VUE_APP_EN_PRODUCTNAME_PORT}-Verbindungen von Drittanbietern an diesem Standort.`,
    'ix-incoming-count': `– | Sie haben 1 eingehende ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Verbindung. | Sie haben {count} eingehende ${process.env.VUE_APP_EN_PRODUCTNAME_IX}-Verbindungen.`,
    fullscreen: `Vollbildmodus`,
    big: `Große Anzeige (Dienst-Widgets nicht sichtbar)`,
    small: `Kleine Anzeige (Dienst-Widgets sichtbar)`,
    'fullscreen-error-title': `Vollbildmodus konnte nicht aktiviert werden`,
    'fullscreen-error-message': `Fehler beim Versuch, den Vollbildmodus zu aktivieren: {errorMessage} ({errorName})`,
    'not-ready-title': `Karte nicht bereit`,
    'not-ready-message': `Einige Kartenelemente werden noch geladen. Bitte versuchen Sie es in ein paar Sekunden erneut.`,
    'initialization-error': `Die Karte der Dienste kann nicht angezeigt werden: {message}`,
    globe: `Ansicht als Globus`,
    mercator: `Ansicht als flache (Mercator-)Projektion`,
  },
  'cancel-reason': {
    label: `Grund der Stornierung`,
    'moved-dc': `Auszug aus dem Rechenzentrum`,
    'order-error': `Irrtümlich bestellt oder Nachbestellung`,
    'switch-provider': `Wechsel zu einem anderen Anbieter`,
    'proof-of-concept': `Proof of Concept / Test`,
    other: `Grund für Nichtauflistung`,
    'project-ended': `Projekt beendet`,
    'cloud-native-move': `Umstellung auf eine Cloud-native Lösung`,
    pricing: `Preisgestaltung`,
    'product-performance': `Produktleistung`,
    'feedback-prompt': `Unterstützen Sie uns bei unseren Bemühungen um ein Verbesserung unseres Angebots, indem Sie weitere Details zu Ihrem Kündigungsgrund angeben`,
  },
  entitlements: {
    description: `Auf dieser Seite werden AWS-Berechtigungen angezeigt, die über den AWS-Marktplatz erworben wurden. Sie können diese Liste filtern, um abgelaufene Berechtigungen anzuzeigen oder auszublenden. Beachten Sie, dass nur Berechtigungen mit Status {inUse} mit einem Dienst verbunden sind.`,
    'in-use': `In Gebrauch`,
    loading: `Berechtigungen werden geladen`,
    'no-match': `Keine passenden Berechtigungen`,
    'service-id': `Dienst-ID`,
    'agreement-id': `AWS-Vereinbarungs-ID`,
    'vendor-agreement-id': `Lieferantenvereinbarungs-ID`,
    'vendor-account-id': `Anbieterkonto-ID`,
    sku: `SKU`,
    'start-date': `Datum des Vertragsbeginns`,
    'end-date': `Datum des Vertragsendes`,
    status: `Status`,
    'product-type': `Produkttyp`,
    failed: `Laden der Berechtigungen fehlgeschlagen`,
    available: `Verfügbar`,
    expired: `Abgelaufen`,
  },
  'service-status': {
    maintenance: `Wartung`,
    outages: `Ausfälle`,
    timezone: `Zeitzone`,
    sort: `Sortieren`,
    filter: `Filter`,
    'search-event-placeholder': `Text zum Filtern der Ergebnisse eingeben`,
    status: `Status`,
    'start-date': `Datum des Beginns`,
    ascending: `Aufsteigend`,
    descending: `Absteigend`,
    ongoing: `Aktiv`,
    scheduled: `Geplant`,
    completed: `Abgeschlossen`,
    cancelled: `Abgebrochen`,
    resolved: `Gelöst`,
    'in-last-7-days': `in den letzten 7 Tagen`,
    'no-data-text': `Es wurden keine Ergebnisse gefunden, die den ausgewählten Kriterien entsprechen. Versuchen Sie, Ihre Filterkriterien anzupassen.`,
    'start-utc': `Start (UTC):`,
    'end-utc': `Ende (UTC):`,
    duration: `Dauer:`,
    'resolution-details': `Details zur Lösung`,
    'root-cause': `Ursache:`,
    resolution: `Lösung:`,
    'mitigation-actions': `Abhilfemaßnahmen:`,
    'update-notifications': `Update-Benachrichtigungen`,
    'impacted-services': `Betroffene Dienste`,
    'hide-updates': `Vorherige Updates ausblenden`,
    'show-updates': `Vorherige Updates anzeigen`,
    more: `Mehr anzeigen`,
    less: `Weniger anzeigen`,
    'incident-notification': `Vorfallbenachrichtigung`,
    'status-update': `Weiter – Vorfallbenachrichtigung`,
    'resolved-status': `Gelöst – Vorfallbenachrichtigung`,
    'latest-status': `Neuester – Vorfallbenachrichtigung`,
    'next-update-etr': `Nächstes Update/ETR:`,
    'notification-description': `Beachten Sie, dass Ihr Dienst derzeit von einer Störung betroffen ist. Wir arbeiten aktiv an der Wiederherstellung der Dienste, was für uns höchste Priorität hat.`,
    'next-update-text': `Updates werden bereitgestellt, sobald sie verfügbar sind. Wir danken Ihnen für Ihre Geduld, während wir an der Behebung dieses Ausfalls arbeiten.`,
    'maintenance-outages': `Wartung und Ausfälle`,
    'refresh-error': `Ereignisse können nicht aktualisiert werden`,
    'type-label': `Typ`,
    'name-label': `Name`,
    'id-label': `ID`,
    'actions-label': `Aktionen`,
    details: `Details`,
    map: `Karte`,
  },
}
