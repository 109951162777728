import posthog from 'posthog-js'
import captureSentryError from '@/utils/CaptureSentryError.js'

/**
 * Has this functionality been disabled by a compilation setting? Make sure to always check this variable directly so
 * that function bodies can be correctly omitted by code minifier.
 */
const INTEGRATION_DISABLED = process.env.VUE_APP_DISABLE_INTEGRATION_POST_HOG === 'true'

class PostHog {
  #disabled = false
  #token = ''
  #host = ''
  #autocapture = false

  /**
   * Sets up the PostHog integration
   * @param {Object} appConfiguration The environment settings loaded from environment.js
   */
  init(appConfiguration) {
    if (INTEGRATION_DISABLED) return

    if (appConfiguration.postHog) {
      const { token, host, autocapture } = appConfiguration.postHog
      this.#token = token
      this.#host = host
      if (autocapture != null) this.#autocapture = autocapture
    } else {
      this.#disabled = true
    }
  }

  /**
   * Function for associating a user with the current PostHog session
   * @param {Object} payload The credentials payload returned from authenticating with API-SDK
   */
  setUser(payload) {
    if (INTEGRATION_DISABLED || this.#disabled) return

    try {
      posthog.init(this.#token, {
        api_host: this.#host,
        autocapture: this.#autocapture,
        cross_subdomain_cookie: false,
        disable_session_recording: true,
        advanced_disable_feature_flags: true,
      })
      
    } catch (error) {
      // In the unlikely event of an error, log it as a sentry error, and set posthog to disabled
      captureSentryError(error)
      this.#disabled = true
      return
    }

    const { username, companyUid, companyName, accountType } = payload
    posthog.identify(username, { companyUid, companyName, accountType })
  }

  /**
   * Function for associating arbitrary data with a user. The data will be persisted against that user
   * @param {Object} payload 
   */
  setUserData(payload) {
    if (INTEGRATION_DISABLED || this.#disabled) return
    
    posthog.register(payload)
  }

  /**
   * Function for capturing a custom event that will be recorded against the user in PostHog
   * @param {string} event The code of the event
   * @param {Object} [payload] An optional payload containing an object of key/value pairs to be associated with the event
   */
  captureEvent(event, payload) {
    if (INTEGRATION_DISABLED || this.#disabled) return

    posthog.capture(event, payload)
  }

  /** Ends the current PostHog session for the user */
  clearUser() {
    if (INTEGRATION_DISABLED || this.#disabled) return

    posthog.reset()
  }
}

export default new PostHog()
