import * as globals from '@/Globals.js'

// Do this once, outside the data method so it doesn't need to recalculate.
const filteredGlobals = {}
const keys = Object.keys(globals).filter(key => key.startsWith('G_'))
for (const key of keys) {
  filteredGlobals[key] = globals[key]
}

// Package as a plugin to ensure that it's only defined once as per the Vue mixin guide.
const install = Vue => {
  Vue.mixin({
    data() {
      return filteredGlobals
    },
  })
}

export default install
