import captureSentryError from '@/utils/CaptureSentryError.js'
import store from '@/store/Store.js'

/**
 * Has this functionality been disabled by a compilation setting? Make sure to always check this variable directly so
 * that function bodies can be correctly omitted by code minifier.
 */
const INTEGRATION_DISABLED = process.env.VUE_APP_DISABLE_INTEGRATION_SALESFORCE_CHAT === 'true'

class SalesforceChat {
  #disabled = false
  #readyResolve = null
  #readyPromise = new Promise(resolve => { this.#readyResolve = resolve })

  #getJWT() {
    return store.getters['Auth/accessToken'] || localStorage.getItem('_accessToken')
  }

  #setIdentityToken() {
    const JWT = this.#getJWT()
    if (!JWT) return false
    window.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
      identityTokenType: 'JWT',
      identityToken: this.#getJWT(),
    })
    return true
  }

  /**
   * Initialises the Salesforce embedded messaging integration.
   *
   * @param {Object} appConfig - The application configuration.
   * @returns {Promise<void>} - A promise that resolves when the initialization is complete.
   */
  async init(appConfig) {
    if (INTEGRATION_DISABLED) return

    // Event handler for when the system is ready
    window.addEventListener('onEmbeddedMessagingReady', () => {
      this.#setIdentityToken()
      this.#readyResolve()
    })

    // Event handler for when the identity token expires
    window.addEventListener('onEmbeddedMessagingIdentityTokenExpired', () => {
      if (!this.#setIdentityToken()) {
        this.logout()
      }
    })

    const script = document.createElement('script')
    script.src = `https://${appConfig.salesforceEmbeddedMessaging.subdomain}.my.site.com/${appConfig.salesforceEmbeddedMessaging.deploymentId}/assets/js/bootstrap.js`
    script.async = true
    script.defer = true
    script.onload = () => {
      try {
        window.embeddedservice_bootstrap.settings.language = 'en_US' // For example, enter 'en' or 'en-US'

        window.embeddedservice_bootstrap.init(
          appConfig.salesforceEmbeddedMessaging.orgId,
          appConfig.salesforceEmbeddedMessaging.deploymentName,
          `https://${appConfig.salesforceEmbeddedMessaging.subdomain}.my.site.com/${appConfig.salesforceEmbeddedMessaging.deploymentId}`,
          {
            scrt2URL: `https://${appConfig.salesforceEmbeddedMessaging.subdomain}.my.salesforce-scrt.com`,
          }
        )
      } catch (err) {
        // In the unlikely event of an error, log it as a sentry error, and set the chat as disabled
        captureSentryError(err)
        this.#disabled = true
        this.#readyResolve()
      }
    }
    script.onerror = err => {
      // In the unlikely event of an error, log it as a sentry error, and set the chat as disabled
      captureSentryError(err)
      this.#disabled = true
      this.#readyResolve()
    }

    document.body.appendChild(script)
    await this.#readyPromise
    this.setHidden(true)
  }

  /**
   * Sets up the chat with the provided credentials. The credentials must include a JWT signed using RS256
   *
   * @param {object} credentials - The credentials needed for setup.
   */
  async setup(credentials) {
    if (INTEGRATION_DISABLED) return

    await this.#readyPromise
    if (this.#disabled) return

    this.#setIdentityToken()

    window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
      t_Email: credentials.email,
    })
    this.setHidden(false)
  }

  /**
   * Sets the visibility of the chat button.
   * @param {boolean} hidden - Indicates whether the chat button should be hidden or shown.
   */
  async setHidden(hidden) {
    if (INTEGRATION_DISABLED) return
    
    await this.#readyPromise
    if (this.#disabled) return

    if (hidden) {
      window.embeddedservice_bootstrap.utilAPI.hideChatButton()
    } else {
      window.embeddedservice_bootstrap.utilAPI.showChatButton()
    }
  }

  /**
   * Logs out the user and clears the session.
   */
  async logout() {
    if (INTEGRATION_DISABLED) return
    
    await this.#readyPromise
    if (this.#disabled) return

    window.embeddedservice_bootstrap.userVerificationAPI.clearSession()
      .catch(err => {
        captureSentryError(err)
      })
      .finally(() => {
        this.setHidden(true)
      })
  }
}

export default SalesforceChat
