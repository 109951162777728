import captureSentryError from '@/utils/CaptureSentryError.js'
import sdk from '@megaport/api-sdk'

// Initial state
const coreState = {
  notificationPreferences: {},
}

const getters = {
  getUserNotification: state => destinationName => {
    return state.notificationPreferences[destinationName]
  },
}

const actions = {
  loadPreferences(context) {
    sdk.instance.notificationPreferences()
      .get()
      .then(res => {
        context.commit('setNotificationPreferences', res)
      })
      .catch(e => {
        captureSentryError(e)
      })
  },
  updatePreferences(context, payload) {
    const destinationTitle = payload.destinations.destinationTitle || ''
    delete payload.destinations.destinationTitle
    return new Promise((resolve, reject) => {
      sdk.instance.notificationPreferences()
        .update(payload)
        .then(res => {
          context.commit('Notifications/notifyGood', {
              title: `${destinationTitle} notifications successfully updated.`,
              message: res.message,
            }, {
              root: true,
            })

          return resolve()
        })
        .catch(err => {
          console.warn(err)
          context.commit('Notifications/notifyBad', {
              title: `${destinationTitle} notifications update failed.`,
              message: err.message,
            }, {
              root: true,
            })

          return reject(err)
        })
    })
  },
}

const mutations = {
  logout(state) {
    state.notificationPreferences = {}
  },
  setNotificationPreferences(state, payload) {
    state.notificationPreferences = payload
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
