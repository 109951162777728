import Vue from 'vue'
import { deepClone } from '@/helpers.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { randomNumber } from '@/utils/RandomNumber.js'
import sdk from '@megaport/api-sdk'

// Initial state
const coreState = {
  marketplaceData: [],
  loadMarketplaceProfilesTimer: null,
}

const coreGetters = {
  marketplaceProfile(state, getters, rootState) {
    if (rootState.Company.data && rootState.Company.data.companyUid && state.marketplaceData) {
      return state.marketplaceData.find(p => rootState.Company.data.companyUid === p.companyUid)
    }
    return null
  },
}

const actions = {
  /**
   * Load all the marketplace profiles, and as a side effect, load my own profile.
   *
   * @param {object} context (store context)
   */
  loadMarketplaceProfiles(context) {
    context.commit('updateData', [])

    return new Promise(resolve => {
      sdk.instance
        .marketplace()
        .get(true)
        .then(t => {
          const newData = t
            .filter(profile => {
              if (!profile.services) return false
              if (!profile.companyName) return false
              return true
            })
            .map(profile => {
              profile.logoImage = 'url(/fallback-images/mp-placeholder.png)'
              profile.logo = '/fallback-images/mp-placeholder.png'
              if (profile.image === 'true') {
                const suffix = `?r=${randomNumber()}`
                const companyImageAPI = this._vm.$appConfiguration.marketplaceMediaUrl
                profile.logoImage = `url(${companyImageAPI}/${profile.companyUid}${suffix})`
                profile.logo = `${companyImageAPI}/${profile.companyUid}${suffix}`
              }
              // strip out surrounding quotes
              if (profile.companyBio && profile.companyBio.charAt(0) === '"' && profile.companyBio.charAt(profile.companyBio.length - 1) === '"')
                profile.companyBio = profile.companyBio.slice(1, -1)

              return profile
            })
          context.commit('updateData', newData)
          context.dispatch('getMarketplaceProfile')

          const timerId = setTimeout(() => {
            context.dispatch('loadMarketplaceProfiles')
          }, 60 * 60 * 4 * 1000)
          context.commit('updateLoadMarketplaceProfilesTimer', timerId)
          return resolve()
        })
        .catch(e => {
          captureSentryError(e)
        })
    })
  },
  /**
   * Load the marketplace profile for the current user.
   *
   * @param {object} context (store context)
   */
  getMarketplaceProfile(context) {
    sdk.instance
      .marketplace()
      .profile()
      .then(profile => {
        // ensure the user's own profile is always in the marketplace
        if (!profile || !profile.companyName) return
        profile.logoImage = 'url(/fallback-images/mp-placeholder.png)'
        profile.logo = '/fallback-images/mp-placeholder.png'
        if (profile.image === 'true') {
          const suffix = `?r=${randomNumber()}`
          const companyImageAPI = this._vm.$appConfiguration.marketplaceMediaUrl
          profile.logoImage = `url(${companyImageAPI}/${profile.companyUid}${suffix})`
          profile.logo = `${companyImageAPI}/${profile.companyUid}${suffix}`
        }
        if (!profile.services) {
          profile.services = {}
        }

        const index = context.state.marketplaceData.findIndex(p => profile.companyUid === p.companyUid)

        if (index === -1) {
          context.commit('appendProfile', profile)
        } else {
          // Make sure the data we have is up to date.
          context.commit('updateProfile', {
            index,
            profile,
          })
        }
        window.mpApp.$emit('marketplace-profile-loaded', true)
      })
      .catch(err => {
        let success = false
        if (err.status === 404) {
          // No profile found for company is still a valid success result
          success = true
        }
        window.mpApp.$emit('marketplace-profile-loaded', success)
      })
  },
  /**
   *
   * @param {object} context (store context)
   * @param {object} payload - contains both the profile and services
   */
  updateMarketplaceProfile(context, { profile, services }) {
    // Make sure it's a clean object
    const workingProfile = deepClone(profile)

    delete workingProfile.services
    delete workingProfile.logoImage
    delete workingProfile.logo
    if (workingProfile.image === 'true') {
      delete workingProfile.image
    }

    // Check to make sure we are polluting data with hidden services.
    const workingServices = services.filter(s => {
      return s.portInfo.marketplaceVisibility
    })

    if (workingServices.length > 0) {
      workingProfile.services = {}
      workingServices.forEach(s => {
        if (!s.portInfo.productUid || s.portInfo.provisioningStatus === this.G_PROVISIONING_DESIGN) return

        workingProfile.services[s.portInfo.productUid] = {
          contactEmail: s.contactEmail,
          description: s.description,
          metadata: s.metadata,
          serviceTypes: s.serviceTypes,
          title: s.title,
        }
      })
    }

    sdk.instance
      .marketplace()
      .updateProfile(workingProfile)
      .then(res => {
        const index = context.state.marketplaceData.findIndex(p => res.companyUid === p.companyUid)
        if (index !== -1) {
          context.commit('updateProfile', {
            index,
            profile: res,
          })
        } else {
          context.commit('appendProfile', workingProfile)
        }

        context.commit(
          'Notifications/notifyGood',
          {
            title: window.mpApp.$t('general.success-updating', { thing: window.mpApp.$t('menu.marketplace-profile') }),
            message: window.mpApp.$t('marketplace.company-profile', { company: workingProfile.companyName }),
          },
          {
            root: true,
          }
        )

        if (context.rootState.Company.data && context.rootState.Company.data.companyUid === res.companyUid) {
          context.dispatch('getMarketplaceProfile')
        }
        window.mpApp.$emit('marketplace-profile-updated', true)
      })
      .catch(err => {
        // TODO: Improve error processing
        context.commit(
          'Notifications/notifyBad',
          {
            title: window.mpApp.$t('general.error-updating', { thing: window.mpApp.$t('menu.marketplace-profile') }),
            message: err.message || err.data.message,
          },
          {
            root: true,
          }
        )
        window.mpApp.$emit('marketplace-profile-updated', false)
      })
  },
}

const mutations = {
  logout(state) {
    state.marketplaceData = []
    if (state.loadMarketplaceProfilesTimer) {
      clearTimeout(state.loadMarketplaceProfilesTimer)
      state.loadMarketplaceProfilesTimer = null
    }
  },
  updateData(state, data) {
    state.marketplaceData = data
  },
  appendProfile(state, profile) {
    state.marketplaceData.push(profile)
  },
  /**
   *
   * @param {object} state - state for this part of the store.
   * @param {*} payload - includes index and profile
   */
  updateProfile(state, payload) {
    const { index, profile } = payload
    const existingData = state.marketplaceData[index]
    Vue.set(state.marketplaceData, index, {
      ...existingData,
      ...profile,
    })
  },
  updateLoadMarketplaceProfilesTimer(state, newValue) {
    state.loadMarketplaceProfilesTimer = newValue
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
