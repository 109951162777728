<template>
  <div class="full-height position-relative d-inline-block width-132px my-0 mx-15-px"
    data-name="loggedin">
    <img src="@/assets/images/megaport-logos/Megaport-Logo-Web-72dpi-RGB-132x32.png"
      :alt="$t('images.megaport-logo')"
      width="132"
      height="31"
      class="position-absolute my-auto top-0 bottom-0">
    <el-tooltip placement="bottom"
      :content="`${apiName} ${$t('general.version')} ${gitVersion}, environment: ${environmentName}, whitelabel: ${whitelabelName}`"
      :open-delay="500">
      <div class="color-text-regular current-env">
        {{ apiName }}
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import gitVersion from '@/gitVersion.js'

export default {
  name: 'MegaportLogo',

  inject: ['whitelabelName'],

  data() {
    return {
      gitVersion,
    }
  },

  computed: {
    apiName() {
      return this.$appConfiguration.apiName
    },
    environmentName() {
      return this.$appConfiguration.environmentName
    },
  },
}
</script>

<style scoped>
.current-env {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.1rem;
}
.mx-15-px {
  margin-inline: 15px;
}
.width-132px {
  width: 132px;
}
</style>
