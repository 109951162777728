import { merge } from 'lodash'

import elementTranslations from 'element-ui/lib/locale/lang/ja'
import baseTranslations from '@/i18n/store/ja.js'


const sources = [elementTranslations, baseTranslations]

if (process.env.VUE_APP_WHITELABEL_FOLDER) {
  const whitelabelFile = `${process.env.VUE_APP_WHITELABEL_FOLDER}/i18n/ja.js`
  try {
    // We must specify the base URL or Webpack won't have the file for us
    const whitelabelTranslations = require(`@/whitelabels/${whitelabelFile}`).default
    sources.push(whitelabelTranslations)
  } catch (error) {
    // It will fail if there is no ja.js file defined for this whitelabel, and that's OK.
  }
}

export default merge({}, ...sources)
