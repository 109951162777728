const buildProviders = {
  whitelabelName: process.env.VUE_APP_WHITELABEL_NAME,
  layout: {
    integratedFooter: process.env.VUE_APP_INTEGRATED_HEADER === 'true',
    integratedHeader: process.env.VUE_APP_INTEGRATED_FOOTER === 'true',
  },
  disabledFeatures: {
    showPrices: process.env.VUE_APP_DISABLE_SHOW_PRICES === 'true',
    billingGraphs: process.env.VUE_APP_DISABLE_BILLING_GRAPHS === 'true',
    promoCodes: process.env.VUE_APP_DISABLE_PROMO_CODES === 'true',

    pusher: process.env.VUE_APP_DISABLE_INTEGRATION_PUSHER === 'true',

    addPort: process.env.VUE_APP_DISABLE_ADD_PORT === 'true',
    addPortToLag: process.env.VUE_APP_DISABLE_ADD_PORT_TO_LAG === 'true',
    locking: process.env.VUE_APP_DISABLE_LOCKING === 'true',
    downloadLoa: process.env.VUE_APP_DISABLE_DOWNLOAD_LOA === 'true',

    // If configuredServices is disabled, not only does it not show up in the sidebar,
    // it also implies that it automatically completes the purchase rather than just
    // configuring it. The translations should also reflect the wording you want at
    // the end of the configuration process. It also implies that you can't save
    // configurations either.
    configuredServices: process.env.VUE_APP_DISABLE_CONFIGURED_SERVICES === 'true',

    marketplace: process.env.VUE_APP_DISABLE_MARKETPLACE === 'true',

    dashboard: process.env.VUE_APP_DISABLE_DASHBOARD === 'true',
    knowledgeBase: process.env.VUE_APP_DISABLE_DOCUMENTATION === 'true',
    footer: process.env.VUE_APP_DISABLE_FOOTER === 'true',
    quickConnect: process.env.VUE_APP_DISABLE_QUICKCONNECT === 'true',
    expandedServices: process.env.VUE_APP_DISABLE_EXPANDED_SERVICES === 'true',
    salesforceChat: process.env.VUE_APP_DISABLE_INTEGRATION_SALESFORCE_CHAT === 'true',
    pageTitles: process.env.VUE_APP_DISABLE_TITLE_UPDATE === 'true',
    megaportMarketing: process.env.VUE_APP_DISABLE_MEGAPORT_MARKETING === 'true',

    productAMSIX: process.env.VUE_APP_DISABLE_PRODUCT_AMSIX === 'true',
    productIX: process.env.VUE_APP_DISABLE_PRODUCT_IX === 'true',
    productMegaportInternet: process.env.VUE_APP_DISABLE_PRODUCT_MEGAPORT_INTERNET === 'true',
  },
}

export default buildProviders
