<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t(dialogTitle)"
    :modal-append-to-body="false"
    data-testid="cancellation-dialog"
    width="55%">
    <!-- Dialog Body -->
    <!-- Service still in design with an 'ALL' scope -->
    <div v-if="statusIsDesign && serviceScopeIsAll">
      {{ $t('cancel.clear-configured') }}
    </div>
    <!-- Service still in design -->
    <template v-else-if="statusIsDesign">
      <!-- Confirmation of removal question -->
      <div>{{ $t('cancel.remove-from-configured') }}</div>
      <!-- Service Card -->
      <div class="cancel-service-item">
        <mu-mega-icon :icon="cancellingService.serviceIcon"
          class="service-type-icon" />
        <div>{{ cancellingService.productName }}</div>
      </div>
    </template>
    <!-- Service no longer in design -->
    <template v-else>
      <el-form ref="cancelForm"
        :model="cancelForm"
        :rules="cancelRules">
        <!-- Service is a port and has not been cancelled -->
        <div v-if="statusIsNotCancelled"
          class="mb-1">
          <el-form-item prop="cancelNow"
            class="cancel-content">
            <el-radio-group v-if="serviceIsPort"
              v-model="cancelForm.cancelNow"
              name="terminate-services-now-later">
              <!-- Later termination available -->
              <el-radio-button v-if="statusIsLive"
                :label="false"
                class="inverse-padding"
                data-name="terminateLater">
                <el-tooltip placement="top"
                  :content="$t('cancel.end-of-contract')"
                  :open-delay="500">
                  <span>{{ $t('cancel.later') }}</span>
                </el-tooltip>
              </el-radio-button>
              <el-radio-button :label="true"
                class="inverse-padding"
                data-name="terminateNow">
                <el-tooltip placement="top"
                  :content="$t('cancel.terminate-immediately')"
                  :open-delay="500">
                  <span>{{ $t('cancel.now') }}</span>
                </el-tooltip>
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div>

        <div class="min-height-40px py-0-5">
          <transition name="switch-content"
            mode="out-in">
            <p v-if="cancelForm.cancelNow === true"
              key="cnow"
              class="my-0">
              {{ $t('cancel.now-confirmation') }}
            </p>
            <p v-if="cancelForm.cancelNow === false"
              key="clater"
              class="my-0">
              {{ $t('cancel.later-confirmation') }}
            </p>
          </transition>
        </div>

        <div v-if="loadingCharges"
          v-loading="loadingCharges"
          class="cancel-service-item terminating height-40px" />

        <template v-else>
          <div v-for="(charge, index) in charges"
            :key="`charge-${index}`">
            <div class="cancel-service-item terminating"
              :class="charge.terminateMessage ? 'forbidden' : ''">
              <mu-mega-icon v-if="charge.serviceIcon"
                :icon="charge.serviceIcon"
                class="service-type-icon" />
              <div v-if="charge.productName"
                class="service-content">
                <div>{{ charge.productName }}</div>
                <div class="min-height-2-2rem">
                  <transition name="switch-content"
                    mode="out-in">
                    <div v-if="cancelForm.cancelNow === false"
                      key="cancellater"
                      class="fs-1-3rem">
                      {{ terminateDate(charge.terminationDate) }}
                    </div>
                    <div v-if="cancelForm.cancelNow === true"
                      key="cancelnow"
                      class="fs-1-3rem">
                      {{ $t('cancel.immediate') }}
                    </div>
                  </transition>
                </div>
              </div>
              <el-input v-if="showPricing"
                :value="charge.displayMoney"
                size="small"
                class="service-cancellation"
                data-testid="cancellation-fee"
                disabled />
              <div v-if="charge.terminateMessage"
                class="terminate-forbidden">
                <p class="font-weight-500">
                  {{ $t('cancel.unable') }}
                </p>
                <p>{{ charge.terminateMessage }}</p>
              </div>
            </div>
          </div>
        </template>

        <div v-for="(message, index) in extra"
          :key="`extra-${index}`"
          class="flex-row-centered mt-1">
          <div>
            <i class="fas fa-exclamation-triangle color-warning mr-1 fs-2-5rem"
              aria-hidden="true" />
          </div>
          <div v-html="message" /> <!-- eslint-disable-line vue/no-v-html -->
        </div>

        <el-form-item prop="reason"
          :label="$t('cancel-reason.label')"
          label-width="200px"
          class="mt-3">
          <el-select v-model="cancelForm.reason"
            class="width-4/5"
            data-testid="select-cancellation-reason">
            <el-option v-for="reason in CANCEL_REASONS"
              :key="reason.code"
              :label="$t(reason.translationPath)"
              :value="reason.code"
              data-testid="cancellation-reason" />
          </el-select>
        </el-form-item>

        <el-form-item prop="comment"
          :label="$t('general.comments')"
          label-width="200px">
          <el-input v-model="cancelForm.comment"
            type="textarea"
            :rows="5"
            class="width-4/5"
            data-testid="cancellation-comments" />
        </el-form-item>
        <p class="feedback-prompt">
          {{ $t('cancel-reason.feedback-prompt') }}
        </p>
      </el-form>
    </template>

    <!-- Dialog Footer -->
    <template v-if="statusIsDesign"
      #footer>
      <el-button data-testid="keep-configured-button"
        @click="setVisible(false)">
        {{ $t(serviceScopeIsAll ? 'cancel.keep-configured-services' : 'cancel.keep-configured-service') }}
      </el-button>
      <el-button data-testid="remove-configuration-button"
        type="primary"
        @click="cancelServices">
        {{ $t('cancel.remove-configuration') }}
      </el-button>
    </template>
    <template v-else
      #footer>
      <el-button data-testid="keep-services-button"
        @click="setVisible(false)">
        {{ $t('cancel.keep-services') }}
      </el-button>
      <el-button v-if="charges.length > 0 && cancelForm.cancelNow !== null && canTerminate"
        :disabled="cancellingActive"
        data-testid="terminate-services-button"
        type="primary"
        @click="cancelServices">
        {{ $tc('services.terminate-count', charges.length, { count: charges.length }) }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
// External tools
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { moneyFilter, mpDate, deepClone } from '@/helpers.js'
import { CLOUD_ITEMS, CANCEL_REASONS } from '@/Globals.js'
// Integrations
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'

// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  name: 'CancelServices',

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    serviceUid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      cancelForm: {
        cancelNow: null,
        reason: null,
        comment: '',
      },
      cancelRules: {
        cancelNow: {
          required: true,
          message: this.$t('general.please-select'),
          trigger: 'change',
        },
        reason: {
          required: true,
          message: this.$t('validations.please-select', { thing: this.$t('cancel-reason.label') }),
          trigger: 'blur',
        },
        comment: {
          min: 0,
          max: 400,
          message: this.$tc('validations.max-length', 400, { max: 400 }),
        },
      },
      cancellingActive: false,
      nowCharges: [],
      laterCharges: [],
      loadingChargesValue: 0,
      destroyed: false, // Protect against processing the promise response if component is going away
      CANCEL_REASONS,
    }
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapState('Services', ['services']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('Services', ['getServicesById', 'myPorts', 'findPort', 'allServicesUidDictionary']),

    showPricing() {
      return !this.disabledFeatures.showPrices
        && this.isFeatureEnabled('PRICING_VISIBLE')
        && this.cancelForm.cancelNow !== null
        && !this.service?.terminateMessage
    },
    charges() {
      return this.cancelForm.cancelNow ? this.nowCharges : this.laterCharges
    },
    loadingCharges() {
      return this.loadingChargesValue < 2
    },
    cancellingService() {
      // Clearing all in-DESIGN services by clicking on the top level bin icon in the Configured Services sidebar
      if (this.serviceUid === 'DESIGNED') {
        return { provisioningStatus: this.G_PROVISIONING_DESIGN, scope: 'ALL' }
      }

      // Clearing an individual in-DESIGN service
      const service = this.allServicesUidDictionary[this.serviceUid] || false

      if (service) {
        const serviceIcon = service.productType === this.G_PRODUCT_TYPE_VXC ? this.getVxcIconFor(service) : service.productType

        return { ...service, serviceIcon }
      } else {
        return false
      }
    },
    extra() {
      const messages = []
      if (this.cancellingService && [this.G_PRODUCT_TYPE_VXC, this.G_PRODUCT_TYPE_CXC].includes(this.cancellingService.productType)) {
        const b = this.findPort(this.cancellingService.bEnd.productUid)

        if (b && ['ALIBABA', 'AZURE', 'ORACLE', 'NUTANIX'].includes(b.connectType)) {
          messages.push(this.$t('connections.cancel-a-end', { companyName: b.companyName }))
        }
      }
      return messages
    },
    canTerminate() {
      return this.charges.every(charge => !charge.terminateMessage)
    },
    serviceIsPort() {
      return this.cancellingService.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    serviceScopeIsAll() {
      return this.cancellingService.scope === 'ALL'
    },
    serviceStatus() {
      return this.cancellingService?.provisioningStatus
    },
    statusIsDesign() {
      return this.serviceStatus === this.G_PROVISIONING_DESIGN
    },
    statusIsNotCancelled() {
      return this.serviceStatus !== this.G_PROVISIONING_CANCELLED
    },
    statusIsLive() {
      return this.serviceStatus === this.G_PROVISIONING_LIVE
    },
    dialogTitle() {
      return this.statusIsDesign ? 'cancel.remove-configured' : 'cancel.terminate'
    },
    /**
     * Check if any IBM connections are to be cancelled to display modal after
     * cancellation reminding users to confirm connection in IBM portal
     */
    serviceContainsIbm() {
      // Check if we're cancelling an IBM VXC
      // NOTE: The API returns csp_connection as an object when it contains a single connection
      // and as an array when it contains multiple, so we need to check its structure.
      const cspConnection = this.cancellingService.resources?.csp_connection

      const cancelIbmConnection = Array.isArray(cspConnection) ?
        cspConnection.some(({ csp_name }) => csp_name === 'IBM') :
        cspConnection?.csp_name === 'IBM'

      if (cancelIbmConnection) return true

      // Check if we're cancelling a service containing any IBM VXCs
      const ibmCloudItem = CLOUD_ITEMS.find(item => item.title === 'IBM Cloud')

      const cancelIbmService = this.cancellingService.associatedVxcs ?
        this.cancellingService.associatedVxcs.some(({ bEnd: { ownerUid } = {} }) => ibmCloudItem.companyUids.includes(ownerUid)) :
        false

      return cancelIbmService
    },
  },

  mounted() {
    this.cancelForm.cancelNow = this.serviceIsPort ? null : true

    if (this.serviceStatus && !this.statusIsDesign) {
      this.loadCancelNowCharges()
      if (this.statusIsLive) {
        this.loadCancelLaterCharges()
      } else {
        this.loadingChargesValue++
        this.cancelForm.cancelNow = true
      }
    }

    if (!this.statusIsNotCancelled) {
      this.updateCancelNow(true)
    }
  },

  beforeDestroy() {
    this.destroyed = true
  },

  methods: {
    ...mapActions('Services', ['editService', 'clearCart', 'getMyServices', 'fetchServiceOrConnection', 'removeServiceOrConnection']),
    ...mapActions('Notifications', ['showIbmReminderModal']),
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),

    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    updateCancelNow(tf) {
      this.cancelForm.cancelNow = tf
    },
    loadCancelNowCharges() {
      this.loadingChargesValue = 0
      sdk.instance
        .product(this.serviceUid)
        .cancelCharges(true)
        .then(charges => {
          this.nowCharges = this.chargeConcat(charges)
          this.loadingChargesValue++
        })
        .catch(e => {
          if (!this.destroyed) {
            const service = this.allServicesUidDictionary[this.serviceUid] || false

            if (e.status === 409) {
              if (e.data?.message) {
                this.nowCharges = [
                  {
                    terminateMessage: e.data.message,
                    ...service && {
                      productName: service.productName,
                      productType: service.productType,
                    },
                  },
                ]
              }
            } else if (e.status === 403) {
              this.nowCharges = [
                {
                  terminateMessage: this.$t('cancel.no-permission'),
                  ...service && {
                    productName: service.productName,
                    productType: service.productType,
                  },
                },
              ]
            } else {
              this.nowCharges = [
                {
                  terminateMessage: this.$t('cancel.error'),
                  ...service && {
                    productName: service.productName,
                    productType: service.productType,
                  },
                },
              ]
              captureSentryError(e)
            }
            this.loadingChargesValue++
          }
        })
    },
    loadCancelLaterCharges() {
      sdk.instance
        .product(this.serviceUid)
        .cancelCharges(false)
        .then(charges => {
          this.laterCharges = this.chargeConcat(charges)
          this.loadingChargesValue++
        })
        .catch(e => {
          if (!this.destroyed) {
            const service = this.allServicesUidDictionary[this.serviceUid] || false

            if (e.status === 409) {
              if (e.data?.message) {
                this.laterCharges = [
                  {
                    terminateMessage: e.data.message,
                    ...service && {
                      productName: service.productName,
                      productType: service.productType,
                    },
                  },
                ]
              }
            } else if (e.status === 403) {
              this.laterCharges = [
                {
                  terminateMessage: this.$t('cancel.no-permission'),
                  ...service && {
                    productName: service.productName,
                    productType: service.productType,
                  },
                },
              ]
            } else {
              this.laterCharges = [
                {
                  terminateMessage: this.$t('cancel.error'),
                  ...service && {
                    productName: service.productName,
                    productType: service.productType,
                  },
                },
              ]
              captureSentryError(e)
            }

            this.loadingChargesValue++
          }
        })
    },
    terminateDate(value) {
      return mpDate(value)
    },
    extractRelevantCancellationData(charge) {
      const { productId, total, currency, productName, terminationDate, terminateMessage } = charge

      const displayMoney = moneyFilter(total, currency)

      let serviceIcon = charge.productType

      const service = this.getServicesById(productId)?.[0]

      if (service?.productType === this.G_PRODUCT_TYPE_VXC) {
        serviceIcon = this.getVxcIconFor(service)
      }

      return { displayMoney, productName, serviceIcon, terminationDate, terminateMessage }
    },
    chargeConcat(charge) {
      const list = []
      list.push(this.extractRelevantCancellationData(charge))
      if (charge.sublagPriceCheck?.length) {
        for (const subCharge of charge.sublagPriceCheck) {
          list.push(this.extractRelevantCancellationData(subCharge))
        }
      }
      if (charge.ixPriceCheck?.length) {
        for (const subCharge of charge.ixPriceCheck) {
          list.push(this.extractRelevantCancellationData(subCharge))
        }
      }
      if (charge.vxcPriceCheck?.length) {
        for (const subCharge of charge.vxcPriceCheck) {
          list.push(this.extractRelevantCancellationData(subCharge))
        }
      }

      return list
    },
    getVxcIconFor(connection) {
      let icon = this.G_PRODUCT_TYPE_VXC

      const aEndDetails = this.findPort(connection.aEnd.productUid)
      const bEndDetails = this.findPort(connection.bEnd.productUid)

      if (aEndDetails?.companyUid && bEndDetails?.companyUid) {
        const foreignEnd = [aEndDetails, bEndDetails].find(end => end.companyUid !== this.companyUid)

        if (foreignEnd) {
          const marketplaceProfile = this.marketplaceData.find(profile => profile.companyUid === foreignEnd.companyUid)

          if (marketplaceProfile) icon = 'Marketplace'

          if (foreignEnd.connectType === 'TRANSIT') icon = 'MegaportInternet'
        }
      }

      return icon
    },
    async blockForDiverseLinks() {
      const h = this.$createElement

      const others = []
      const relevantPorts = []

      for (const port of this.myPorts) {
        if (port.provisioningStatus === this.G_PROVISIONING_DESIGN && port.uiConfig?.diverseFrom === this.serviceUid) {
          others.push(h('li', null, port.productName))
          relevantPorts.push(port)
        }
      }

      if (others.length) {
        try {
          const message = h('div', { ref: 'diverseConfirmation' }, [
            h('p', null, this.$tc('ports.delete-diverse-from', others.length)),
            h('ul', null, others),
            h('p', null, this.$tc('ports.reset-diversity', others.length)),
            h('p', null, this.$t('general.action-confirmation')),
          ])
          await this.$confirm(message, this.$t('cancel.diversity'), {
            confirmButtonText: this.$t('general.yes'),
            cancelButtonText: this.$t('general.no'),
            type: 'warning',
            showClose: false,
            closeOnClickModal: false,
          })

          // Now do the actual tidy up work
          for (const port of relevantPorts) {
            const service = deepClone(port)
            service.config.diversityZone = null
            service.uiConfig.diverseFrom = null

            // This will find the existing one and update it
            this.editService({ service })
          }
        } catch (e) {
          return true
        }
      }
      return false
    },
    async cancelServices() {
      if (await this.blockForDiverseLinks()) {
        return false
      }
      this.cancellingActive = true
      if (!this.statusIsDesign) {
        this.$_cancelLiveServices()
      } else if (this.serviceScopeIsAll) {
        this.$_removeAllConfiguredServices()
      } else {
        this.$_removeSingleConfiguredService()
      }
    },
    $_cancelLiveServices() {
      this.$refs.cancelForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)
          this.cancellingActive = false

          return false
        }

        // Capture the terminate event once a valid confirmation is submitted
        captureEvent(`cancel-${productTypeToEvent(this.cancellingService.productType)}.terminate.click`, this.cancelForm)

        // Show IBM modal if an IBM connection or a service containing an IBM connection is cancelled
        if (this.serviceContainsIbm) {
          this.showIbmReminderModal()
        }

        sdk.instance
          .product(this.serviceUid)
          .cancel(this.cancelForm.cancelNow, {
            cancellation_reason: this.cancelForm.reason,
            cancellation_comment: this.cancelForm.comment,
          })
          .then(data => {
            // Wait until the service has been successfully cancelled at an API level before removing the local record
            if (this.cancelForm.cancelNow) {
              this.removeServiceOrConnection({ productUid: this.serviceUid, cartCleanup: true })
            }

            this.notifyGood({
              title: this.$tc(
                this.cancelForm.cancelNow ? 'cancel.terminated' : 'cancel.terminating',
                this.charges.length,
                { count: this.charges.length }
              ),
              message: data.message,
            })

            if (!this.cancelForm.cancelNow) {
              this.getMyServices()
            }

            this.setVisible(false)
          })
          .catch(e => {
            if (!this.destroyed) {
              if (e.data && e.data.message) {
                this.notifyBad({
                  title: this.$tc('cancel.issues-terminating', this.charges.length, { count: this.charges.length }),
                  message: e.data.message,
                })
              } else {
                this.notifyBad({
                  title: this.$tc('cancel.issues-terminating', this.charges.length, { count: this.charges.length }),
                  message: this.$t('general.unknown-error'),
                })

                captureSentryError(e)
              }
              this.fetchServiceOrConnection({ productUid: this.serviceUid })
                .then(() => {
                  // empty function is intentional
                })
                .catch(() => {
                  // empty function is intentional
                })
            }
          })
          .finally(() => {
            this.cancellingActive = false
          })
      })
    },
    $_removeAllConfiguredServices() {
      captureEvent('cancel-all.remove-config.click')
      this.clearCart()
      this.setVisible(false)
    },
    $_removeSingleConfiguredService() {
      captureEvent(`cancel-${productTypeToEvent(this.cancellingService.productType)}.remove-config.click`)

      // Cancelling a single configured service. Need to find out if it was a port, and if it was a LAG primary, and if so,
      // transfer the LAG primary status to another port if one is available.

      // We know the service id, so can find it in the services
      const port = this.services.find(p => p.productUid === this.serviceUid)
      if (port && port.lagPrimary && port._subLags && port._subLags.length) {
        const service = { ...port._subLags[0] }
        service.lagPrimary = true
        this.removeServiceOrConnection({ productUid: this.serviceUid, cartCleanup: true })
        this.editService({ service })
      } else {
        this.removeServiceOrConnection({ productUid: this.serviceUid, cartCleanup: true })
      }
      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.cancel-content {
  width: fit-content;
  margin: auto;
}

.hr-spacing {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.cancel-service-item {
  &.terminating {
    width: unset;
    padding: 1.3rem;
    margin: 1rem auto;
  }
  &.forbidden {
    background-color: var(--color-danger-light-8);
  }
  .terminate-forbidden {
    margin-left: auto;
    p {
      white-space: normal;
      word-break: break-word;
      line-height: 1.3rem;
      margin: 0.2rem 0;
    }
  }
}

.switch-content-enter-active,
.switch-content-leave-active {
  transition: opacity 0.3s ease;
}
.switch-content-enter,
.switch-content-leave-to {
  opacity: 0;
}

.service-content {
  white-space: normal;
  word-break: normal;
  line-height: normal;
}

.service-cancellation {
  width: 15rem;
  margin-left: auto;
}

// This allows us to put popovers on individual radio buttons and have them
// display on hover anywhere on the button.
.inverse-padding {
  .el-radio-button__inner {
    padding: 12px 0;
  }
}

.fs-2-5rem {
  font-size: 2.5rem;
}

.feedback-prompt {
  box-sizing: border-box;
  width: 80%;
  line-height: 1.2em;
  margin-bottom: 0.5rem;
  padding-left: 200px;
}
</style>

<style lang="scss">
.service-cancellation.el-input.is-disabled .el-input__inner {
  color: var(--color-text-regular);
  text-align: right;
}
</style>
