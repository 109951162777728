import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import { Settings } from 'luxon'
import store from '@/store/Store.js'

/**
 * This is the master ordered list of languages for the user to select from. This is used internally
 * and in the language selection in the UI.
 *
 * The first language specified is the default and is automatically loaded as the fallback language.
 */
export const languageSelections = [
  { code: 'en', region: 'US', iso: 'en-US', file: 'en-setup.js', name: 'English' },
  { code: 'de', region: 'DE', iso: 'de-DE', file: 'de-setup.js', name: 'Deutsch' },
  { code: 'fr', region: 'FR', iso: 'fr-FR', file: 'fr-setup.js', name: 'Français' },
  { code: 'ja', region: 'JP', iso: 'ja-JP', file: 'ja-setup.js', name: '日本語 (Japanese)' },
  { code: 'es', region: 'ES', iso: 'es-ES', file: 'es-setup.js', name: 'Español' },
  { code: 'pt', region: 'BR', iso: 'pt-BR', file: 'pt-BR-setup.js', name: 'Português Brasileiro' },
]

const defaultLanguageMessages = require(`@/i18n/${languageSelections[0].file}`).default
const loadedLanguages = [languageSelections[0].iso]
let i18n = null

/**
 * Get the selected language from cookie/localStorage. Falls back to en-US if not found.
 *
 * @returns The selected language record
 */
const readSelectedLanguage = () => {
  const cookies = new URLSearchParams(document.cookie.replace(/; /g, '&'))
  // Look for one that includes the region, fall back to just language
  const localStore = (window.localStorage.getItem('mp_lang_region') || window.localStorage.getItem('mp_lang')) || undefined
  const resolved = cookies.get('mp_lang_region') || cookies.get('mp_lang') || localStore
  const selectedLanguage = languageSelections.find(lang => lang.iso === resolved || lang.code === resolved)
  // Avoid the possibility of it being set to something we don't know how to translate.
  return selectedLanguage || languageSelections[0]
}

/**
 * Set the language as a cookie as the primary information.
 * Some ad blockers block cookies so mirror to localStorage (if on client).
 *
 * @param {object} lang The selected language iso abbreviation
 */
const writeSelectedLanguage = ({ code, iso }) => {
  // Set lang code
  document.cookie = `mp_lang=${code}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang', code)
  // Set lang iso
  document.cookie = `mp_lang_region=${iso}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang_region', iso)
}

/**
 * Uses the cached language definition and sets it as current, or if not cached, dynamically loads the
 * specified language.
 *
 * @param {string} lang Language record from languageSelections
 */
export const setActiveLanguage = async (lang = readSelectedLanguage()) => {
  writeSelectedLanguage(lang)

  if (!loadedLanguages.includes(lang.iso)) {
    const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang.file}`)
    loadedLanguages.push(lang.iso)

    i18n.setLocaleMessage(lang.iso, messages.default)
    loadedLanguages.push(lang.iso)
  }
  setI18nLanguage(lang.iso)
  store.commit('setLanguage', lang)
}

/**
 * Set the i18n plugin language
 * Makes the specified language work throughout the app.
 *
 * @param {string} lang The language abbreviation
 */
export const setI18nLanguage = lang => {
  // I18n plugin
  i18n.locale = lang
  // Luxon dates
  Settings.defaultLocale = lang
  Settings.resetCaches()
  // Html
  updateHtml()
}

/**
 * Updates the lang attribute on the html and sets an appropriate class on the body so you can adjust formatting
 * for different languages if required.
 */
const updateHtml = () => {
  document.querySelector('html').setAttribute('lang', i18n.locale)

  const removableClasses = []
  document.body.classList.forEach(className => {
    if (className.startsWith('locale-')) {
      removableClasses.push(className)
    }
  })
  for (const className of removableClasses) {
    document.body.classList.remove(className)
  }
  document.body.classList.add(`locale-${i18n.locale}`)
}

// We can not load up i18n when running tests because some old-ass tests spam the console with errors 🤬
if (process.env.NODE_ENV !== 'test') {
  Vue.use(VueI18n)
  i18n = new VueI18n({
    locale: readSelectedLanguage().iso,
    fallbackLocale: languageSelections[0].iso,
    messages: { [languageSelections[0].iso]: defaultLanguageMessages },
    silentTranslationWarn: true,
  // postTranslation: str => `X${str}X` // Uncomment this to display everything translated with a prefix & suffix of X
  })
  ElementLocale.i18n((key, value) => i18n.t(key, value))

  // Initialise with the default or preselected active language.
  setActiveLanguage()
}

export default i18n
