const INTEGRATION_DISABLED = process.env.VUE_APP_DISABLE_INTEGRATION_GOOGLE_TAG_MANAGER === 'true'

class GoogleTagManager {
  #disabled = false

  init(appConfiguration) {
    if (INTEGRATION_DISABLED) return

    if (!appConfiguration.googleTagManager) {
      this.#disabled = true
      return
    }

    window.dataLayer = window.dataLayer || []
    this.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    const currentTopScript = document.getElementsByTagName('script')[0]
    let googleTagManagerScript = document.createElement('script')
    googleTagManagerScript.type = 'text/javascript'
    googleTagManagerScript.src = `https://www.googletagmanager.com/gtm.js?id=${appConfiguration.googleTagManager.containerId}`
    googleTagManagerScript.async = true
    currentTopScript.parentNode.insertBefore(googleTagManagerScript, currentTopScript)
  }

  /**
   * Generic method to get data to the data layer to be picked up by GTM
   */
  push() {
    if (INTEGRATION_DISABLED || this.#disabled) return

    window.dataLayer.push(arguments)
  }
}

export default new GoogleTagManager()
