import googleTagManager from '@/third-party-integrations/googleTagManager'
import sentry from '@/third-party-integrations/sentry'
import googleLogin from '@/third-party-integrations/googleLogin'
import stripe from '@/third-party-integrations/stripe'
import awsAmplify from '@/third-party-integrations/awsAmplify'
import SalesforceChat from '@/third-party-integrations/salesforceEmbeddedMessaging'
import postHog from '@/third-party-integrations/postHog'
import oneTrust from '@/third-party-integrations/oneTrust'
import segment from '@/third-party-integrations/segment'
import G2Review from '@/third-party-integrations/g2Review'

const integrations = {}

export function setupIntegrations(appConfiguration) {
  // Sentry: make this the first to load so that if other integrations need to report errors, they can
  sentry.init(appConfiguration)
  integrations.sentry = sentry

  // OneTrust
  oneTrust.init()
  integrations.oneTrust = oneTrust

  // Salesforce Chat
  const salesforceChatInstance = new SalesforceChat()
  salesforceChatInstance.init(appConfiguration)
  integrations.salesforceChat = salesforceChatInstance

  // Google Tag Manager
  googleTagManager.init(appConfiguration)
  integrations.googleTagManager = googleTagManager

  // Google Login
  googleLogin.init(appConfiguration)
  integrations.googleLogin = googleLogin

  // AWS Amplify
  awsAmplify.init(appConfiguration)
  integrations.awsAmplify = awsAmplify

  // Stripe
  stripe.init()
  integrations.stripe = stripe

  // PostHog
  postHog.init(appConfiguration)
  integrations.postHog = postHog

  // Segment
  segment.init(appConfiguration)
  integrations.segment = segment

  // G2
  const g2ReviewInstance = new G2Review()
  g2ReviewInstance.init(appConfiguration)
  integrations.g2review = g2ReviewInstance
}

export default integrations
