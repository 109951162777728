import integrations from '@/third-party-integrations/integrations.js'
import { MegaportError } from '@megaport/api-sdk'

const captureSentryError = e => {
  if (!(e instanceof MegaportError) || !e.handled) {
    integrations.sentry.captureException(e)
  }
}

/**
 * Tells sentry about any issues that zod had parsing the returned response.
 *
 * It will truncate the number of errors and the number of reported data rows
 * so that we don't get any payload size errors from Sentry.
 *
 * Since Sentry doesn't play nicely with nested objects, we need to stringify
 * anything that requires it.
 *
 * @param {ZodError} zodError
 * @param {AxiosResponse} axiosResponse
 */
export const captureZodParseError = (zodError, axiosResponse) => {
  if (process.env.VUE_APP_DISABLE_INTEGRATION_SENTRY === 'true') {
    console.error('Parsing Error:', zodError)
    console.warn('Axios Response:', axiosResponse)
    return
  }

  const NUM_ZOD_ERRORS = 50
  const NUM_DATA_ROWS = 20

  // Truncate if it's massive
  zodError.issues.length = Math.min(zodError.issues.length, NUM_ZOD_ERRORS)

  const apiData = {
    statusCode: axiosResponse.status,
    requestUrl: `${axiosResponse.config.baseURL}${axiosResponse.config.url}`,
    ...(axiosResponse.config.data && { requestData: axiosResponse.config.data }),
  }
  if (axiosResponse.data?.data) {
    let responseData = axiosResponse.data.data
    if (Array.isArray(responseData) && responseData.length > NUM_DATA_ROWS) {
      responseData = responseData.slice(0, NUM_DATA_ROWS)
    }
    apiData.responseData = responseData
  }

  // Filter IDs, UUIDs, and url query params out of request URLs so sentry can automatically merge raised issues
  const filteredUrl = axiosResponse.config.url
    .replaceAll(/(?<=[?&][^?/&=]+=)[^?/&=]*/g, '[value]')
    .replaceAll(/(?<=[?&]([^?/&=]+=))[^?/&=]*(?:&\1[^?/&=]*)+/g, '[array]') // flag duplicate params as an array
    .replaceAll(/(?<=\/)\d+(?=[/?]|$)/g, '[id]')
    .replaceAll(/(?<=\/)[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}(?=[/?]|$)/ig, '[uuid]')

  const sentryException = new Error(`Zod Parsing error at ${axiosResponse.config.method.toUpperCase()} ${filteredUrl}`, { cause: zodError })
  sentryException.cause ??= zodError // Manually set cause property in case we are pre ECMAScript 2022

  integrations.sentry.captureException(sentryException, { contexts: {
    'Zod Error': {
      issues: zodError.issues,
    },
    'Axios Response': apiData,
  } })
}

export default captureSentryError
